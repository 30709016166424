
export default [
  {
    name: `background_image`,
    image: require(`@/assets/loading/background.jpg`)
  },
  { name: `title` },
  {
    name: `instruction_1`,
    image: require(`@/assets/loading/mouse.png`),
  },
  {
    name: `instruction_2`,
    image: require(`@/assets/loading/arrow.png`),
  },
  {
    name: `instruction_3`,
    image: require(`@/assets/loading/hotspot.png`),
  },
  {
    name: `instruction_4`,
    image: require(`@/assets/loading/clarins-logo.png`),
  },
  { name: `loading_label` },
  { name: `start_btn` },
  { name: `loading_texts` },
];
