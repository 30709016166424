export default [
    {
      name: `actifs-excellence-absolue`,
      title: `Outstanding active ingredients`,
      texts: [
        `This method of cultivation creates the ideal conditions for growing highly concentrated active plant ingredients. Each extract is then tested for performance and tolerance.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `One year, four seasons`,
      texts: [
        `At Domaine Clarins, plants get our full attention all year long. In the spring, it’s time for work: weeding, soil preparation, and new growth.`,
        `During the summer and fall, it’s time to harvest and dry the plants. It’s also the season for scientific observations. `,
        `When winter comes, the teams prepare for the coming season and analyze the observations made throughout the year.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `From the field to the drying racks`,
      texts: [
        `Our plants are hand-picked when they are richest in active ingredients. They are then cut into small pieces and spread out in a dryer. Following traditional methods, they are protected from the sun’s rays and are gently turned over once a day until they are completely dry. At Domaine Clarins, more than 2.5 tons of fresh plants are harvested each year`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100% organic crops`,
      texts: [
        `In soil that is free of pollution, the plants grow according to the rhythm of the seasons and with respect for nature, following the strict criteria of organic farming. Naturally rich, the soil is modified only by the addition of plant and animal matter. It is worked to a minimum to respect the natural balance of the soil. No phytosanitary treatments are used here: the plants are treated with plant extracts. All the benefits of a virtuous and high-quality production are thus brought together on the Domaine. `,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `The start of a long adventure`,
      texts: [
        `Since 2016, six 100% organic extracts have enriched our herbarium: Alpine Rose, Houseleek, Soapwort, Lemon Balm and Yellow Gentian. You can find them in our cleansers and ClarinsMen products. Our goal is to double that number by 2025.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `Dedicated to innovation and experimentation`,
      texts: [
        `At Domaine Clarins, we grow the organic plants of today… and tomorrow. Our research teams analyse new species for their cosmetic properties and study their effects on the skin.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `A new biodiversity commitment `,
      texts: [
        `In 2021, the connection between Clarins and the Alps were further strengthened by a major new pledge to protecting biodiversity: the signing of a Real Environmental Obligation (REO) with Asters, Conservatoire d’Espaces Naturels de Haute Savoie. Through this contract, we commit to preserving the Domaine’s biodiversity for 99 years, the maximum duration provided by the REO. This is the very first time that a private company has made this type of commitment to a natural area.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `Committed to safeguarding the French Alps`,
      texts: [
        `Clarins’ love for the Alps began in 1993, when Christian Courtin crossed paths with Prince Sadruddin Aga Khan and his Alp Action Foundation.`,
        `Since then, the brand has provided ongoing support for the biodiversity of the Alps: it has contributed to 5 programs to preserve endangered species in partnership with Alp Action, which was later taken over by Asters. This is a commitment is very close to our hearts and we continue to support with the same passion today as always.`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `A dedicated team`,
      texts: [
        `Domaine Clarins also involves a team of passionate people who share a deep connection to the land and their profession. Up to 15 experts work there under the guidance of Aurore Metral and Véronique Mothès, who are both in charge of operations with input from ethnobotanist Jean-Pierre Nicolas.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `Gentiana lutea (great yellow gentian)`,
      texts: [
        `A favorite ingredient in phytotherapy, yellow gentian has been known since antiquity for its numerous curative effects. A robust plant of the great outdoors, it thrives in pastures and has an amazing lifespan of over half a century. During this long life, it develops strong roots and reservoirs of active ingredients that help fight against digestive disorders. The soothing benefits of the organic gentian leaf extract grown at Domaine Clarins is in all of our water-based cleansers.`,
      ],
      cta: {
        title: `Discover our products`,
        href: `https://www.clarins.com.my/skincare/face/cleansers-toners-210/`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `The bearded vulture`,
      texts: [
        `Look up to the sky you may have the chance to see this Alpine vulture, which was reintroduced in France in 1986 under the impetus of Asters and Clarins. With a wingspan of about 3 metres, it is the largest European bird of prey. There are now 70 in the Alps, even though the species was threatened with extinction.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `Sempervivum tectorum (common houseleek)`,
      texts: [
        `Native to Western Europe, the houseleek is subjected to extreme temperatures, excessive drought and arid soil. Regardless of these obstacles, it displays green and fleshy leaves that are full of water. The plant is grown on the Domaine and its organic extract is selected by our Laboratories for the ClarinsMen range which helps the skin stay hydrated.`,
      ],
      cta: {
        title: `Discover our products`,
        href: `https://www.clarins.com.my/skincare/men-500/`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `An open-air laboratory`,
      texts: [
        `The Domaine’s living outdoor laboratory is dedicated to experimentation. Today, about 20 new plant species from the mountains and plains are being studied to determine the parts that are most concentrated in active ingredients. Once collected, the samples are sent to the Clarins Laboratories in Pontoise, France, for analysis. Since 2017, over 900 samples have been reviewed for potential use in our Clarins formulas.`,
      ],
    },
    {
      name: `melisse`,
      title: `Lemon balm`,
      texts: [
        `Cultivated since antiquity as a medicinal plant, lemon balm relieves many ailments, particularly digestive and nervous disorders. It promotes sleep by reducing stress. Studies confirm that the plant helps with relaxation, as well as reducing stress and anxiety. In cosmetics, organic lemon balm extract helps to calm, soothe and soften the skin. You can find it in all our cleansers.`,
      ],
      cta: {
        title: `Discover our products`,
        href: `https://www.clarins.com.my/skincare/face/cleansers-toners-210/` ,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `Trusted local partners`,
      texts: [
        `Loyalty, a value we hold dear. We have been working with the same local partners for many years. They all share our philosophy and respect the good practices followed at Domaine Clarins: quality plants, organic agriculture, traditional methods, etc. They have our full confidence and we are committed to them for the long term.`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `Wild plants`,
      texts: [
        `Domaine Clarins work with the owners of 14 neighbouring sites that are certified organic. These sites are known as “wilderness” sites where plants grow naturally. We harvest butterbur, yellow gentian and Alpine rose leaves in a sustainable and responsible manner.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `Short circuit production`,
      texts: [
        `Growing our own plants allows us to control our environmental impact without compromising on quality. After harvesting, an extract is prepared to be formulated by our Laboratories. Each step is 100% traceable, guaranteeing transparency and sustainability.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `Respect for the soil`,
      texts: [
        `Domaine Clarins provides exceptionally rich soil for growing our plants. On this precious land, we use traditional farming methods and minimal technology. Gentle ploughing by horses preserves the life of the soil and, in particular, the earthworms and microorganisms that guarantee its fertility.`,
      ],
      cta: {
        title: `Watch the video`,
        fakeDisable: true,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `The richness and purity of the French Alps`,
      texts: [
        `Located in the heart of the Alps, Domaine Clarins reaps all the benefits of the mountains: pure air, rich and preserved soil, and water taken directly from the source. Optimal conditions for growing our own organic plants, so that we can offer you the highest-quality products possible.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `Saponaria officinalis (common soapwort)`,
      texts: [
        `A perennial plant found on the banks of rivers, ditches and woods, soapwort bears fragrant white or pink flowers in summer. Our ancestors used its leaves and rhizomes to wash woolen fabrics and bleach threads and laces. Soapwort has cleansing and emulsifying properties thanks to its high content of saponins, natural surfactants. Grown on the Domaine, organic soapwort allows our Gentle Foaming Cleanser range to gently cleanse the skin.`,
      ],
      cta: {
        title: `Discover our products`,
        href: `https://www.clarins.com.my/skincare/face/cleansers-toners-210/` ,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `Trustworthy Traceability`,
      texts: [
        `The “Domaine Clarins” logo on our products guarantees complete control of the supply chain, from the field to our points of sale.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `A source of clean energy`,
      texts: [
        `At Domaine Clarins, beauty is in harmony with nature. Thanks to the collective energy of people and the pure environment, we grow our plants without polluting or causing harm. Rainwater is collected in rain barrels and used to water the crops. Solar panels have been installed to produce electricity for the dryer and chalet.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `Responsible sourcing`,
      texts: [
        `We believe in responsible and sustainable beauty. We favor good agricultural practices and local sourcing from France or Europe. When our ingredients come from further away, we implement Fair Trade programmes . We ensure full traceability of harvesting areas and enter into long-term contracts with our partners.`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `Protective netting`,
      texts: [
        `At the Domaine, weeding is done entirely by hand. To limit the development of weeds, we have chosen to use natural protection. Wool or hemp canopies protect the crops from the sun’s rays, preserve the soil’s fertility, and encourage the crops to flourish.`,
      ],
    },
  ];
