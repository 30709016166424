export default [
    {
      name: `actifs-excellence-absolue`,
      title: `卓越的活性成分`,
      texts: [
        `這種栽培方法保證植物的獨有品質，確保植物蘊含高濃度的活性成分。每種準備好的萃取隨後將受測試性能和耐受性。`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `Domaine Clarins 的四季`,
      texts: [
        `在Domaine Clarins，每時每刻都有植物需要我們關注！`,
        `在春天即是工作的季節，我們需要除草、整地和種植植物。在夏季和秋季,`,
        `就是收穫和烘乾植物的時候，亦是科學觀察的季節。當冬天來臨時，團隊為即將到來的季節做準備並分析全年的觀察結果。`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis jamais. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `從收割到烘乾`,
      texts: [
        `這些植物在活性成分最豐富時以手工採摘，然後切成小片，鋪在烘乾機中，依照傳統方法烘乾，避免陽光照射，並將植物每天小心翻面一次，直至完全乾燥。Domaine Clarins每年收穫超過2.5噸新鮮植物。`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100%有機種植`,
      texts: [
        `在沒有污染的土壤中，植物按照季節的節奏和尊重自然的方式生長，並遵循嚴格的有機農業標準。由於土壤天然肥沃，所以只需通過添加植物和動物物質作微調。我們把土壤處理工作減至最少，以尊重土壤的天然平衡。這裡並不使用植物檢疫處理，植物均以植物萃取作處理。因此，莊園匯聚了合乎道德以及高品質生產的所有好處。`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `漫長歷程的開始`,
      texts: [
        `自2016年以來，6種Clarins 100%有機萃取加入了我們的法式草本館，包括阿爾卑斯玫瑰、石蓮花、肥皂草、香蜂草和黃龍膽。您可以在我們的潔面乳和ClarinsMen產品找到有關成分。我們的目標是到2025年時，將這個數字翻倍。`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `融匯文化與實驗之地`,
      texts: [
        `在Domaine Clarins，我們為今天和未來種植有機植物…… 我們的研究團隊分析新物種的美容特性，並研究其對皮膚的效用。`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `對環境的新承諾 `,
      texts: [
        `2021年，Clarins與阿爾卑斯山之間的聯繫因對生物多樣性的一項重大新承諾而進一步加強：與上薩瓦省自然空間學院的Asters簽署了「真正環境義務」（REO）。通過這份合約，我們承諾保護莊園的生物多樣性99年，這是REO提供的最長期限。這是首次有私人公司對自然區域做出這種承諾。`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `致力拯救阿爾卑斯山`,
      texts: [
        `Clarins一直以來都熱愛阿爾卑斯山！這一切始於1993年，當時Christian Courtin偶然認識了Prince Sadruddin Aga Khan王子和他的Alp Action基金會。`,
        `從那時起，品牌一直為阿爾卑斯山的生物多樣性提供支持：與Alp Action合作為5個保護瀕危物種的計劃做出貢獻，Alp Action後來由Asters接管。此承諾秉承我們的核心價值，而我們今天一如既往地以同樣的熱情繼續予以支持。`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `一個熱誠關懷的團隊`,
      texts: [
        `Domaine Clarins亦擁有一個充滿熱誠的團隊，他們熱愛我們的土地、熱愛他們的專業。多達15名專家在營運負責人Aurore Metral和Véronique Mothès的指導下工作，他們亦獲得民族植物學家Jean-Pierre Nicolas的專業建議。`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `黃龍膽`,
      texts: [
        ` 植物療法的療效之后，黃龍膽自古以來就以其眾多的療效而聞名，是一種健壯的戶外植物，能在牧場上茁壯成長，並且壽命驚人，可長達半個世紀。在這漫長的生命中，黃龍膽會形成強大的根莖和活性成分貯藏庫，有助對抗消化系統疾病。在Clarins的水性潔面產品中，您可體驗到種植自Domaine Clarins的有機龍膽葉萃取帶來的舒緩功效。`,
      ],
      cta: {
        title: `探索我們的產品`,
        href: `https://www.clarins.com.hk/zh/%E9%9D%A2%E9%83%A8-%E8%BA%AB%E9%AB%94%E8%AD%B7%E7%90%86/%E9%9D%A2%E9%83%A8%E8%AD%B7%E7%90%86/%E6%BD%94%E9%9D%A2%E5%8F%8A%E7%88%BD%E8%86%9A%E8%AD%B7%E7%90%86-210/%E6%A4%8D%E8%90%83%E9%80%9F%E6%95%88%E5%8D%B8%E5%A6%9D%E6%B0%B4--%E6%95%8F%E6%84%9F%E8%82%8C%E5%B0%88%E7%94%A8--80062047.html`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `有鬍子的禿鷹`,
      texts: [
        `仰望天空，您可能有機會看到這種高山禿鷲，在Asters和Clarins的推動下於1986年重新引入法國。翼展約3米，是歐洲最大的猛禽。儘管該物種面臨滅絕的威脅，但現在阿爾卑斯山仍有70隻此禿鷹。`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `石蓮花`,
      texts: [
        `石蓮花原產於西歐，受到極端溫度、過度乾旱和乾旱土壤所影響。儘管如此，石蓮花仍能長出充滿水分的綠色肉質葉子。該植物生長在莊園中，我們的實驗室選擇其有機萃取並用於ClarinsMen系列，可幫助肌膚保持潤澤。 `,
      ],
      cta: {
        title: `探索我們的產品`,
         href: `https://www.clarins.com.hk/zh/%E9%9D%A2%E9%83%A8-%E8%BA%AB%E9%AB%94%E8%AD%B7%E7%90%86/%E7%94%B7%E5%A3%AB%E8%AD%B7%E7%90%86/%E7%9C%BC%E9%83%A8%E8%AD%B7%E7%90%86-560/%E5%8D%87%E7%B4%9A%E6%B4%BB%E5%8A%9B%E6%8A%97%E5%80%A6%E7%9C%BC%E9%83%A8%E5%95%AB%E5%96%B1-80071985.html`, 
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `戶外實驗室`,
      texts: [
        `莊園設有一個專門進行實驗的地方。當地研究大約20個來自山區和平原的新物種，以找出活性成分最集中的部分。收集到的樣品會送至我們位於Pontoise的實驗室進行分析。自2017年以來，已分析900多個樣品，令Clarins的配方更豐富。`,
      ],
    },
    {
      name: `melisse`,
      title: `檸檬香蜂草`,
      texts: [
        `檸檬香蜂草自古以來就作為藥用植物栽培，可以緩解許多疾病，尤其是消化系統和神經系統疾病。檸檬香蜂草亦可減輕壓力以促進睡眠。研究證實，這種植物有助於放鬆，以及減輕壓力和焦慮。在化妝品中，有機檸檬香蜂草萃取有助鎮靜、舒緩和軟化肌膚。我們所有潔面產品皆含有檸檬香蜂草。`,
      ],
      cta: {
        title: `探索我們的產品`,
          href: `https://www.clarins.com.hk/zh/%E9%9D%A2%E9%83%A8-%E8%BA%AB%E9%AB%94%E8%AD%B7%E7%90%86/%E9%9D%A2%E9%83%A8%E8%AD%B7%E7%90%86/%E6%BD%94%E9%9D%A2%E5%8F%8A%E7%88%BD%E8%86%9A%E8%AD%B7%E7%90%86-210/%E6%A4%8D%E8%90%83%E6%BA%AB%E5%92%8C%E6%BD%94%E9%9D%A2%E6%B3%A1%E6%B2%AB-%E4%B8%AD%E6%80%A7%E8%87%B3%E4%B9%BE%E6%80%A7%E8%82%8C%E9%81%A9%E7%94%A8--80071907.html`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `值得信賴的當地合作夥伴`,
      texts: [
        `忠誠，是我們珍視的價值。多年來，我們一直與相同的當地合作夥伴合作。他們都認同我們的理念，並尊重Domaine Clarins遵循的良好實踐，包括優質植物、有機農業、傳統方法等。他們對我們充滿信心，我們亦承諾與他們長期合作。`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `野生植物`,
      texts: [
        `Domaine Clarins團隊與14個通過有機認證的鄰近站點場地的擁有者合作。這些地點被稱為植物自然生長的「荒野」場地。我們以可持續和負責任的方式收割蜂斗菜、黃龍膽和阿爾卑斯玫瑰葉。`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `我們的本地生產網絡`,
      texts: [
        `種植自己的植物使我們能夠在不影響品質的情況下控制對環境的影響。收成後，我們的實驗室就會準備為萃取進行配製。每一步都是100%可追溯，保證透明度和可持續性。`,
      ],
    },
    {
      name: `respect-sols`,
      title: `尊重土壤`,
      texts: [
        `Domaine Clarins為我們提供異常肥沃的土壤。為了好好保護它，我們偏向於傳統的方法而不是重型機械設備。使用馬作溫和的耕作可以保護土壤的生命，特別是蚯蚓和微生物，以保證土壤肥沃。`,
      ],
      cta: {
        title: `觀看影片`,
        enabled: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `富饒純淨的阿爾卑斯山`,
      texts: [
        `Domaine Clarins位於阿爾卑斯山的中心地帶，享有山脈的所有好處，包括純淨的空氣（深呼吸感受！）、肥沃且保存完好的土壤以及直接取自源頭的水。莊園擁有最佳的條件種植我們的植物，並為您提供優質產品。`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `肥皂草`,
      texts: [
        `肥皂草是一種多年生植物，可在河岸、溝渠和樹林中找到，夏季會開出芳香的白色或粉紅色花朵。我們的祖先會用肥皂草的葉子和根莖來洗滌羊毛織物，漂白線和花邊。由於肥皂草富含皂素與天然表面活性劑，因而具有清潔和乳化的特性。有機肥皂草生長在Domaine Clarins，為我們的植萃溫和潔面泡沫系列帶來溫和的潔膚功效。`,
      ],
      cta: {
        title: `探索我們的產品`,
        href: `https://www.clarins.com.hk/zh/%E9%9D%A2%E9%83%A8-%E8%BA%AB%E9%AB%94%E8%AD%B7%E7%90%86/%E9%9D%A2%E9%83%A8%E8%AD%B7%E7%90%86/%E6%BD%94%E9%9D%A2%E5%8F%8A%E7%88%BD%E8%86%9A%E8%AD%B7%E7%90%86-210/%E6%A4%8D%E8%90%83%E6%BA%AB%E5%92%8C%E6%BD%94%E9%9D%A2%E6%B3%A1%E6%B2%AB-%E4%B8%AD%E6%80%A7%E8%87%B3%E4%B9%BE%E6%80%A7%E8%82%8C%E9%81%A9%E7%94%A8--80071907.html`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `卓越的可追溯性`,
      texts: [
        `我們產品上的「Domaine Clarins」標誌保證我們全面控制從種植場到銷售點的所有供應鏈。`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `潔淨能源`,
      texts: [
        `在Domaine Clarins，一切以大自然為優先。植物能夠茁壯生長，有賴於人類和大自然的集體能量，讓植物免於遭受污染或侵害。雨水收集在雨水桶中，用來澆灌莊稼。莊園亦設置太陽能電池板，為烘乾機和小木屋發電。`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `負責任採購過程`,
      texts: [
        `我們相信負責任和可持續的美麗之道。我們會盡量選擇良好的農業實踐和當地採購（法國或歐洲）。當我們的原料來自更遠的地方時，我們會實施公平貿易溢價。我們確保收穫區域的完全可追溯性，並與我們的合作夥伴簽訂長期合約。。`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `天然保護`,
      texts: [
        `在莊園中，除草工作完全由人手完成。為了控制雜草的生長，我們選擇使用天然的保護方式。羊毛或大麻樹冠可保護作物免受陽光照射，保持土壤肥沃，促進作物生長。`,
      ],
    },
  ];
