
export default [
  {
    name: `entree-domaine`,
    title: `Entrance to the Domaine`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `Gentian and Soapwort`,
  },
  {
    name: `devant-chalet`,
    title: `The Chalet`,
  },
  {
    name: `chalet`,
    title: `The Chalet`,
  },
  {
    name: `haut-domaine`,
    title: `The Top of the Domaine`,
  },
  {
    name: `jardin-collection`,
    title: `The Collection Garden`,
  },
  {
    name: `parcelle-cheval`,
    title: `The Horse Pasture`,
  },
  {
    name: `plantations`,
    title: `The Flower Beds`,
  },
  {
    name: `partenaires`,
    title: `Our Partners`,
  },
  {
    name: `drone-domaine`,
    title: `Fly Over the Domaine`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `Discover the Valley`,
    isInMap: false
  },
];
