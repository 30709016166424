
export default [
  {
    image: require(`@/assets/360/entree-domaine.jpeg`),
    video: null,
    name: `entree-domaine`,
  },
  {
    image: require(`@/assets/360/gentiane-et-saponaire.jpeg`),
    video: null,
    name: `gentiane-et-saponaire`,
  },
  {
    image: require(`@/assets/360/devant-chalet.jpeg`),
    video: null,
    name: `devant-chalet`,
  },
  {
    image: require(`@/assets/360/chalet.jpeg`),
    video: null,
    name: `chalet`,
  },
  {
    image: require(`@/assets/360/haut-domaine.jpeg`),
    video: null,
    name: `haut-domaine`,
  },
  {
    image: require(`@/assets/360/jardin-collection.jpeg`),
    video: null,
    name: `jardin-collection`,
  },
  {
    image: null,
    video: require(`@/assets/360/parcelle-cheval.mp4`),
    name: `parcelle-cheval`,
  },
  {
    image: null,
    video: require(`@/assets/360/plantations.mp4`),
    name: `plantations`,
  },
  {
    image: require(`@/assets/360/partenaires.jpeg`),
    video: null,
    name: `partenaires`,
  },
  {
    image: null,
    video: require(`@/assets/360/drone-domaine.mp4`),
    name: `drone-domaine`,
  },
  {
    image: null,
    video: require(`@/assets/360/drone-montee.mp4`),
    name: `drone-montee`,

  },
  {
    image: require(`@/assets/360/hub.jpg`),
    video: null,
    name: `hub`,
  },
];
