
export default [
  {
    name: `entree-domaine`,
    title: `純淨之巔入口`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `黃金龍膽和有機肥皂草`,
  },
  {
    name: `devant-chalet`,
    title: `小木屋`,
  },
  {
    name: `chalet`,
    title: `小木屋`,
  },
  {
    name: `haut-domaine`,
    title: `純淨之巔最高處`,
  },
  {
    name: `jardin-collection`,
    title: `花園`,
  },
  {
    name: `parcelle-cheval`,
    title: `養馬牧場`,
  },
  {
    name: `plantations`,
    title: `花田`,
  },
  {
    name: `partenaires`,
    title: `我們的合作夥伴`,
  },
  {
    name: `drone-domaine`,
    title: `飛越純淨之巔`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `探索山谷`,
    isInMap: false
  },
];
