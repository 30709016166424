export default [
    {
      name: `actifs-excellence-absolue`,
      title: `Außergewöhnliche Wirkstoffe`,
      texts: [
        `Unsere gewählten Anbaumethoden bringen Pflanzen hervor, die eine hohe Wirkstoffkonzentration aufweisen. Jeder vorbereitete Extrakt wird dann im Labor auf Wirksamkeit und Sicherheit getestet.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `Ein Jahr, vier Jahreszeiten`,
      texts: [
        `Auf der Domaine Clarins erfordert jede Pflanze unsere ständige Aufmerksamkeit! Im Frühjahr wird gejätet, der Boden vorbereitet und gepflanzt.`,
        `Im Sommer und Herbst ist es an der Zeit, die Pflanzen zu ernten und zu trocknen und dann wissenschaftliche Beobachtungen anzustellen.`,
        `Wenn der Winter kommt, bereiten sich die Teams auf die kommende Saison vor und analysieren die über das Jahr gesammelten wissenschaftlichen Daten.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `Vom Feld auf die Trockengestelle`,
      texts: [
        `Auf der Domaine Clarins werden jedes Jahr über 2,5 Tonnen an Pflanzen geerntet. Zuerst werden sie von Hand gepflückt, dann in kleine Stücke geschnitten und an der Sonne getrocknet. Einmal am Tag werden sie vorsichtig gewendet, bis sie vollständig trocken sind.`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100% Bio-Pflanzen"`,
      texts: [
        `Seit 2016 bereichern sechs Bio-Pflanzenextrakte von Clarins (100 %) unser schönes Herbarium: Alpenrose, Hauswurz, Seifenkräuter, Zitronenmelisse, Pestwurz und Gelber Enzian. Sie sind in unseren Reinigern, Makeup-Entfernern und ClarinsMen-Pflegeprodukten zu finden. Bis 2025 möchten wir die Anzahl der angebauten Bio-Pflanzen verdoppeln.`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `Der Beginn eines langen Abenteuers`,
      texts: [
        `Seit 2016 bereichern fünf Clarins Bio-Extrakte (100%) unser wunderschönes Herbarium: Alpenrose, Hauslauch, Saponaria, Zitronenmelisse und Gelber Enzian. Sie sind in unseren Reinigungen, Makeup-Entfernern und ClarinsMen-Produkten enthalten. Bis 2025 wollen wir die Zahl der angebauten Bio-Pflanzen verdoppeln.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `Der Innovation und dem Experimentieren verpflichtet`,
      texts: [
        `Auf der Domaine Clarins werden neue Arten von organischen Pflanzen analysiert, um ihre Vorteile für die Schönheit zu bestimmen und ihre Sicherheit zu gewährleisten.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `Ein neues Biodiversitäts-Engagement`,
      texts: [
        `Clarins unterstützt das Conservatoire des Espaces Naturelles de Haute-Savoie Asters, das für die neun Naturschutzgebiete des Departements verantwortlich ist. Im Jahr 2021 haben wir mit ihnen eine Umweltverpflichtung (ORE) unterzeichnet, in der wir uns offiziell dazu verpflichten, die Biodiversität der Domaine für die nächsten 99 Jahre zu erhalten.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `Engagement zum Schutz der französischen Alpen`,
      texts: [
        `Clarins Liebe zu den Alpen nahm 1993 Gestalt an, als Christian Courtin Prinz Saddrudin Aga Khan, den Gründer von Alp Action, traf. Seitdem unterstützen wir den Schutz der alpinen Biodiversität in Partnerschaft mit Alp Action, jetzt Asters, einschließlich der Erhaltung bedrohter Arten. Dieses Engagement liegt uns immer noch am Herzen und wir haben heute noch so viel Leidenschaft dafür wie 1993!`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `Ein engagiertes Team`,
      texts: [
        `Zur Domaine Clarins gehört auch ein Team aus leidenschaftlichen Menschen, die eine tiefe Verbundenheit mit dem Land und ihrem Beruf teilen. Fünf Experten arbeiten dort unter der Leitung von Aurore Metral und Véronique Mothès, die beide für den Betrieb verantwortlich sind. Sie werden unterstützt vom Ethnobotaniker Jean-Pierre Nicolas.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `Gentiana lutea (großer gelber Enzian)`,
      texts: [
        `Der Gelbe Enzian ist eine robuste Pflanze, die bis zu 50 Jahre im Freiland gedeiht und auf der Domaine Clarins zu finden ist. Sie können die beruhigenden Vorteile dieses Bio-Pflanzenextrakts in unseren Reinigungen und Makeup-Entfernern genießen.`,
      ],
      cta: {
        title: `Entdecken Sie unsere Produkte`,
        href: `https://www.clarins.de/pflege-gesicht-reinigung/210/`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `Der Bartgeier`,
      texts: [
        `Wenn Sie in den Himmel blicken, entdecken Sie vielleicht den einen oder anderen Geier, der in der Alpenregion heimisch ist und 1986 mit Hilfe von Astern und Clarins wieder in Frankreich eingeführt wurde. Mit einer Flügelspannweite von etwa 3 Metern ist er der größte Greifvogel Europas. Heute sind es noch 70 in den Alpen, nachdem die Art vom Aussterben bedroht war.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `"Sempervivum tectorum (Gewöhnliche Hauswurz"`,
      texts: [
        `Die ursprünglich aus Westeuropa stammende Hauswurzpflanze ist extremen Temperaturen sowie übermäßiger Trockenheit und trockenem Gelände ausgesetzt. Trotzdem hat es grüne, fleischige Blätter, die voller Wasser sind. Wir verwenden Bio-Hauswurzextrakt in der ClarinsMen-Linie für die Hautfeuchtigkeit.`,
      ],
      cta: {
        title: `Entdecken Sie unsere Produkte`,
         href: `https://www.clarins.de/bestseller/`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `Ein Labor unter dem Himmel`,
      texts: [
        `Das lebende Freiluftlabor der Domaine ist dem Experimentieren gewidmet. Jedes Jahr werden etwa 15 Pflanzenarten aus Berg- und Flachlandgebieten untersucht, um die Teile zu bestimmen, die die höchste Konzentration an Wirkstoffen aufweisen. Die gesammelten Proben werden zur Analyse an die Clarins Laboratories in Pontoise, Frankreich, geschickt. Seit 2017 wurden über 1500 Proben auf ihre mögliche Verwendung in unseren Clarins Formeln geprüft.`,
      ],
    },
    {
      name: `melisse`,
      title: `Zitronenmelisse`,
      texts: [
        `"Untersuchungen zeigen, dass die Zitronenmelisse hilft, Stress und Angst zu reduzieren. In der Kosmetik hilft Bio-Zitronenmelissenextrakt, die Haut zu beruhigen, zu besänftigen und weich zu machen. Sie finden es in all unseren Reinigungsbehandlungen.`,
      ],
      cta: {
        title: `Entdecken Sie unsere Produkte`,
             href: `https://www.clarins.de/pflege-gesicht-reinigung/210/`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `Vertrauenswürdige Partner vor Ort`,
      texts: [
        `Loyalität liegt uns am Herzen. Aus diesem Grund arbeiten wir seit vielen Jahren mit denselben lokalen Partnern zusammen, mit denen wir eine gemeinsame Philosophie teilen und die die ethischen Praktiken der Domaine Clarins respektieren. Sie haben unser vollstes Vertrauen!`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `Wildpflanzen`,
      texts: [
        `Das Team der Domaine Clarins arbeitet mit 14 nahe gelegenen Betrieben zusammen, die für den ökologischen Landbau zertifiziert sind. Dies sind sogenannte „wilde“ Länder, in denen Pflanzen natürlich wachsen. Hier ernten wir nachhaltig Pestwurz, Gelben Enzian und Alpenrose.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `Hervorragende Rückverfolgbarkeit`,
      texts: [
        `Die Ernte unserer eigenen Pflanzen ermöglicht eine minimale Umweltbelastung ohne Kompromisse bei der Qualität. Jeder Schritt, von der Ernte bis zum Labor, ist zu 100% rückverfolgbar.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `Respekt vor dem Boden`,
      texts: [
        `Die Domaine Clarins verfügt über ein außergewöhnlich reichhaltiges Terrain: Der Boden ist nicht verschmutzt und natürlich angereichert. Um ihn mit größter Sorgfalt zu pflegen, folgen wir den Prinzipien der regenerativen Landwirtschaft. Diese verantwortungsvolle Art des Anbaus geht über die Standards der biologischen Landwirtschaft hinaus. Es wird alles für eine Nutzung getan, bei der die Böden und die biologische Vielfalt gestärkt werden.`,
      ],
      cta: {
        title: `Watch the video`,
        fakeDisable: true,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `Der Reichtum und die Reinheit der französischen Alpen`,
      texts: [
        `Natürliche Ressourcen sind auf der Domaine Clarins lebenswichtig. Regenwasser wird für die Pflanzen verwendet, während Sonnenkollektoren ausreichend Strom erzeugen, um das Wachstum zu fördern, ohne Eingriffe, die die Umwelt verschmutzen oder schädigen könnten.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `"Saponaria officinalis (gemeines Seifenkraut)"`,
      texts: [
        `Die Ernte unserer eigenen Pflanzen ermöglicht eine minimale Umweltbelastung ohne Kompromisse bei der Qualität. Jeder Schritt, von der Ernte bis zum Labor, ist zu 100% rückverfolgbar.`,
      ],
      cta: {
        title: `Entdecken Sie unsere Produkte`,
             href: `https://www.clarins.de/bestseller/`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `Rückverfolgbarkeit ist der Schlüssel`,
      texts: [
        `Das Logo „Domaine Clarins“ garantiert eine Qualitätskontrolle der Produktion von der Pflanzung bis zum Verkauf.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `Eine Quelle sauberer Energie`,
      texts: [
        `Auf der Domaine Clarins werden jedes Jahr über 2,5 Tonnen an Pflanzen geerntet. Zuerst werden sie von Hand gepflückt, dann in kleine Stücke geschnitten und an der Sonne getrocknet. Einmal am Tag werden sie vorsichtig gewendet, bis sie vollständig trocken sind.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `Verantwortungsbewusste Beschaffung`,
      texts: [
        `Wir glauben an Schönheit, die verantwortungsvoll und nachhaltig ist. Dies beinhaltet ethische landwirtschaftliche Praktiken und eine möglichst lokale Versorgung (Frankreich oder Europa). Zutaten, die von weiter her kommen, sind durch Fair-Trade-Programme möglich, um eine vollständige Rückverfolgbarkeit und langfristige Partnerschaften zu gewährleisten.`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `Schutznetz`,
      texts: [
        `Auf der Domaine Clarins wird das Unkraut von Hand gejätet. Um ihre Entwicklung einzuschränken, haben wir uns für einen natürlichen Schutz entschieden. Dieses Woll- oder Hanfnetz schützt auch die Pflanzen vor der Sonne, fördert die Blüte und hilft, die Fruchtbarkeit des Bodens zu erhalten.`,
      ],
    },
  ];
