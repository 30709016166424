
export default [
  {
    name: `entree-domaine`,
    title: `ドメーヌ クラランスの入り口`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `ゲンチアナとサボンソウ`,
    dialogHotspots: [
      {
        to: `saponaire-saponaria-officinalis`,
        enabled: false,
      },
      {
        to: `gentiane-jaune-gentiana-lutea`,
        enabled: false,
      },
    ],
  },
  {
    name: `devant-chalet`,
    title: `シャレー`,
  },
  {
    name: `chalet`,
    title: `シャレー`,
  },
  {
    name: `haut-domaine`,
    title: `ドメーヌ クラランスの頂上`,
  },
  {
    name: `jardin-collection`,
    title: `コレクションガーデン`,
  },
  {
    name: `parcelle-cheval`,
    title: `馬の放牧場`,
  },
  {
    name: `plantations`,
    title: `花壇の様子`,
    dialogHotspots: [
      {
        to: `joubarbe-des-toits`,
        enabled: false,
      },
    ],
  },
  {
    name: `partenaires`,
    title: `パートナー企業`,
  },
  {
    name: `drone-domaine`,
    title: `ドメーヌ クラランスの上空を飛ぶ`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `渓谷を発見する`,
    isInMap: false
  },
];
