<template>
  <div
    class="dialog"
    :class="{
      visible,
    }"
    @click="close"
  >
    <div class="dialog-content" @click.stop="" v-if="dialog !== null">
      <div
        class="video-wrapper"
        v-show="hasVideo"
      >
        <video
          :src="video"
          ref="video"
          @ended="videoEnded"
          playsinline
          webkit-playsinline
        />
        <a class="close-button" @click.prevent="close">×</a>
      </div>
      <div
        class="image-wrapper"
        sstyle="backgroundImageStyle"
        v-show="hasImage"
      >
        <img
          :src="image.src"
          class="image"
          v-if="image"
        >
        <a
          class="previous-button"
          :class="{
            inactive: !canGoPreviousImage,
          }"
          @click.prevent="previousImage"
          v-if="hasManyImages"
        >
          <img class="base" :src="arrowImg" v-if="arrowImg">
          <img class="hover" :src="arrowImgHover" v-if="arrowImgHover">
        </a>
        <a
          class="next-button"
          :class="{
            inactive: !canGoNextImage,
          }"
          @click.prevent="nextImage"
          v-if="hasManyImages"
        >
          <img class="base" :src="arrowImg" v-if="arrowImg">
          <img class="hover" :src="arrowImgHover" v-if="arrowImgHover">
        </a>
      </div>
      <div class="content-wrapper" v-show="hasContent">
        <h1>
          {{ dialog.title }}
        </h1>
        <div class="content">
          <p
            v-for="(text, i) in texts"
            :key="i"
          >
            {{ text }}
          </p>
          <div class="cta-wrapper" v-if="hasCta">
            <a class="cta clickable" :href="dialog.cta.href" @click="logCtaEvent(dialog)" target="blank" v-if="dialog.cta.href">
              {{ dialog.cta.title }}
            </a>
            <a class="cta cta-video clickable" @click="goToDialogFromCta(dialog)" v-if="dialog.cta.videoDialog">
              {{ dialog.cta.title }}
            </a>
          </div>
        </div>
        <a class="close-button" @click.prevent="close">×</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dialog: {
      type: Object,
      required: false,
      default: () => null,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
    icons: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data: () => ({
    currentImageIndex: 0,
  }),

  computed: {
    arrowImg() {
      return this.icons?.carouselArrow || ``;
    },
    arrowImgHover() {
      return this.icons?.carouselArrowHover || ``;
    },
    images() {
      const images = [];
      (this.dialog.images || []).forEach(dialogImage => {
        const index = images.findIndex(image => image.$src === dialogImage.$src);
        if (index === -1) {
          images.push(dialogImage);
        }
      });
      return images;
    },
    image() {
      return this.images[this.currentImageIndex];
    },
    video() {
      return this.dialog.video || null;
    },
    texts() {
      return this.dialog.texts || [];
    },
    hasImage() {
      return Boolean(this.image);
    },
    hasVideo() {
      return Boolean(this.video);
    },
    hasContent() {
      return this.dialog.title || this.texts.length || this.dialog.cta;
    },
    hasManyImages() {
      return this.images.length > 1;
    },
    hasCta() {
      return this.dialog.cta && (this.dialog.cta.href || this.dialog.cta.videoDialog) && this.dialog.cta.fakeDisable !== true;
    },
    canGoPreviousImage() {
      return this.currentImageIndex > 0;
    },
    canGoNextImage() {
      return this.currentImageIndex < (this.images.length - 1);
    },
    backgroundImageStyle() {
      if (!this.image) {
        return ``;
      }

      return `background-image: url('${this.image.src}');`;
    },
  },

  methods: {
    previousImage() {
      if (!this.canGoPreviousImage) { return; }
      this.currentImageIndex -= 1;
    },
    nextImage() {
      if (!this.canGoNextImage) { return; }
      this.currentImageIndex += 1;
    },
    close() {
      this.$emit(`close`);
    },
    logCtaEvent(dialog) {
      if (dialog.cta.event) {
        this.$gtagEvent(dialog.cta.event);
      }
    },
    goToDialogFromCta(dialog) {
      this.logCtaEvent(dialog);
      this.$emit(`go-to-dialog`, dialog.cta.videoDialog);
    },
    playVideo() {
      // this.$refs[`video`].muted = this.muted;
      this.$refs[`video`].muted = false;
      this.$refs[`video`].currentTime = 0;
      this.$refs[`video`].play();
      this.$emit(`video-playing`);
    },
    stopVideo() {
      this.$refs[`video`].currentTime = 0;
      this.$refs[`video`].pause();
      this.$emit(`video-stopped`);
    },
    videoEnded() {
      this.stopVideo();
      this.$emit(`close`);
    },
  },

  watch: {
    visible(visible) {
      if (visible) {
        this.currentImageIndex = 0;
        if (this.hasVideo) {
          this.playVideo();
        }
      } else {
        this.stopVideo();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$nextPreviousBtnsSize: 60px;

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.dialog-content {
  width: 90%;
  max-width: 1100px;
  max-height: 95%;
  position: relative;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.close-button {
  font-family: revert;
  font-size: 30px;
  line-height: 30px;
  position: absolute;
  color: #2c3e50;
  background-color: transparent;
  height: 30px;
  width: 30px;

  &:hover {
    color: #999;
  }
}

.image-wrapper {
  position: relative;
  z-index: 100;
  width: 45%;
  max-width: 800px;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.21);

  .image {
    width: 100%;
    height: auto;
  }

  .previous-button,
  .next-button {
    position: absolute;
    top: 50%;
    margin-top: -($nextPreviousBtnsSize / 2);
    width: $nextPreviousBtnsSize;
    height: $nextPreviousBtnsSize;
    cursor: pointer;

    &.inactive {
      opacity: 0;
      cursor: default;
    }

    img {
      width: 100%;
      height: 100%;
    }

    img.hover {
      display: none;
    }

    &:hover {
      img.base {
        display: none;
      }

      img.hover {
        display: block;
      }
    }
  }

  .previous-button {
    left: -($nextPreviousBtnsSize / 4);
    img {
      transform: rotate(180deg);
    }
  }

  .next-button {
    right: -($nextPreviousBtnsSize / 4);
  }
}

.video-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .close-button {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
    color: #B40024;
    background-color: #FFF;
    border-radius: 50px;
    &:hover {
      color: #2c3e50;
    }
  }

  video {
    max-height: 90vh;
    max-width: 90vw;
    height: 90vh;
    width: 90vw;
  }
}

.content-wrapper {
  position: relative;
  z-index: 99;
  overflow: auto;
  align-self: stretch;
  flex: 1;
  margin: 50px 0px;
  padding: 30px 60px;
  & {
    background: #FFF;
  }
  h1 {
    margin: 0;
    font-size: 22px;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
    color: #B40024;
  }
  .content {
    position: absolute;
    left: 60px;
    right: 60px;
    top: 120px;
    bottom: 30px;
    overflow: auto;
    text-align: justify;
    font-size: 14px;
    // padding-top: 30px;
  }

  .close-button {
    top: 20px;
    right: 20px;
  }

  .cta-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .cta {
      color: #333;
      text-decoration: underline;
      font-weight: 500;
      &:hover {
        color: #B40024;
      }
      &.cta-video {
        margin-left: 50%;
        transform: translateX(-50%);
        background-color: #B40024;
        font-size: 14px;
        text-align: center;
        color: #FFF;
        border-radius: 5px;
        padding: 15px 25px;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.21);
        text-decoration: none;
        min-width: 50%;
        font-weight: 500;
        &:hover {
          background-color: #8C0B26;
        }
      }
    }
  }
}


</style>
