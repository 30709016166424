<template>
  <div
    class="dialog"
    :class="{
      visible,
    }"
    @click="close"
  >
    <div class="dialog-content" @click.stop="" v-if="dialog !== null">
      <div
        class="video-wrapper"
        v-show="hasVideo"
      >
        <video
          :src="video"
          ref="video"
          @play="videoIsPlaying"
          @ended="videoEnded"
          playsinline
          webkit-playsinline
        />
        <a class="close-button" @click.prevent="close">×</a>
      </div>
      <div
        class="image-wrapper"
        :style="backgroundImageStyle"
        v-show="hasImage"
      >
        <a class="close-button" @click.prevent="close">×</a>
        <a
          class="previous-button"
          :class="{
            inactive: !canGoPreviousImage,
          }"
          @click.prevent="previousImage"
          v-if="hasManyImages"
        >
          <img :src="arrowImg">
        </a>
        <a
          class="next-button"
          :class="{
            inactive: !canGoNextImage,
          }"
          @click.prevent="nextImage"
          v-if="hasManyImages"
        >
          <img :src="arrowImg">
        </a>
      </div>
      <div class="content-wrapper" v-show="hasContent">
        <h1>
          {{ dialog.title }}
        </h1>
        <div class="content">
          <p
            v-for="(text, i) in texts"
            :key="i"
          >
            {{ text }}
          </p>
        </div>
        <div class="cta-wrapper" v-if="hasCta">
          <a class="cta" :href="dialog.cta.href" @click="logCtaEvent(dialog)" target="blank" v-if="dialog.cta.href">
            {{ dialog.cta.title }}
          </a>
          <a class="cta" @click="goToDialogFromCta(dialog)" v-if="dialog.cta.videoDialog">
            {{ dialog.cta.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrowImg from '@/assets/hotspots/arrow.png';

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dialog: {
      type: Object,
      required: false,
      default: () => null,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    currentImageIndex: 0,
    arrowImg,
  }),

  computed: {
    images() {
      const images = [];
      (this.dialog.images || []).forEach(dialogImage => {
        const index = images.findIndex(image => image.$src === dialogImage.$src);
        if (index === -1) {
          images.push(dialogImage);
        }
      });
      return images;
    },
    image() {
      return this.images[this.currentImageIndex];
    },
    video() {
      return this.dialog.video || null;
    },
    texts() {
      return this.dialog.texts || [];
    },
    hasImage() {
      return Boolean(this.image);
    },
    hasVideo() {
      return Boolean(this.video);
    },
    hasContent() {
      return this.dialog.title || this.texts.length || this.dialog.cta;
    },
    hasManyImages() {
      return this.images.length > 1;
    },
    hasCta() {
      return this.dialog.cta && (this.dialog.cta.href || this.dialog.cta.videoDialog);
    },
    canGoPreviousImage() {
      return this.currentImageIndex > 0;
    },
    canGoNextImage() {
      return this.currentImageIndex < (this.images.length - 1);
    },
    backgroundImageStyle() {
      if (!this.image) {
        return ``;
      }

      return `background-image: url('${this.image.src}');`;
    },
  },

  methods: {
    previousImage() {
      if (!this.canGoPreviousImage) { return; }
      this.currentImageIndex -= 1;
    },
    nextImage() {
      if (!this.canGoNextImage) { return; }
      this.currentImageIndex += 1;
    },
    close() {
      this.$emit(`close`);
    },
    logCtaEvent(dialog) {
      if (dialog.cta.event) {
        this.$gtagEvent(dialog.cta.event);
      }
    },
    goToDialogFromCta(dialog) {
      this.logCtaEvent(dialog);
      this.$emit(`go-to-dialog`, dialog.cta.videoDialog);
    },
    enterFullscreen() {
      const fullscreenElement = this.$refs[`video`];
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        fullscreenElement.msRequestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        fullscreenElement.webkitRequestFullscreen();
      }
    },
    exitFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozExitFullScreen) {
          document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } catch (e) {} // eslint-disable-line
    },
    playVideo() {
      this.$refs[`video`].muted = this.muted;
      this.$refs[`video`].currentTime = 0;
      this.$refs[`video`].controls = this.dialog.hasControls === true;
      // this.$refs[`video`].playsinline = this.dialog.isMobileFullscreen !== true;
      // this.$refs[`video`][`webkit-playsinline`] = this.dialog.isMobileFullscreen !== true;
      this.$refs[`video`].play();
      this.$emit(`video-playing`);
    },
    videoIsPlaying() {
      // if (this.dialog.isMobileFullscreen === true) {
      //   setTimeout(() => {
      //     this.enterFullscreen();
      //   }, 10);
      // }
    },
    stopVideo() {
      this.$refs[`video`].currentTime = 0;
      this.$refs[`video`].pause();
      this.$emit(`video-stopped`);
    },
    videoEnded() {
      // this.exitFullscreen();
      this.stopVideo();
      this.$emit(`close`);
    },
  },

  watch: {
    visible(visible) {
      if (visible) {
        this.currentImageIndex = 0;
        if (this.hasVideo) {
          this.playVideo();
        }
      } else {
        this.stopVideo();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$nextPreviousBtnsSize: 40px;

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.dialog-content {
  width: 90%;
  max-height: 95%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.close-button {
  font-family: revert;
  font-size: 25px;
  line-height: 30px;
  position: absolute;
  color: #d40f3f;
  background-color: #FFF;
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 250px;
  background-size: cover;
  background-position-y: center;
  background-color: #FFF;

  .close-button {
    top: 20px;
    right: 20px;
  }

  img {
    position: absolute;
    top: -50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .previous-button,
  .next-button {
    position: absolute;
    top: 75%;
    margin-top: -($nextPreviousBtnsSize / 2);
    width: $nextPreviousBtnsSize;
    height: $nextPreviousBtnsSize;

    &.inactive {
      opacity: 0.3;
    }
  }

  .previous-button {
    left: -($nextPreviousBtnsSize / 2);
    img {
      transform: rotate(180deg);
    }
  }

  .next-button {
    right: -($nextPreviousBtnsSize / 2);
  }
}

.video-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .close-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
  }

  video {
    max-height: 100vh;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
  }
}

.content-wrapper {
  padding: 40px 20px;
  overflow: auto;
  &, * {
    background: #FFF;
  }
  h1 {
    margin: 0;
    font-size: 22px;
    padding-bottom: 20px;
    color: #d40f3f;
  }
  .content {
    text-align: justify;
    font-size: 15px;
  }

  .cta-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .cta {
      background-color: #AB1822;
      font-size: 13px;
      color: #FFF;
      border-radius: 8px;
      padding: 15px 25px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.21);
      text-decoration: none;
      min-width: 50%;
      font-weight: 500;
    }
  }
}


</style>
