
export default [
  {
    name: `background_image`,
    image: require(`@/assets/loading/background-mobile.jpg`)
  },
  {
    name: `instruction_1`,
    image: require(`@/assets/loading/gestures.png`),
  },
];
