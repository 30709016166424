
export default [
  {
    name: `title`,
    title: `BESUCHEN SIE DIE DOMAINE CLARINS`,
  },
  {
    name: `instruction_1`,
    title: `Bewegen Sie Ihre Maus für einen 360° Besuch`,
  },
  {
    name: `instruction_2`,
    title: `Nutzen Sie die Pfeile für die Erkundung`,
  },
  {
    name: `instruction_3`,
    title: `Interessante Themen`,
  },
  {
    name: `instruction_4`,
    title: `zurück zur Homepage`,
  },
  {
    name: `loading_label`,
    title: `Wird geladen`,
  },
  {
    name: `start_btn`,
    title: `STARTEN SIE IHRE VIRTUELLE TOUR`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Besuchen Sie die Domaine Clarins im Herzen der Alpen.`,
      `Hier züchten und ernten wir unsere eigenen Pflanzen ohne Luft- und Bodenverschmutzung.`,
      `Mit größtem Respekt vor der Natur erzeugen wir Inhaltsstoffe für unsere qualitativen Produkte.`,
    ],
  },
];
