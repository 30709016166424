<template>
  <div
    class="mobile-map"
    :class="{ visible }"
    :style="`background-image: url('${map.backgroundImage}');`"
    v-if="hasMap"
  >
    <div class="items">
      <div
        class="map-scene-btn clarins-button"
        v-for="scene in filteredScenes"
        :key="scene.uuid"
        @click="goToScene(scene)"
      >
        {{ scene.title }}
      </div>
    </div>
    <!-- <a class="close-button" @click.prevent="closeMap">x</a> -->
    <a class="close-button" @click.prevent="closeMap">
      <img src="@/assets/map/close.png" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    scenes: {
      type: Array,
      required: false,
      default: () => [],
    },
    map: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
  }),

  computed: {
    hasMap() {
      return Boolean(this.map.backgroundImage);
    },
    filteredScenes() {
      return this.scenes.filter(scene => {
        return (scene.title || ``).trim() !== `` && scene.isInMap !== false;
      });
    },
  },

  methods: {
    closeMap() {
      this.$emit(`close`);
    },
    goToScene(scene) {
      this.$emit(`change-scene`, scene.name);
    },
  },
}
</script>

<style lang="scss" scoped>
$closeBtnSize: 50px;
.mobile-map {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  padding: 20px 20px $closeBtnSize;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .items {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .map-scene-btn {
      // background-color: #AB1822;
      width: 60%;
      max-width: 205px;
      // font-size: 13px;
      // color: #FFF;
      // border-radius: 20px;
      // padding: 20px 10px;
    }
  }

  .close-button {
    position: fixed;
    bottom: 40px;
    right: 30px;
    img {
      height: $closeBtnSize;
      width: $closeBtnSize;
    }
  }
}
</style>
