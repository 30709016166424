
export default [
  {
    name: `title`,
    title: `歡迎來到Domaine Clarins的中心`,
  },
  {
    name: `instruction_1`,
    title: `拖動滑鼠進行360°探索。`,
  },
  {
    name: `instruction_2`,
    title: `點擊箭嘴以更改區域。`,
  },
  {
    name: `instruction_3`,
    title: `點擊感興趣的地方以了解更多資訊。`,
  },
  {
    name: `instruction_4`,
    title: `點擊標誌返回主頁。`,
  },
  {
    name: `loading_label`,
    title: `加载`,
  },
  {
    name: `start_btn`,
    title: `開始我的虛擬之旅`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Clarins在阿爾卑斯山的中心地帶建立了自家莊園。`,
      `地方受完好保育，沒有空氣或土壤污染。`,
      `我們以對大自然最尊重的方式種植和收成植物。`,
      `我們成分的質量決定了我們產品的品質`,
    ],
  },
];
