<template>
  <div
    class="clarins-map"
    :class="{ visible }"
    v-if="hasMap"
  >
    <div class="flex-row justify-center align-center map-wrapper">
      <div style="position: relative;">
        <img :src="map.backgroundImage">
        <div
          v-for="(hotspot, i) in scenesHotspots"
          :key="i"
          class="generic-hotspot"
          :style="{
            left: `${hotspot.x}%`,
            top: `${hotspot.y}%`,
          }"
          @click="goToScene(hotspot.scene)"
        >
          <div
            class="hover-dialog"
            :class="{
              bottom: hotspot.direction === `bottom`,
            }"
          >
            <img :src="hotspot.image">
            <div class="legend" v-html="hotspot.scene.title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    scenes: {
      type: Array,
      required: false,
      default: () => [],
    },
    map: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
  }),

  computed: {
    hasMap() {
      return Boolean(this.map.backgroundImage);
    },
    scenesHotspots() {
      const filteredScenes = this.scenes.filter(scene => {
        return (scene.title || ``).trim() !== `` && scene.isInMap !== false;
      });

      const hotspots = [];
      (this.map?.hotspots || []).forEach(hotspot => {
        const scene = filteredScenes.find(scene => scene.name === hotspot.to);
        if (scene) {
          hotspots.push({
            ...hotspot,
            scene,
          });
        }
      });

      return hotspots;
    },
  },

  methods: {
    closeMap() {
      this.$emit(`close`);
    },
    goToScene(scene) {
      this.$emit(`change-scene`, scene.name);
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
$closeBtnSize: 50px;
$genericHotspotSizeDesktop: 35px;
.clarins-map {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #000;
  padding: 0px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .map-wrapper {
    img {
      height: 100vh;
      width: auto;
    }

    .generic-hotspot {
      position: absolute;
      top: -1000px;
      left: -1000px;
      transform: translateX(-50%) translateY(-50%);
      background-color: #B4001A;
      color: #FFF;
      height: $genericHotspotSizeDesktop;
      width: $genericHotspotSizeDesktop;
      border-radius: 50px;
      white-space: nowrap;
      border: 3px solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      cursor: pointer;
      z-index: 99;

      .hover-dialog {
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        width: 200px;
        background-color: #FFF;
        transform-style: preserve-3d;
        display: none;

        img {
          width: 100%;
          height: auto;
        }

        .legend {
          color: #333;
          font-weight: 300;
          padding: 16px 5px 20px;
          font-size: 15px;
          white-space: normal;
        }

        &:before {
          content: "";
          position: absolute;
          left: 50%;
          margin-left: -20px;
          background-color: #FFF;
          width: 40px;
          height: 40px;
          bottom: -18px;
          transform: rotate(45deg) translateZ(-1px);
        }

        &.bottom {
          top: auto;
          bottom: -32px;
          transform: translateX(-50%) translateY(100%);

          &:before {
            top: -18px;
            bottom: auto;
          }
        }
      }

      &:hover {
        border: 2px solid #FFF;
        z-index: 100;

        .hover-dialog {
          display: block;
        }
      }
    }
  }
}
</style>
