export default [
    {
      name: `actifs-excellence-absolue`,
      title: `탁월한 활성 성분`,
      texts: [
        `클라랑스의 재배 방식은 활성 성분을 풍부하게 함유한 독보적 품질의 식물을 보장합니다. 또한, 식물에서 추출물을 추출한 후에는 성능 및 자극에 대한 테스트를 거칩니다.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `도멘느 클라랑스에서 보낸 1년`,
      texts: [
        `도멘느 클라랑스에는 언제나 관심을 필요로 하는 식물들이 숨쉬고 있습니다! 봄이 되면 팔을 걷어 붙이고 제초 작업을 진행하고 땅을 고르며 새 생명이 자라날 환경을 조성합니다. 여름과 가을에는 식물을 수확하고 건조하는 시기이며, 과학적인 관찰을 하기도 합니다. 겨울이 다가오면 다음 시즌을 준비하면서 한 해 동안의 관측 결과를 분석합니다.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `수확부터 건조까지`,
      texts: [
        `식물은 활성 성분이 가장 풍부한 시기에 직접 손으로 수확됩니다. 수확된 식물들을 작은 크기로 자르고, 전통적인 방법에 따라 햇빛이 들지 않는 곳에서 건조기에 펼쳐 건조합니다. 완전히 마를 때까지 하루에 한 번씩 뒤집어줍니다. 도멘느 클라랑스에서는 연간 2.5톤 규모의 신선한 식물이 수확됩니다.`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100% 유기농 농작물`,
      texts: [
        `식물은 오염되지 않은 토양에서 계절의 리듬에 따라 자연을 존중하는 방식으로 재배되며, 유기농 농법의 엄격한 기준을 준수합니다. 환경적인 요소가 풍부한 토양은 식물과 동물에서 나온 물질에 의해서만 변화합니다. 그러므로 우리는 토양의 자연적인 균형을 존중하고 유지하기 위해 최소한의 작업만 수행합니다. 또한 별도의 식물위생처리가 아닌 식물 자체에서 추출한 추출물로만 가꾸고 있습니다. 도멘느 클라랑스에서는 이러한 고품질의 안정적인 생산에서 얻은 모든 방식을 활용하여 친환경적인 환경을 제공하고 있습니다.`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `漫기나긴 모험의 시작`,
      texts: [
        `2016년부터 클라랑스의 6가지 유기농 추출물인 알파인 로즈, 셈퍼비범, 비누풀, 레몬 밤, 머위, 옐로우 겐티안이 클라랑스의 식물 표본실에서 생산되고 있습니다. 이 추출물들은 클라랑스의 클렌저 및 메이크업 리무버와 클라랑스맨 제품에 사용됩니다. 클라랑스의 목표는 2025년까지 그 수를 두 배로 늘리는 것입니다.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `문화와 실험의 장소`,
      texts: [
        `도멘느 클라랑스에서 우리는 현재와 미래에 쓰일 유기농 식물을 재배합니다. 클라랑스의 연구팀은 새로운 스킨케어 제품의 특성을 분석하고 피부에 미치는 영향을 연구합니다.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `환경을 향한 새로운 약속`,
      texts: [
        `2021년 클라랑스는 오트사부아 재래종 보존을 지향하는 아스터(Asters, Conservatoire d’Espaces Naturels de Haute Savoie)의 진정한 환경적 의무(Real Environmental Obligation, REO)에 서명함으로써 생물 다양성을 향한 새로운 헌신을 약속함과 동시에 알프스와의 유대 관계를 한층 강화했습니다. 클라랑스는 이 계약을 통해 REO에서 명시하는 최장 기간인 99년 동안 도멘느 클라랑스의 생물 다양성을 보존할 것을 약속합니다. 자연 지역에 대해 이와 같은 유형의 헌신을 표명한 것은 사기업으로서는 최초입니다.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `알프스를 보존하기 위한 헌신적인 노력`,
      texts: [
        `클라랑스는 오래 전부터 알프스를 향한 애정을 표현해 왔습니다! 그 시작은 바로 크리스티앙 쿠르탱(Christian Courtin)이 사드루딘 아가 칸(Sadruddin Aga Khan) 왕자와 그가 설립한 알프 액션 재단(Alp Action Foundation)을 만난 1993년이었습니다. 클라랑스는 그 이후 알프스의 생물 다양성을 보존하기 위해 지속적인 지원을 이어오고 있습니다. 알프 액션 재단과의 파트너십을 바탕으로 5가지 프로그램을 통해 멸종 위기종을 보호하는 데 기여했으며, 이후에는 아스터(Asters)와 함께 그 명맥을 이어 가고 있습니다. 클라랑스는 언제나 그랬듯이 진심을 다해 열정적인 지원 활동을 쭉 이어오고 있습니다.`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `진심을 다하는 팀`,
      texts: [
        `도멘느 클라랑스에는 토양과 자신의 직업에 대한 깊은 애정을 공유하는 열정적인 팀이 있습니다. 이 팀은 운영을 담당하는 오로르 메트랄(Aurore Metral)과 베로티크 모테(Véronique Mothès)를 비롯하여 5명의 전문가로 이루어져 있으며, 민족 식물학자인 장 피에르 니콜라(Jean-Pierre Nicolas)가 고문을 맡고 있습니다.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `옐로우 겐티안 - Gentiana lutea`,
      texts: [
        `식물 요법에서 자주 사용되는 옐로우 겐티안은 예로부터 치유 효과를 지닌 식물로 잘 알려져 있었습니다. 광활한 대자연에서 굳세게 자라나는 이 식물은 주로 목초지에서 만나볼 수 있으며, 반세기에 달하는 오랜 생명력을 자랑합니다. 클라랑스의 수용성 클렌저에는 도멘느 클라랑스에서 재배되는 유기농(삭제요청) 겐티안 잎 추출물이 함유되어 그 진정 효과를 누릴 수 있습니다.`,
      ],
      cta: {
        title: `클라랑스 제품 자세히 보기`,
        href: `https://www.clarins.co.kr/%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%B0%94%EB%94%94-%EC%BC%80%EC%96%B4/%ED%8E%98%EC%9D%B4%EC%8A%A4/%ED%81%B4%EB%A0%8C%EC%A0%80-%ED%86%A0%EB%84%88-210/%ED%81%B4%EB%A0%8C%EC%A7%95-%EB%AF%B8%EC%85%80%EB%9D%BC-%EC%9B%8C%ED%84%B0-80062047.html`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `수염 독수리`,
      texts: [
        `알프스의 하늘을 올려다보면 1986년 아스터와 클라랑스의 적극적인 추진으로 프랑스에 다시 들여온 알프스 수염 독수리를 볼 수 있을지도 모릅니다. 날개 폭이 3미터에 달하는 이 독수리는 유럽 맹금류 중 가장 커다란 크기를 자랑합니다. 멸종 위기에 처한 이 종은 현재 알프스에 70마리가 서식하고 있습니다.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `셈퍼비범 - Sempervivum tectorum`,
      texts: [
        `서유럽에서 자생하는 셈퍼비범은 극한 기온 변화나 과한 가뭄, 건조한 토양 등에 노출되어도 늘 수분을 가득 머금은 녹색의 두꺼운 잎을 자랑하는 식물입니다. 클라랑스 연구소는 도멘느 클라랑스에서 재배되는 유기농 셈퍼비범 추출물을 이용하여 피부에 수분을 더해 주는 클라랑스맨 제품 라인을 선보입니다.`,
      ],
      cta: {
        title: `클라랑스 제품 자세히 보기`,
         href: ` https://www.clarins.co.kr/%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%B0%94%EB%94%94-%EC%BC%80%EC%96%B4/%ED%81%B4%EB%9D%BC%EB%9E%91%EC%8A%A4-%EB%A7%A8-500/`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `야외 실험실`,
      texts: [
        `도멘느 클라랑스는 여러가지 실험이 이루어지는 야외 실험실이기도 합니다. 이곳에서는 매년 산지와 평원에서 나는 약 15가지의 새로운 종에 대한 연구를 통해 활성 성분이 가장 많이 농축되어 있는 부분을 파악합니다. 수집된 샘플은 분석을 위해 프랑스 퐁투아즈(Pontoise)에 위치한 클라랑스 연구소로 보내집니다. 2017년 이후, 1,500개가 넘는 샘플이 이러한 분석을 거쳐 클라랑스 포뮬러에 한층 풍성함을 더해 주었습니다.`,
      ],
    },
    {
      name: `melisse`,
      title: `레몬 밤`,
      texts: [
        `예로부터 약용 식물로 재배되었던 레몬 밤은 컨디션을 완화하거나 스트레스를 줄여 숙면에 도움이 되는 것으로 알려져있습니다. 피부를 진정시키고 부드럽게 하는 데 도움이 되는 유기농 레몬밤 성분은 클라랑스의 모든 클렌저에서 찾을 수 있습니다.`,
      ],
      cta: {
        title: `클라랑스 제품 자세히 보기`,
          href: `https://www.clarins.co.kr/%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%B0%94%EB%94%94-%EC%BC%80%EC%96%B4/%ED%8E%98%EC%9D%B4%EC%8A%A4/%ED%81%B4%EB%A0%8C%EC%A0%80-%ED%86%A0%EB%84%88-210/`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `믿을 수 있는 현지 파트너`,
      texts: [
        `클라랑스는 신의의 가치를 소중히 여기며, 여러 해 동안 같은 현지 파트너와 협업하고 있습니다. 그들은 모두 클라랑스의 철학을 공유하며 탁월한 품질의 식물, 유기농 농법, 전통적인 방식과 같은 도멘느 클라랑스의 우수 관행을 존중합니다. 클라랑스는 현지 파트너를 전적으로 신뢰하며 오랜 기간동안 긴밀한 관계를 유지하기 위해 노력하고 있습니다.`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `야생 식물`,
      texts: [
        `도멘느 클라랑스 팀은 유기농 인증을 받은 인근 14곳의 인근 지역의 토지 소유자와 협력합니다. 인근 지역은 여러 식물이 자연적으로 서식하는 “야생지”로 알려져 있습니다. 클라랑스는 지속 가능하고 책임감 있는 방식으로 머위, 옐로우 겐티안, 알파인 로즈 잎을 수확합니다.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `클라랑스의 현지 생산 네트워크`,
      texts: [
        `클라랑스는 자체적으로 식물을 재배함으로써 우수한 품질을 유지하면서도 환경에 부정적인 영향을 미치지 않도록 관리하고 있습니다. 식물을 수확한 후에는 클라랑스 연구소에서 추출물을 활용하여 포뮬러를 제작합니다. 모든 단계는 100% 추적이 가능하여 투명성과 지속 가능성을 보장합니다.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `토양에 대한 존중`,
      texts: [
        `도멘느 클라랑스는 식물 재배를 위해 한 번도 오염되지 않은 흙이자 자연적인 성분이 함유된 비옥한 토양을 제공합니다. 클라랑스에서는 토양을 최대한으로 관리할 수 있도록 재생 농법의 원칙을 따릅니다. 이는 유기농 농법의 표준을 훌쩍 뛰어넘는 윤리적인 경작 방식입니다. 클라랑스는 토양과 생물 다양성 모두를 보호하기 위해 최선을 다하고 있습니다.`,
      ],
      cta: {
        title: `동영상 보기`,
        enabled: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `알프스의 풍요로움과 순수함`,
      texts: [
        `알프스의 중심에 위치한 도멘느 클라랑스는 가슴 속을 가득 채우는 깨끗한 공기와 잘 보존된 풍부한 토양, 수원지에서 바로 흘러나오는 물과 같은 알프스의 모든 이점을 고스란히 누리고 있습니다. 식물을 재배하기에 최적의 조건을 지닌 이곳에서 탁월한 품질의 제품이 탄생합니다.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `비누풀 - Saponaria officinalis`,
      texts: [
        `비누풀은 강둑, 도랑, 숲에서 발견되는 다년생 식물로, 여름에 향긋한 흰색 또는 분홍색의 꽃을 피웁니다. 선조들은 비누풀의 잎과 뿌리줄기를 사용하여 모직물을 세척하고 실과 레이스를 표백했습니다. 비누풀에는 천연 계면활성제인 사포닌이 풍부하게 함유되어 세정력 및 유화 효과가 뛰어납니다. 클라랑스 젠틀 포밍 클렌저 제품 라인에는 도멘느 클라랑스에서 재배하는 유기농 비누풀이 함유되어 피부를 부드럽게 클렌징해 줍니다.  `,
      ],
      cta: {
        title: `클라랑스 제품 자세히 보기`,
        href: `https://www.clarins.co.kr/%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%B0%94%EB%94%94-%EC%BC%80%EC%96%B4/%ED%8E%98%EC%9D%B4%EC%8A%A4/%ED%81%B4%EB%A0%8C%EC%A0%80-%ED%86%A0%EB%84%88-210/%ED%95%98%EC%9D%B4%EB%93%9C%EB%A0%88%EC%9D%B4%ED%8C%85-%EC%A0%A0%ED%8B%80-%ED%8F%AC%EB%B0%8D-%ED%81%B4%EB%A0%8C%EC%A0%80--%EC%A4%91%C2%B7%EA%B1%B4%EC%84%B1-%ED%94%BC%EB%B6%80--80071907.html`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `독보적인 추적 가능성`,
      texts: [
        `제품에 자리한 “도멘느 클라랑스” 로고는 재배부터 판매까지 전체 공급망이 완전하게 관리되고 있다는 것을 의미합니다.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `청정 에너지의 원천`,
      texts: [
        `도멘느 클라랑스에서의 아름다움은 자연과 조화를 이룹니다. 사람과 깨끗한 환경의 에너지를 모아 자연을 손상시키지 않고 식물을 키우고 있습니다. 빗물은 저장소에 저장한 뒤 농작물에 공급하고, 태양광 패널을 통해 건조기 및 목장에 공급할 전기를 생산합니다.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `책임감 있는 원료 조달`,
      texts: [
        `클라랑스는 책임감 있고 지속 가능한 아름다움을 믿습니다. 또한, 올바른 농업 관행을 선호하고 가급적 프랑스 또는 유럽에서 재배되는 현지 원료를 공급받습니다. 이보다 더 먼 곳에서 원료를 공급받는 경우에는 공정 무역에 대한 프리미엄을 적용합니다. 클라랑스는 수확지에 대한 완벽한 추적 가능성을 확보하며, 파트너와 장기적인 계약을 체결합니다.`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `보호막`,
      texts: [
        `도멘느 클라랑스에서는 식물을 보호하기 위해 손수 제초 작업을 합니다.  울 또는 헴프 소재의 캐노피는 햇빛으로부터 농작물을 보호하고 토양의 비옥함을 지켜 주며, 농작물이 더욱 풍성하게 자라날 수 있는 환경을 조성합니다.`,
      ],
    },
  ];
