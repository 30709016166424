
export default [
  {
    video: require(`@/assets/360/entree-domaine.mp4`),
    rotation: 3.1,
    name: `entree-domaine`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `cultures-100-bio`,
        x: 496.2,
        y: 55.54,
        z: -26.31,
      },
      {
        to: `debut-longue-aventure`,
        x: 418.51988996468333,
        y: 133.6191880391285,
        z: 238.7195306038309,
      },
      {
        to: `production-circuit-court`,
        x: -252.933,
        y: -2.908,
        z: 431.296,
      },
    ],
    sceneHotspots: [
      {
        to: `gentiane-et-saponaire`,
        x: 13.8,
				y: -2.4,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
      {
        to: `jardin-collection`,
        x: 463.86625552366837,
        y: 85.10474958301631,
        z: 166.08816509571915,
        xRotation: 45,
        yRotation: -5,
        zRotation: -36,
      },
      {
        to: `devant-chalet`,
        x: -186.145,
        y: -119.986,
        z: 448.278,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
    ],
  },
  {
    video: require(`@/assets/360/gentiane-et-saponaire.mp4`),
    rotation: 1.4,
    name: `gentiane-et-saponaire`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `saponaire-saponaria-officinalis`,
        x: 448.414,
        y: 10.982,
        z: -220.915,
      },
      {
        to: `gentiane-jaune-gentiana-lutea`,
        x: 255.2120168872271,
        y: -166.12133433274892,
        z: 396.5734846353962,
      },
    ],
    sceneHotspots: [
      {
        to: `parcelle-cheval`,
        x: 150.173,
        y: 22.461,
        z: -476.385,
        xRotation: 45,
        yRotation: -14,
        zRotation: -120,
      },
      {
        to: `entree-domaine`,
        x: 335.787,
        y: 12.489,
        z: 370.257,
        xRotation: 38,
        yRotation: 0,
        zRotation: -90,
      },
    ],
  },
  {
    video: require(`@/assets/360/devant-chalet.mp4`),
    rotation: -3.2,
    sound: `default`,
    name: `devant-chalet`,
    title: ``,
    dialogHotspots: [
      {
        to: `dedie-innovation`,
        x: 472.86507640044795,
        y: -9.947515331323066,
        z: 162.1717190496901,
      },
      {
        to: `sources-energie-propres`,
        x: 426.094137626,
        y: 164.8775047909124,
        z: 203.12359364356954,
      },
      {
        to: `richesse-purete-alpes`,
        x: 279.4265484941091,
        y: -181.73263889435327,
        z: 372.68492316857134,
      },
    ],
    sceneHotspots: [
      {
        to: `chalet`,
        x: 471.459548886546,
        y: -133.0551515280103,
        z: 100.11104042788051,
        xRotation: 50,
        yRotation: 0,
        zRotation: -90,
      },
      {
        to: `plantations`,
        x: -373.51473862611425,
        y: 139.2980191367056,
        z: -301.7992741768203,
        xRotation: 38,
        yRotation: 0,
        zRotation: -26,
      },
      {
        to: `entree-domaine`,
        x: -394.16878435437764,
        y: -101.41481010846816,
        z: 290.4238380905664,
        xRotation: 38,
        yRotation: 0,
        zRotation: -15,
      },
    ],
  },
  {
    image: require(`@/assets/360/chalet.jpeg`),
    rotation: 1.5,
    sound: `default`,
    name: `chalet`,
    title: ``,
    isInMap: false,
    dialogHotspots: [
      {
        to: `tracabilite-exemplaire`,
        x: 480.8640631186503,
        y: 12.035,
        z: -136.97773166362373,
      },
      {
        to: `ceuillette-au-sechage`,
        x: -105.54509975384347,
        y: -23.55322535482139,
        z: 488.1654202146402,
      },
    ],
    sceneHotspots: [
      {
        to: `entree-domaine`,
        x: -390.602,
        y: -110.190,
        z: -303.675,
        xRotation: 45,
        yRotation: 5,
        zRotation: -124,
      },
      {
        to: `jardin-collection`,
        x: -310.9752565763253,
        y: -7.760539266202595,
        z: -391.4513556338595,
        xRotation: 45,
        yRotation: -5,
        zRotation: -62,
      },
    ],
  },
  {
    video: require(`@/assets/360/haut-domaine.mp4`),
    rotation: 3.3,
    name: `haut-domaine`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `gentiane-jaune-gentiana-lutea`,
        x: -402.491,
        y: 21.368,
        z: -295.879,
      },
    ],
    sceneHotspots: [
      {
        to: `partenaires`,
        x: -352.207,
        y: 195.967,
        z: -295.883,
        xRotation: 45,
        yRotation: 0,
        zRotation: -100,
      },
      {
        to: `plantations`,
        x: -248.295,
        y: 15.884,
        z: 433.701,
        xRotation: 45,
        yRotation: 0,
        zRotation: -90,
      },
      {
        to: `jardin-collection`,
        x: 444.412,
        y: -223.650,
        z: 49.781,
        xRotation: 40,
        yRotation: 0,
        zRotation: 200,
      },
    ],
  },
  {
    video: require(`@/assets/360/jardin-collection.mp4`),
    rotation: 2.7,
    name: `jardin-collection`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `laboratoire-ciel-ouvert`,
        x: 499.627,
        y: 19.294,
        z: 0.203,
      },
    ],
    sceneHotspots: [
      {
        to: `plantations`,
        x: 317.762,
        y: 161.024,
        z: -350.854,
        xRotation: 45,
        yRotation: -5,
        zRotation: -110,
      },
      {
        to: `haut-domaine`,
        x: 476.076,
        y: 143.479,
        z: 52.583,
        xRotation: 45,
        yRotation: -5,
        zRotation: -110,
      },
      {
        to: `devant-chalet`,
        x: -228.543,
        y: -162.921,
        z: 413.793,
        xRotation: 45,
        yRotation: -5,
        zRotation: -140,
      },
      {
        to: `entree-domaine`,
        x: -382.635,
        y: -166.991,
        z: 275.145,
        xRotation: 45,
        yRotation: -5,
        zRotation: 0,
      },
      {
        to: `gentiane-et-saponaire`,
        x: -478.151,
        y: -117.160,
        z: -87.437,
        xRotation: 45,
        yRotation: -5,
        zRotation: -95,
      },
      {
        to: `parcelle-cheval`,
        x: -207.366,
        y: -8.736,
        z: -454.887,
        xRotation: 45,
        yRotation: -5,
        zRotation: -20,
      },
    ],
  },
  {
    video: require(`@/assets/360/parcelle-cheval.mp4`),
    rotation: -2.2,
    name: `parcelle-cheval`,
    title: ``,
    hasSound: true,
    dialogHotspots: [
      {
        to: `melisse`,
        x: 426.497566569214,
        y: -238.91221461002553,
        z: 104.9799000793579,
      },
      {
        to: `respect-sols`,
        x: 467.5804234173902,
        y: -175.43002963074855,
        z: -24.348559311974466,
      },
      {
        to: `plantes-sauvages`,
        x: 361.9657359635201,
        y: -108.25683113532473,
        z: -327.50765563712406,
      },
      {
        to: `actifs-excellence-absolue`,
        x: 164.00372899997708,
        y: -72.01629127559532,
        z: -466.8151996936375,
      },
    ],
    sceneHotspots: [
      {
        to: `gentiane-et-saponaire`,
        x: 454.35857267900195,
        y: -38.35951406340934,
        z: -205.15076191405979,
        xRotation: 35,
        yRotation: -5,
        zRotation: -96,
      },
      {
        to: `plantations`,
        x: 226.03855048544847,
        y: 73.90470556480527,
        z: -439.8234511594587,
        xRotation: 35,
        yRotation: -5,
        zRotation: -96,
      },
    ],
  },
  {
    video: require(`@/assets/360/plantations.mp4`),
    rotation: -3.3,
    name: `plantations`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `equipe-petits-soins`,
        x: 413.075,
        y: -281.656,
        z: 6.203,
      },
      {
        to: `annee-domaine-clarins`,
        x: 328.8279125269504,
        y: -320.08631711419883,
        z: 198.53703316871832,
      },
      {
        to: `joubarbe-des-toits`,
        x: -420.40673789112395,
        y: -189.8080085249615,
        z: 192.95360746959864,
      },
      {
        to: `voilages-protecteurs`,
        x: -232.42380126431993,
        y: -89.88109368527185,
        z: 433.4749884408364,
      },
    ],
    sceneHotspots: [
      {
        to: `parcelle-cheval`,
        x: 401.7187464965456,
        y: -170.1897751065251,
        z: 244.24882632765008,
        xRotation: 45,
        yRotation: 0,
        zRotation: -15,
      },
      {
        to: `jardin-collection`,
        x: 317.69927954876863,
        y: -205.7571793025544,
        z: -326.69733843367914,
        xRotation: 45,
        yRotation: -5,
        zRotation: -130,
      },
      {
        to: `partenaires`,
        x: -463.1947499113235,
        y: 187.91417195546896,
        z: -11.785059731578054,
        xRotation: 45,
        yRotation: 0,
        zRotation: -90,
      },
    ],
  },
  {
    video: require(`@/assets/360/partenaires.mp4`),
    rotation: -0.5,
    name: `partenaires`,
    title: ``,
    sound: `default`,
    dialogHotspots: [
      {
        to: `sourcing-responsable`,
        x: 313.880,
        y: -193.044,
        z: -337.953,
      },
      {
        to: `engages-sauvegarde-alpes`,
        x: 496.622,
        y: -10.536,
        z: 57.055,
      },
      {
        to: `partenaires-locaux`,
        x: 286.570,
        y: 63.694,
        z: 404.747,
      },
      {
        to: `gypaete-barbu`,
        x: 37.921,
        y: 170.342,
        z: 468.557,
      },
      {
        to: `engagement-preservation-alpes`,
        x: -388.732,
        y: 85.918,
        z: 302.498,
      },
    ],
    sceneHotspots: [
      {
        to: `haut-domaine`,
        x: 385.246,
        y: -256.362,
        z: -189.375,
        xRotation: 52,
        yRotation: 0,
        zRotation: -120,
      },
      {
        to: `plantations`,
        x: 457.337,
        y: -194.932,
        z: 53.322,
        xRotation: 52,
        yRotation: 0,
        zRotation: -85,
      },
    ],
  },
  {
    video: require(`@/assets/360/drone-domaine.mp4`),
    rotation: -1.8,
    name: `drone-domaine`,
    sound: `default`,
    videoEndsToScene: `entree-domaine`,
    exitButtonTo: `hub`,
  },
  {
    video: require(`@/assets/360/drone-montee.mp4`),
    rotation: 0.3,
    name: `drone-montee`,
    sound: `default`,
    videoEndsToScene: `partenaires`,
    exitButtonTo: `hub`,
  },
  {
    image: require(`@/assets/360/hub.jpg`),
    rotation: 2.6,
    zoom: 20,
    name: `hub`,
    sound: `default`,
    dialogHotspots: [],
    sceneHotspots: [],
    hubHotspots: [
      {
        to: `devant-chalet`,
        x: 420.2422514678712,
        y: -188.74225418938514,
        z: 194.35228726393743,
      },
      {
        to: `entree-domaine`,
        x: 429.2899370599845,
        y: -236.65992743057387,
        z: 98.49989181511899,
      },
      {
        to: `jardin-collection`,
        x: 471.0526049563524,
        y: -135.93167362280803,
        z: 98.14287274141182,
      },
      {
        to: `haut-domaine`,
        x: 487.8962215769124,
        y: -38.82927477818275,
        z: 102.22311084668065,
      },
      {
        to: `plantations`,
        x: 497.21766070910377,
        y: -24.71545714144966,
        z: -46.51606235759596,
      },
      {
        to: `gentiane-et-saponaire`,
        x: 439.2644996483044,
        y: -204.51640411677764,
        z: -123.36830952828898,
      },
      {
        to: `parcelle-cheval`,
        x: 402.9505565426275,
        y: -85.71971406009371,
        z: -283.3425128769817,
      },
      {
        to: `partenaires`,
        x: 488.97269063549584,
        y: 63.527866452661094,
        z: 82.88496846024945,
      },
      {
        to: `drone-domaine`,
        icon: require(`@/assets/hotspots/drone.png`),
        x: 470.69452863833396,
        y: 149.05737797574147,
        z: 78.92121882569712,
      },
      {
        to: `drone-montee`,
        icon: require(`@/assets/hotspots/drone.png`),
        x: 411.75544953720174,
        y: 73.29219945152097,
        z: -274.01770613589076,
      },
    ],
  },
];
