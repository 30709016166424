export default [
  {
    name: `actifs-excellence-absolue`,
    title: `Exceptional active ingredients`,
    texts: [
      `This method of cultivation creates the ideal conditions for growing highly concentrated active plant ingredients. Each extract is then tested for performance and tolerance.`,
    ],
  },
  {
    name: `annee-domaine-clarins`,
    title: `One year at Domaine Clarins`,
    texts: [
      `At Domaine Clarins, plants get our full attention all year long.`,
      `In the spring, it’s time for work: weeding, soil preparation, and new growth. During the summer and fall, it’s time to harvest and dry the plants. It’s also the season for scientific observations. `,
      `When winter comes, the teams prepare for the coming season and analyze the observations made throughout the year.`,
    ],
  },
  {
    name: `beaute-au-sommet`,
    title: `La beauté au sommet`,
    texts: [
      `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
      `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
    ],
  },
  {
    name: `ceuillette-au-sechage`,
    title: `From harvesting to drying`,
    texts: [
      `Our plants are hand-picked when they are richest in active ingredients. They are then cut into small pieces and spread out in a dryer. Following traditional methods, they are protected from the sun’s rays and are gently turned over once a day until they are completely dry. At Domaine Clarins, more than 2.5 tons of fresh plants are harvested each year`,
    ],
  },
  {
    name: `cultures-100-bio`,
    title: `100% organic crops`,
    texts: [
      `In soil that is free of pollution, our plants grow according to the rhythm of the seasons, and with respect for nature—strict criteria for organic farming. Naturally rich, the soil is modified only by the addition of organic matter. It is worked to a minimum to respect the natural balance of the soil. No phytosanitary treatments are used: the plants are treated with vegetal extracts. All the benefits of a virtuous and high-quality production are brought together at the Domaine.`,
      `*Organic ingredients are produced in accordance with European regulation (CE 2018/848).`,
    ],
  },
  {
    name: `debut-longue-aventure`,
    title: `The beginning of a long adventure`,
    texts: [
      `Since 2016, six Clarins organic extracts (100%) have enriched our beautiful herbarium: Alpenrose, House Leek, Saponaria, Lemon Balm, Petasites and Yellow Gentian. They can be found in our cleansers, makeup removers and ClarinsMen products. By 2025, we aim to double the number of organic plants grown.`,
    ],
  },
  {
    name: `dedie-innovation`,
    title: `Dedicated to innovation and experimentation`,
    texts: [
      `At Domaine Clarins, we grow organic plants for the products of today… and tomorrow. Our research teams analyze each new species for its cosmetic effects on the skin.`,
    ],
  },
  {
    name: `engagement-preservation-alpes`,
    title: `A new environmental commitment`,
    texts: [
      `In 2021, the connection between Clarins and the Alps was further strengthened by a major new pledge to protecting biodiversity: the signing of a Real Environmental Obligation (REO) with Asters, Conservatoire d’Espaces Naturels de Haute Savoie. Through this contract, we promise to preserve the Domaine’s biodiversity for 99 years—the maximum duration provided by the REO. This is the very first time that a private company has made this type of commitment to protecting a natural ecosystem.`,
    ],
  },
  {
    name: `engages-sauvegarde-alpes`,
    title: `Committed to saving the Alps`,
    texts: [
      `Clarins’ love for the Alps began in 1993 when Christian Courtin-Clarins crossed paths with Prince Sadruddin Aga Khan and his Alp Action Foundation, which has since been acquired by Asters.`,
      `Since then, the brand has provided ongoing support for biodiversity in the Alps by contributing to 5 programs that preserve endangered species. This commitment is very close to our hearts, and we continue to lend support with the same passion today as always.`,
    ],
  },
  {
    name: `equipe-petits-soins`,
    title: `A team that cares`,
    texts: [
      `Domaine Clarins also involves a team of passionate people who share a deep connection to the land and their profession. 5 experts work there under the guidance of Aurore Metral and Véronique Mothès, who are both in charge of operations with input from ethnobotanist Jean-Pierre Nicolas.`,
    ],
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    title: `Golden gentian - Gentiana lutea`,
    texts: [
      `A favorite ingredient in phytotherapy, Golden Gentian has been known since antiquity for its numerous curative effects. A robust plant of the great outdoors, it thrives in pastures and its lifespan is amazing: half a century. During this long life, it develops strong roots and reservoirs of active ingredients that help fight against digestive disorders. The soothing benefits of the Organic Golden Gentian Leaf extract grown at Domaine Clarins is in all of our water-based cleansers. `,
    ],
    cta: {
      title: `Discover one of our products`,
      href: `https://www.clarinsusa.com/en/velvet-cleansing-milk/80062049.html`,
    },
  },
  {
    name: `gypaete-barbu`,
    title: `The bearded vulture`,
    texts: [
      `Look up to the sky—you may have the chance to see this Alpine vulture, which was reintroduced in France in 1986 under the auspices of Asters and Clarins. With a wingspan of about 3 meters, it is the largest European bird of prey. There are now 70 in the Alps, although at one time the species had been threatened with extinction.`,
    ],
  },
  {
    name: `joubarbe-des-toits`,
    title: `Houseleek - Sempervivum tectorum`,
    texts: [
      `Native to Western Europe, the Houseleek withstands extreme temperatures, excessive drought, and arid soil. Regardless of these obstacles, it displays green and fleshy leaves that are full of water. The plant is grown on the Domaine, and its organic extract has been selected by our Laboratories for the ClarinsMen range to help the skin stay hydrated.`,
    ],
    cta: {
      title: `Discover one of our products`,
      href: `https://www.clarinsusa.com/en/clarinsmen-energizing-gel/80071984.html`,
    },
  },
  {
    name: `laboratoire-ciel-ouvert`,
    title: `An open-air laboratory`,
    texts: [
      `The Domaine’s living outdoor laboratory is dedicated to experimentation. Every year, around 15 plant species from the mountains and plains are being studied to determine the parts that are most concentrated in active ingredients. Once collected, the samples are sent to the Clarins Laboratories in Pontoise, France, for analysis. Since 2017, over 1500 samples have been reviewed for potential use in our Clarins formulas.`,
    ],
  },
  {
    name: `melisse`,
    title: `Lemon balm`,
    texts: [
      `Cultivated since antiquity as a medicinal plant, Lemon Balm relieves many ailments, including digestive and nervous disorders. Studies confirm that this herbaceous plant promotes relaxation and sleep by reducing stress and anxiety. In cosmetics, Organic Lemon Balm extract helps to calm, soothe and soften the skin. It is a key ingredient in all of our cleansers.`,
    ],
    cta: {
      title: `Discover one of our products`,
      href: `https://www.clarinsusa.com/en/velvet-cleansing-milk/80062049.html`,
    },
  },
  {
    name: `partenaires-locaux`,
    title: `Trusted local partners`,
    texts: [
      `Loyalty is a value we hold dear. Clarins has been working with the same partners for many years. They share our philosophy, and respect the sustainable practices followed at Domaine Clarins: high-quality plants, organic agriculture, traditional farming methods. They have our full confidence, and we are committed to continuing with them for the long term.`,
    ],
  },
  {
    name: `plantes-sauvages`,
    title: `Wild plants`,
    texts: [
      `Domaine Clarins works with the owners of 14 neighboring “wilderness” sites that are certified organic. We harvest Butterbur, Yellow Gentian, and Alpine Rose leaves in an ethical, sustainable, and responsible manner.`,
    ],
  },
  {
    name: `production-circuit-court`,
    title: `Our local production network`,
    texts: [
      `Growing our own plants allows us to control our environmental impact—without compromising on quality. After harvest, each extract is sent to the Clarins Laboratories for use in its formulas. Each step is 100% traceable, guaranteeing transparency and sustainability.`,
    ],
  },
  {
    name: `respect-sols`,
    title: `Respect for the soil`,
    texts: [
      `Domaine Clarins provides exceptionally rich land with soil that has never been contaminated, enriched with natural inputs. We follow the principles of regenerative agriculture to take the best possible care of the land. This ethical method of cultivation goes above and beyond organic farming standards. Every effort is made to ensure that both the soil and biodiversity are protected.`,
    ],
    cta: {
      title: `Watch the video`,
      enabled: false,
    },
  },
  {
    name: `richesse-purete-alpes`,
    title: `The richness and purity of the Alps`,
    texts: [
      `Located in the heart of the Alps, Domaine Clarins reaps all of the benefits of the mountains: pure air, rich and preserved soil, and water taken directly from the source—optimal conditions for growing our own organic plants, so that we can offer you the highest-quality products possible.`,
    ],
  },
  {
    name: `saponaire-saponaria-officinalis`,
    title: `Soapwort - Saponaria officinalis`,
    texts: [
      `A perennial plant found on the banks of rivers, ditches, and woods, Soapwort bears fragrant white or pink flowers in summer. Our ancestors used its leaves and rhizomes to wash woolen fabrics and bleach threads and laces. Soapwort has cleansing and emulsifying properties thanks to its high content of saponins, natural surfactants. Grown on the Domaine, Organic Soapwort makes it possible for our Gentle Foaming Cleanser range to purify even the most sensitive skin.  `,
    ],
    cta: {
      title: `Discover one of our products`,
      href: `https://www.clarinsusa.com/en/hydrating-gentle-foaming-cleanser/80071907.html`,
    },
  },
  {
    name: `tracabilite-exemplaire`,
    title: `Outstanding traceability`,
    texts: [
      `The Domaine Clarins logo seen on our products guarantees complete control of the supply chain—from farm to point-of-sale.`,
    ],
  },
  {
    name: `sources-energie-propres`,
    title: `Clean energy sources`,
    texts: [
      `At Domaine Clarins, beauty is in harmony with nature. Thanks to the collective energy of people and the pure environment, we grow our plants without polluting or causing harm. Rainwater is collected in rain barrels and used to water the crops. Solar panels have been installed to produce electricity for the dryer and chalet.`,
    ],
  },
  {
    name: `sourcing-responsable`,
    title: `Responsible sourcing`,
    texts: [
      `Clarins believes in responsible beauty. We favor sustainable agricultural practices and local sourcing from France or Europe. When our ingredients come from afar, we implement Fair Trade programs and longterm contracts with our partners that include the full traceability of each plant.`,
    ],
  },
  {
    name: `video-cheval`,
  },
  {
    name: `voilages-protecteurs`,
    title: `Protective veils`,
    texts: [
      `At Domaine Carins, weeding is done entirely by hand, and we use natural methods to protect the plants and the land. Wool or hemp canopies shield the crops from the sun’s rays, preserve the soil’s fertility, and encourage the crops to flourish.`,
    ],
  },
];
