
export default [
  {
    name: `home`,
    title: `На главную`,
  },
  {
    name: `map`,
    title: `Вид<br/>сверху`,
  },
  {
    name: `sound-on`,
    title: ` Выключить<br/>звук`,
  },
  {
    name: `sound-off`,
    title: `Включить<br/>звук`,
  },
  {
    name: `full-screen`,
    title: `На весь<br/>экран`,
  },
  {
    name: `gyroscope-on`,
    title: `гироскоп<br/>выключен`,
  },
  {
    name: `gyroscope-off`,
    title: `гироскоп<br/>активирован`,
  },
];
