
import Vue from 'vue';

const computeIsMobile = width => width < 700;

const DEFAULT_WIDTH = window.innerWidth;

class Breakpoints {
  constructor() {
    this.VM = new Vue({
      data: () => ({
        width: DEFAULT_WIDTH,
        isMobile: computeIsMobile(DEFAULT_WIDTH),
      }),
      watch: {
        width(width) {
          this.isMobile = computeIsMobile(width);
        },
      },
    });
  }
  get state() {
    return this.VM.$data;
  }
}

export default {
  install(Vue) {
    const breakpoints = new Breakpoints();
    let timer = null;
    window.addEventListener(`resize`, () => {
      clearTimeout(timer);
      timer = setTimeout(() => breakpoints.state.width = window.innerWidth, 50)
    });
    Vue.prototype.$breakpoints = breakpoints.state;
  },
};
