var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clarins-scene",class:{
    visible: _vm.visible,
    mobile: _vm.$breakpoints.isMobile,
    dragging: _vm.isUserInteracting,
  }},[_c('div',{staticClass:"scene-container scene-container-0",class:{
      visible: _vm.currentSceneI === 0,
    }}),_c('div',{staticClass:"scene-container scene-container-1",class:{
      visible: _vm.currentSceneI === 1,
    }}),_c('div',{staticClass:"hotspots-container",class:{
      visible: _vm.hotspotsVisible,
    }},[_vm._l((_vm.dialogHotspots),function(hotspot){return [_c('div',{key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"generic-hotspot",on:{"mouseup":function($event){return _vm.openDialog(hotspot.to)},"touchend":function($event){return _vm.openDialog(hotspot.to)}}},[(hotspot.legend && !_vm.$breakpoints.isMobile)?_c('div',{staticClass:"hotspot-legend",domProps:{"innerHTML":_vm._s(hotspot.legend)}}):_vm._e()])]}),_vm._l((_vm.hubHotspots),function(hotspot){return [_c('div',{key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"generic-hotspot hub",class:{
          'has-legend': !_vm.$breakpoints.isMobile && hotspot.legend,
        },on:{"mouseup":function($event){return _vm.goToScene(hotspot.to)},"touchend":function($event){return _vm.goToScene(hotspot.to)}}},[(hotspot.icon)?_c('div',{staticClass:"w-100 h-100 flex-row justify-center align-center img-icon-wrapper"},[_c('img',{staticClass:"img-icon",attrs:{"src":hotspot.icon}})]):_vm._e(),(!_vm.$breakpoints.isMobile && hotspot.legend)?_c('div',{staticClass:"h-100 flex-row justify-center align-center legend px-20",domProps:{"innerHTML":_vm._s(hotspot.legend)}}):_vm._e()])]}),_vm._l((_vm.sceneHotspots),function(hotspot){return [_c('div',{key:hotspot.uuid,ref:hotspot.uuid,refInFor:true,staticClass:"scene-hotspot"},[_c('img',{style:(("\n            transform: rotateX(" + (hotspot.xRotation) + "deg) rotateY(" + (hotspot.yRotation) + "deg) rotateZ(" + (hotspot.zRotation) + "deg);\n          ")),attrs:{"src":_vm.arrowImg},on:{"mouseup":function($event){return _vm.goToScene(hotspot.to)},"touchend":function($event){return _vm.goToScene(hotspot.to)}}}),(hotspot.legend)?_c('div',{staticClass:"hotspot-legend",domProps:{"innerHTML":_vm._s(hotspot.legend)}}):_vm._e()])]}),(_vm.scene.exitButtonTo)?_c('div',{staticClass:"generic-hotspot exit-button flex-row justify-center align-center",on:{"click":_vm.exitScene}},[_vm._v(" × ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }