export default [
  {
    name: `title`,
    title: `도멘느 클라랑스에 오신 것을 환영합니다`,
  },
  {
    name: `instruction_1`,
    title: ` 마우스를 드래그하여 360°로 둘러보세요.`,
  },
  {
    name: `instruction_2`,
    title: ` 화살표를 클릭하면 지역을 변경할 수 있습니다. `,
  },
  {
    name: `instruction_3`,
    title: `관심 지점을 클릭하여 자세히 살펴보세요.`,
  },
  {
    name: `instruction_4`,
    title: `홈페이지로 돌아가려면 로고를 클릭하세요.`,
  },
  {
    name: `loading_label`,
    title: `로딩`,
  },
  {
    name: `start_btn`,
    title: `가상 투어 시작하기`,
  },
  {
    name: `loading_texts`,
    texts: [
      `클라랑스는 알프스의 중심에 자체적인 도멘느 클라랑스를 운영하고 있습니다.`,
      `이곳의 자연은 대기나 토양의 오염 없이 온전히 보존되고 있습니다.`,
      `클라랑스는 자연에 대한 존중을 바탕으로 식물을 기르고 수확합니다. `,
      `원료의 품질이 바로 클라랑스 제품의 품질을 결정하기 때문입니다.`,
    ],
  },
];
