
export default [
  {
    name: `title`,
    title: `ДОБРО ПОЖАЛОВАТЬ НА УЧАСТОК DOMAINE CLARINS `,
  },
  {
    name: `instruction_1`,
    title: `Зажмите левую кнопку мыши<br/>и вращайте курсор, чтобы получить обзор на 360°.`,
  },
  {
    name: `instruction_2`,
    title: `Кликайте на стрелки, чтобы перемещаться<br/>по участку.`,
  },
  {
    name: `instruction_3`,
    title: `Кликайте на красные значки, чтобы получить подробную информацию.`,
  },
  {
    name: `instruction_4`,
    title: `Кликните на логотип, чтобы вернуться<br/>на главную страницу.`,
  },
  {
    name: `loading_label`,
    title: `загрузка`,
  },
  {
    name: `start_btn`,
    title: `НАЧАТЬ ВИРТУАЛЬНЫЙ ТУР`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Domaine Clarins - это собственный участок компании Clarins в Альпах. `,
      `Он расположен на охраняемой территории и отличается исключительной чистотой воздуха и почв. `,
      `Здесь мы самостоятельно выращиваем и собираем растения с уважением к природе. `,
      `Качество наших ингредиентов определяет качество наших средств.`,
    ],
  },
];
