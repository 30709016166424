export default [
  {
    name: `entree-domaine`,
    title: ` 庄园入口`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: ` 黄龙胆和肥皂草`,
  },
  {
    name: `devant-chalet`,
    title: `小木屋`,
  },
  {
    name: `chalet`,
    title: `小木屋`,
  },
  {
    name: `haut-domaine`,
    title: `庄园最高处`,
  },
  {
    name: `jardin-collection`,
    title: `花园`,
  },
  {
    name: `parcelle-cheval`,
    title: `养马牧场`,
  },
  {
    name: `plantations`,
    title: `花田`,
  },
  {
    name: `partenaires`,
    title: `我们的合作伙伴`,
  },
  {
    name: `drone-domaine`,
    title: `飞越庄园`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `探索山谷`,
    isInMap: false
  },
];
