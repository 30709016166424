
export default [
  {
    name: `title`,
    title: `歡迎來到克蘭詩純淨之巔的中心`,
  },
  {
    name: `instruction_1`,
    title: `拖動滑鼠進行360°探索。`,
  },
  {
    name: `instruction_2`,
    title: `點擊箭頭以更改區域。`,
  },
  {
    name: `instruction_3`,
    title: `點擊感興趣的地方以了解更多資訊。`,
  },
  {
    name: `instruction_4`,
    title: `點擊標誌返回主頁。`,
  },
  {
    name: `loading_label`,
    title: `加載`,
  },
  {
    name: `start_btn`,
    title: `開始我的虛擬之旅`,
  },
  {
    name: `loading_texts`,
    texts: [
      `克蘭詩在阿爾卑斯山的中心地帶建立了純淨之巔。`,
      `這裡悉心保育，沒有空氣或土壤汙染。`,
      `我們以最尊重大自然的方式種植和收成植物`,
      `優質的成分造就我們優質的保養品。`,
    ],
  },
];
