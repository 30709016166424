<template>
  <div
    class="clarins-scene"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
      dragging: isUserInteracting,
    }"
  >
    <div
      class="scene-container scene-container-0"
      :class="{
        visible: currentSceneI === 0,
      }"
    ></div>
    <div
      class="scene-container scene-container-1"
      :class="{
        visible: currentSceneI === 1,
      }"
    ></div>
    <div
      class="hotspots-container"
      :class="{
        visible: hotspotsVisible,
      }"
    >
      <template
        v-for="hotspot in dialogHotspots"
      >
        <div
          class="generic-hotspot"
          :key="hotspot.uuid"
          :ref="hotspot.uuid"
          @mouseup="openDialog(hotspot.to)"
          @touchend="openDialog(hotspot.to)"
        >
          <div class="hotspot-legend" v-if="hotspot.legend && !$breakpoints.isMobile" v-html="hotspot.legend" />
        </div>
      </template>
      <template
        v-for="hotspot in hubHotspots"
      >
        <div
          class="generic-hotspot hub"
          :class="{
            'has-legend': !$breakpoints.isMobile && hotspot.legend,
          }"
          :key="hotspot.uuid"
          :ref="hotspot.uuid"
          @mouseup="goToScene(hotspot.to)"
          @touchend="goToScene(hotspot.to)"
        >
          <div class="w-100 h-100 flex-row justify-center align-center img-icon-wrapper" v-if="hotspot.icon">
            <img
              :src="hotspot.icon"
              class="img-icon"
            />
          </div>
          <div class="h-100 flex-row justify-center align-center legend px-20" v-if="!$breakpoints.isMobile && hotspot.legend" v-html="hotspot.legend" />
        </div>
      </template>
      <template
        v-for="hotspot in sceneHotspots"
      >
        <div
          class="scene-hotspot"
          :key="hotspot.uuid"
          :ref="hotspot.uuid"
        >
          <img
            :src="arrowImg"
            :style="`
              transform: rotateX(${hotspot.xRotation}deg) rotateY(${hotspot.yRotation}deg) rotateZ(${hotspot.zRotation}deg);
            `"
            @mouseup="goToScene(hotspot.to)"
            @touchend="goToScene(hotspot.to)"
          />
          <div class="hotspot-legend" v-if="hotspot.legend" v-html="hotspot.legend" />
        </div>
      </template>
      <div class="generic-hotspot exit-button flex-row justify-center align-center" @click="exitScene" v-if="scene.exitButtonTo">
        ×
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import loadTexture from '@/three/loadTexture';
import arrowImg from '@/assets/hotspots/arrow.png';
import arrowImgHover from '@/assets/hotspots/arrow-hover.png';

const ANIMATION_DURATION = 500;

const scenes = [];
let draggingXOrigin = null;
let draggingYOrigin = null;
let onMouseDownCamRotX = 0;
let onMouseDownCamRotY = 0;
const lonQuatOffset = new THREE.Quaternion(- Math.sqrt(1/2), 0, 0, Math.sqrt(1/2));
const posObjects = {
  posVector: null,
  posViewVector: null,
  posFrustum: null,
  posMatrix: null,
};

export default {
  components: {
  },

  props: {
    scene: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hotspotsVisible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    dialog: false,
    isOpeningDialog: false,
    arrowImg: arrowImg,
    arrowImgHover: arrowImgHover,
    isAnimateEnabled: false,
    isGoingToScene: false,
    isMouseDown: false,
    isUserInteracting: false,
    currentSceneI: 0,
  }),

  computed: {
    dialogHotspots() {
      return this.scene.dialogHotspots;
    },
    hubHotspots() {
      return this.scene.hubHotspots;
    },
    sceneHotspots() {
      return this.scene.sceneHotspots;
    },
    currentSceneObj() {
      return scenes[this.currentSceneI];
    },
    sceneContainer() {
      return document.querySelector(`.clarins-scene .scene-container-${this.currentSceneI}`);
    },
  },

  methods: {
    async openDialog(dialogName) {
      if (!this.isUserInteracting && !this.isOpeningDialog) {
        this.isOpeningDialog = true;
        this.$emit(`open-dialog`, dialogName);
        await this.wait();
        this.isOpeningDialog = false;
      }
    },
    exitScene() {
      if (!this.scene.exitButtonTo) { return; }

      return this.goToScene(this.scene.exitButtonTo);
    },
    async goToScene(sceneName) {
      if (this.isGoingToScene) { return; }
      this.isGoingToScene = true;
      this.$emit(`change-scene`, sceneName);
      await this.wait(500);
      this.isGoingToScene = false;
    },
    async mountScene() {
      await this.init();
      this.isAnimateEnabled = true;
      this.animate({ firstTime: true });
      setTimeout(() => { this.isAnimateEnabled = false; }, 500);
      this.$emit(`scene-mounted`);
    },
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    async initScene(i = null) {
      const sceneI = i === null ? this.currentSceneI : i;
      let sceneObj = scenes[sceneI];
      if (!sceneObj) {
        scenes[sceneI] = {};
        sceneObj = scenes[sceneI];
      }
      sceneObj.hCam0 = new THREE.Object3D();
      sceneObj.hCam01 = new THREE.Object3D();
      sceneObj.hCam1 = new THREE.Object3D();
      sceneObj.hCam11 = new THREE.Object3D();
      const zoom = 90 - (this.scene.zoom || 0);
      sceneObj.camera = new THREE.PerspectiveCamera(zoom, window.innerWidth / window.innerHeight, 1, 1100);
      sceneObj.camera.target = new THREE.Vector3(0, 0, 0);

      sceneObj.threeScene = new THREE.Scene();

      sceneObj.threeScene.add(sceneObj.hCam0);
      sceneObj.hCam0.add(sceneObj.hCam01);
      sceneObj.hCam01.add(sceneObj.hCam1);
      sceneObj.hCam1.add(sceneObj.hCam11);
      sceneObj.hCam11.add(sceneObj.camera);

      sceneObj.hCam01.rotation.y = -Math.PI / 2;

      sceneObj.previousRotation = this.scene.rotation;
      sceneObj.geometry = new THREE.SphereGeometry(20, 20, 18, this.scene.rotation);
      sceneObj.geometry.scale(-1, 1, 1);

      let texture;

      if (this.scene.image) {
        texture = await loadTexture(this.scene.image);
      } else {
        const videoElement = document.getElementById(`video-scene-${this.scene.uuid}`);
        const defaultMuted = videoElement.getAttribute(`default-muted`) === true;
        videoElement.muted = defaultMuted || this.muted;
        videoElement.currentTime = 0;
        videoElement.play();
        texture = new THREE.VideoTexture(videoElement);
      }

      sceneObj.material = new THREE.MeshBasicMaterial( {
        map: texture,
      });

      sceneObj.mesh = new THREE.Mesh(sceneObj.geometry, sceneObj.material);

      sceneObj.threeScene.add(sceneObj.mesh);

      sceneObj.renderer = new THREE.WebGLRenderer({ alpha: true });
      sceneObj.renderer.setPixelRatio(window.devicePixelRatio);
      sceneObj.renderer.setClearColor(0x000000, 0);
      sceneObj.renderer.setSize(window.innerWidth, window.innerHeight);
      document.querySelector(`.clarins-scene .scene-container-${sceneI}`).appendChild(sceneObj.renderer.domElement);

      this.initSceneGyroProps(sceneObj);
    },
    initSceneGyroProps(scene) {
      scene.alpha = null;
      scene.beta = null;
      scene.gamma = null;

      scene.lonQuat = new THREE.Quaternion();
      scene.lonVecA = new THREE.Vector3(0, 1, 0);
      scene.lonVecB = new THREE.Vector3(0, 0, 0);
      scene.lonVecC = new THREE.Vector3(1, 0, 0);
      scene.lonEuler = new THREE.Euler();
      scene.lonPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

      scene.aziVecA = new THREE.Vector3(0, 1, 0);
      scene.aziVecB = new THREE.Vector3(0, 0, 0);
      scene.aziEuler = new THREE.Euler();
      scene.aziPlane = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0);
    },
    async init() {
      await this.initScene();

      this.addListeners();

      await this.wait();
    },
    videoEnded() {
      if (this.scene.videoEndsToScene) {
        this.$emit(`change-scene`, this.scene.videoEndsToScene);
      }
    },
    async updateScene() {
      let texture;

      if (this.scene.image) {
        texture = await loadTexture(this.scene.image);
      } else {
        const videoElement = document.getElementById(`video-scene-${this.scene.uuid}`);
        const defaultMuted = videoElement.getAttribute(`default-muted`) === true;
        videoElement.muted = defaultMuted || this.muted;
        videoElement.currentTime = 0;
        videoElement.play();
        texture = new THREE.VideoTexture(videoElement);
        if (this.scene.videoEndsToScene) {
          videoElement.addEventListener(`ended`, this.videoEnded, false);
        }
      }

      const sceneIDest = (this.currentSceneI + 1) % 2;

      let sceneObjDest = scenes[sceneIDest];

      if (!sceneObjDest) {
        await this.initScene(sceneIDest);
        sceneObjDest = scenes[sceneIDest];
      } else {
        sceneObjDest.hCam01.rotation.y = -Math.PI / 2;
        sceneObjDest.renderer.render(sceneObjDest.threeScene, sceneObjDest.camera); // Too much ?
        sceneObjDest.geometry.rotateY(sceneObjDest.previousRotation - this.scene.rotation);
        sceneObjDest.previousRotation = this.scene.rotation;
        sceneObjDest.mesh.material.map = texture;
        this.initSceneGyroProps(sceneObjDest);
        sceneObjDest.renderer.render(sceneObjDest.threeScene, sceneObjDest.camera);
      }

      this.currentSceneI = sceneIDest;

      this.enableAnimate();
      this.animate({ firstTime: true });

      setTimeout(() => {
        this.$emit(`scene-mounted`);
      }, ANIMATION_DURATION * 0.3);

      setTimeout(() => {
        this.disableAnimate();
      }, ANIMATION_DURATION);
    },
    resizeScene() {
      this.currentSceneObj.camera.aspect = window.innerWidth / window.innerHeight;
      this.currentSceneObj.camera.updateProjectionMatrix();

      this.currentSceneObj.renderer.setSize(window.innerWidth, window.innerHeight);
      this.posHotspots();
      this.currentSceneObj.renderer.render(this.currentSceneObj.threeScene, this.currentSceneObj.camera);
    },
    enableAnimate() {
      this.isAnimateEnabled = false;
      this.isAnimateEnabled = true;
      this.animate();
    },
    disableAnimate() {
      if (this.scene.image && !this.gyroEnabled) {
        setTimeout(() => { this.isAnimateEnabled = false; }, 100);
      }
    },
    animate({
      firstTime = false,
      i = 0,
      maxI = 1,
    } = {
      firstTime: false,
      i: 0,
      maxI: 1,
    }) {
      if (!this.isAnimateEnabled) { return; }
      const $maxI = firstTime ? 3000 : maxI;
      const force = i < $maxI;
      const newI = i + 1;
      requestAnimationFrame(() => this.animate({
        i: newI,
        maxI: $maxI,
      }));
      this.update(force);
      if (this.isUserInteracting || force || this.gyroEnabled) {
        this.posHotspots();
      }
    },
    update() {
      if (!this.currentSceneObj.camera || !this.render) { return; }

      if (this.gyroEnabled && this.currentSceneObj.alpha !== null) {
        const scene = this.currentSceneObj;
        const { alpha, gamma, beta } = scene;
        scene.lonEuler.set(
          gamma * Math.PI/180, alpha * Math.PI/180, beta * Math.PI/180, 'YZX'
        );
        scene.lonQuat.setFromEuler(scene.lonEuler);
        scene.lonQuat.multiply(lonQuatOffset);
        scene.lonVecA.x = 0;
        scene.lonVecA.y = 1;
        scene.lonVecA.z = 0;
        scene.lonVecA.applyQuaternion(scene.lonQuat);
        scene.lonPlane.projectPoint(scene.lonVecA, scene.lonVecB);
        let lon = scene.lonVecB.angleTo(scene.lonVecC);

        scene.aziEuler.set(
          beta * Math.PI/180, alpha * Math.PI/180, - gamma * Math.PI/180, 'YZX'
        );
        scene.aziVecA.x = 0;
        scene.aziVecA.y = 1;
        scene.aziVecA.z = 0;
        scene.aziVecA.applyEuler(scene.aziEuler);
        scene.aziPlane.projectPoint(scene.aziVecA, scene.aziVecB);

        scene.hCam1.rotation.x = -scene.aziVecB.y * Math.PI/2;
        const hCam1X = scene.hCam1.rotation.x;
        const hCam11X = scene.hCam11.rotation.x;
        const hCamX = hCam1X + hCam11X;
        if (hCamX > 0.8) {
          scene.hCam1.rotation.x = 0.8 - hCam11X;
        } else if (hCamX < -0.8) {
          scene.hCam1.rotation.x = -0.8 - hCam11X;
        }

        if(scene.lonVecB.z > 0) {
          scene.hCam0.rotation.y = 2 * Math.PI - lon;
        } else {
          scene.hCam0.rotation.y = lon;
        }
      }

      this.currentSceneObj.renderer.render(this.currentSceneObj.threeScene, this.currentSceneObj.camera);
    },
    posHotspot(hotspot) {
      const hotspotZ = hotspot.z || -10;
      posObjects.posVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
      posObjects.posViewVector = new THREE.Vector3(hotspot.x, hotspot.y, hotspotZ);
      const canvas = this.currentSceneObj.renderer.domElement;

      posObjects.posVector.project(this.currentSceneObj.camera);

      posObjects.posVector.x = Math.round((0.5 + posObjects.posVector.x / 2) * (canvas.width / window.devicePixelRatio));
      posObjects.posVector.y = Math.round((0.5 - posObjects.posVector.y / 2) * (canvas.height / window.devicePixelRatio));
      posObjects.posVector.z = hotspotZ;

      let xPx = `${posObjects.posVector.x}px`;
      let yPx = `${posObjects.posVector.y}px`;

      posObjects.posFrustum = new THREE.Frustum();
      posObjects.posMatrix = new THREE.Matrix4().multiplyMatrices(this.currentSceneObj.camera.projectionMatrix, this.currentSceneObj.camera.matrixWorldInverse);
      posObjects.posFrustum.setFromProjectionMatrix(posObjects.posMatrix);
      const isVisible = posObjects.posFrustum.containsPoint(posObjects.posViewVector);

      if (!isVisible) {
        xPx = `-100px`;
        yPx = `-100px`;
      }

      try {
        this.$refs[hotspot.uuid][0].style.left = xPx;
        this.$refs[hotspot.uuid][0].style.top = yPx;
      } catch (e) {} // eslint-disable-line

      delete posObjects.posVector;
      delete posObjects.posViewVector;
      delete posObjects.posFrustum;
      delete posObjects.posMatrix;
    },
    posHotspots() {
      delete this.posFrustum;
      delete this.posMatrix;
      this.dialogHotspots.forEach(hotspot => {
        this.posHotspot(hotspot);
      });
      this.hubHotspots.forEach(hotspot => {
        this.posHotspot(hotspot);
      });
      this.sceneHotspots.forEach(hotspot => {
        this.posHotspot(hotspot);
      });
      delete this.posFrustum;
      delete this.posMatrix;
    },
    onWindowResize() {
      this.currentSceneObj.camera.aspect = window.innerWidth / window.innerHeight;
      this.currentSceneObj.camera.updateProjectionMatrix();

      this.currentSceneObj.renderer.setSize( window.innerWidth, window.innerHeight );

      this.posHotspots();
    },
    getEventCoordinates(event) {
        let [x, y] = [null, null];

        if(event.type == 'touchstart' || event.type == 'touchmove' || event.type == 'touchend' || event.type == 'touchcancel'){
            const touchEvent = (typeof event.originalEvent === 'undefined') ? event : event.originalEvent;
            const touch = touchEvent.touches[0] || touchEvent.changedTouches[0];
            x = touch.pageX || null;
            y = touch.pageY || null;
        } else if (event.type == 'mousedown' || event.type == 'mouseup' || event.type == 'mousemove' || event.type == 'mouseover'|| event.type=='mouseout' || event.type=='mouseenter' || event.type=='mouseleave') {
            x = event.clientX || null;
            y = event.clientY || null;
        }

        return { x, y };
    },
    onDocumentMouseDown(event) {
      this.enableAnimate();
      event.preventDefault();
      this.isMouseDown = true;

      const { x, y } = this.getEventCoordinates(event);

      draggingXOrigin = x;
      draggingYOrigin = y;
      onMouseDownCamRotX = this.currentSceneObj.hCam11.rotation.x;
      onMouseDownCamRotY = this.currentSceneObj.hCam01.rotation.y;

      this.$emit(`user-interacted`);
    },
    onDocumentMouseMove(event) {
      if (!this.isMouseDown) {
        return;
      }
      const scene = this.currentSceneObj;
      this.sceneContainer.style.cursor = `grabbing`;
      const { x, y } = this.getEventCoordinates(event);
      if (draggingXOrigin === null || draggingYOrigin === null) {
          draggingXOrigin = x;
          draggingYOrigin = y;
      } else {
          this.isUserInteracting = true;
      }

      if (this.isUserInteracting === true) {
        let dragXDistance = x - draggingXOrigin;
        let dragYDistance = y - draggingYOrigin;
        scene.hCam11.rotation.x = onMouseDownCamRotX + dragYDistance * 0.0035;
        scene.hCam01.rotation.y = onMouseDownCamRotY + dragXDistance * 0.0035;

        const hCam1X = scene.hCam1.rotation.x;
        const hCam11X = scene.hCam11.rotation.x;
        const hCamX = hCam1X + hCam11X;
        if (hCamX > 0.8) {
          scene.hCam11.rotation.x = 0.8 - hCam1X;
        } else if (hCamX < -0.8) {
          scene.hCam11.rotation.x = -0.8 - hCam1X;
        }
      }

      this.$emit(`user-interacted`);
    },
    onDocumentMouseUp() {
      this.sceneContainer.style.cursor = `grab`;
      this.isMouseDown = false;
      draggingXOrigin = null;
      draggingYOrigin = null;
      setTimeout(() => { this.isUserInteracting = false; });
      this.disableAnimate();

      if (process.env.VUE_APP_SHOW_CAMERA_TARGET === `true`) {
        const target = this.currentSceneObj.camera.target;
        console.log(target); // eslint-disable-line
      }

      this.$emit(`user-interacted`);
    },
    deviceOrientationChanged({ alpha, gamma, beta }) {
      const scene = this.currentSceneObj;
      scene.alpha = alpha;
      scene.gamma = gamma;
      scene.beta = beta;
    },
    addListeners() {
      try {
        window.addEventListener('resize', this.resizeScene);
      } catch (e) {} // eslint-disable-line
      document.querySelectorAll(`.clarins-scene .scene-container`).forEach(sceneContainer => {
        sceneContainer.addEventListener( 'mousedown', this.onDocumentMouseDown, false );
        sceneContainer.addEventListener( 'mousemove', this.onDocumentMouseMove, false );
        sceneContainer.addEventListener( 'mouseup', this.onDocumentMouseUp, false );
        sceneContainer.addEventListener( 'touchstart', this.onDocumentMouseDown, { useCapture: false, passive: false });
        sceneContainer.addEventListener( 'touchmove', this.onDocumentMouseMove, { useCapture: false, passive: false });
        sceneContainer.addEventListener( 'touchend', this.onDocumentMouseUp, { useCapture: false, passive: false });
      });
    },
    removeListeners() {
      try {
        window.removeEventListener('resize', this.resizeScene);
      } catch (e) {} // eslint-disable-line
      document.querySelectorAll(`.clarins-scene .scene-container`).forEach(sceneContainer => {
        sceneContainer.removeEventListener('mousedown', this.onDocumentMouseDown, false );
        sceneContainer.removeEventListener('mousemove', this.onDocumentMouseMove, false );
        sceneContainer.removeEventListener('mouseup', this.onDocumentMouseUp, false );
        sceneContainer.removeEventListener('touchstart', this.onDocumentMouseDown, { useCapture: false, passive: false });
        sceneContainer.removeEventListener('touchmove', this.onDocumentMouseMove, { useCapture: false, passive: false });
        sceneContainer.removeEventListener('touchend', this.onDocumentMouseUp, { useCapture: false, passive: false });
      });
    },
  },

  mounted() {
    return this.mountScene();
  },

  beforeDestroy() {
    if (this.scene.video) {
      const videoElement = document.getElementById(`video-scene-${this.scene.uuid}`);
      videoElement.muted = true;
      videoElement.pause();
    }
    this.disableAnimate();
    this.removeListeners();
  },

  watch: {
    muted(muted) {
      if (this.scene.video && this.scene.hasSound) {
        const videoElement = document.getElementById(`video-scene-${this.scene.uuid}`);
        videoElement.muted = muted;
      }
    },
    async gyroEnabled(gyroEnabled) {
      if (gyroEnabled) {
        try {
          this.currentSceneObj.alpha = null;
          window.addEventListener(`deviceorientation`, this.deviceOrientationChanged, true);
          this.enableAnimate();
        } catch (e) {} // eslint-disable-line
      } else {
        try {
          this.currentSceneObj.alpha = null;
          window.removeEventListener(`deviceorientation`, this.deviceOrientationChanged, true);
          this.disableAnimate();
        } catch (e) {} // eslint-disable-line
      }
    },
    scene(scene, oldScene) {
      if (oldScene && oldScene.video) {
        const videoElement = document.getElementById(`video-scene-${oldScene.uuid}`);
        videoElement.muted = true;
        videoElement.removeEventListener(`ended`, this.videoEnded, false);
      }
      this.updateScene();
    },
  },
};
</script>

<style lang="scss" scoped>
$genericHotspotSizeMobile: 25px;
$genericHotspotSizeDesktop: 35px;
$imgHotspotSize: 40px;

.clarins-scene {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: grab;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  &.visible {
    opacity: 1;
  }

  .scene-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:not(.visible) {
      opacity: 0;
      z-index: 90;
      transition: opacity 0.6s ease-in-out;
      &, * {
        pointer-events: none;
      }
    }
    &.visible {
      opacity: 1;
      z-index: 91;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .hotspots-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    * {
      pointer-events: all;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    z-index: 95;
  }

  &.dragging {
    .generic-hotspot,
    .dialog-hotspot,
    .scene-hotspot,
    .hub-hotspot {
      pointer-events: none;
    }
  }

  .generic-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    height: $genericHotspotSizeMobile;
    width: $genericHotspotSizeMobile;
    border-radius: 50px;
    white-space: nowrap;
    border: 3px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &.hub {
      height: $genericHotspotSizeDesktop * 1.1;
      width: $genericHotspotSizeDesktop * 1.1;

      .legend {
        font-weight: 300;
      }
    }
  }

  .hub-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    font-size: 11px;
    border-radius: 50px;
    white-space: nowrap;
    border: 5px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 6px 11px;
  }

  .dialog-hotspot,
  .scene-hotspot {
    position: absolute;
    width: $imgHotspotSize;
    height: $imgHotspotSize;
    top: -100px;
    left: -100px;
    margin-top: -($imgHotspotSize / 2);
    margin-left: -($imgHotspotSize / 2);
    cursor: pointer;
    z-index: 95;
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot {
    cursor: pointer;

    .img-icon {
      height: $genericHotspotSizeMobile - 11px;
    }

    .hotspot-legend {
      position: absolute;
      top: $imgHotspotSize + 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFF;
      font-size: 13px;
      border-radius: 3px;
      white-space: nowrap;
      border: 8px solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      padding: 6px;
      color: #2c3e50;
      display: none;
      pointer-events: none;
    }

    &:hover {
      .hotspot-legend {
        display: block;
      }
    }
  }

  .scene-hotspot {
    cursor: default;

    img {
      cursor: pointer;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .legend {
      pointer-events: none;
      cursor: default;
    }
  }

  &:not(.mobile) {
    .generic-hotspot {
      height: $genericHotspotSizeDesktop;
      width: $genericHotspotSizeDesktop;

      &:hover {
        border: 2px solid #FFF;
      }

      &.hub.has-legend {
        height: $genericHotspotSizeDesktop * 1.2;
        width: $genericHotspotSizeDesktop * 1.2;
        &:hover {
          width: auto;
          height: $genericHotspotSizeDesktop * 1.4;
          z-index: 100;

          .img-icon-wrapper {
            display: none;
          }
        }
        &:not(:hover) {
          .legend {
            display: none;
          }
        }
      }
    }
  }

  .scene-hotspot {
    .hotspot-legend {
      display: block;
    }
  }

  .generic-hotspot.exit-button {
    bottom: 30px;
    left: 50%;
    top: auto;
    right: auto;
    font-size: 28px;
    height: $genericHotspotSizeMobile * 2;
    width: $genericHotspotSizeMobile * 2;
  }
}

.dialog-hotspot img,
.scene-hotspot img {
  width: 100%;
  height: 100%;
}
</style>
