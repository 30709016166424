
export default [
  {
    name: `title`,
    title: `WELCOME TO HEART OF DOMAINE CLARINS `,
  },
  {
    name: `instruction_1`,
    title: `Drag your mouse to explore it in 360°`,
  },
  {
    name: `instruction_2`,
    title: `Click on the arrows to change areas.`,
  },
  {
    name: `instruction_3`,
    title: `Click on the points of interest to learn more.`,
  },
  {
    name: `instruction_4`,
    title: `Click on the logo to return to the home page.`,
  },
  {
    name: `loading_label`,
    title: `Loading`,
  },
  {
    name: `start_btn`,
    title: `START MY VIRTUAL TOUR`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Domaine Clarins epitomises our company’s approach to responsible beauty.`,
      `Located high within the French Alps where the soil and air are free of pollution.`,
      `This protected outdoor living laboratory and farm is the perfect environment for growing many of the precious organic* ingredients used in our products—the key to the superb quality of our formulas.`,
      `*Organic ingredients are produced in accordance with European regulation (CE 2018/848).`,
    ],
  },
];
