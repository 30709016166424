<template>
  <div id="app">
    <splash-screen
      :visible="isSplashScreenOpened"
      :splashscreen-config="visit.splashScreen"
      :lang="lang"
      @choose-lang="langChosen"
      @close="closeSplashScreen"
    />
    <loading-screen
      :is-preloading="isPreloading"
      :loading-config="visit.loading"
      :visible="isLoadingScreenOpened"
      @close="closeLoadingScreen"
    />
    <intro-video
      :video="introVideo"
      :visible="isIntroVideoOpened"
      @close="introVideoClosed"
    />
    <template v-if="isVisitStarted">
      <audio
        ref="backgroundSound"
        :src="currentSound.src"
        loop
        v-if="currentSound !== null && !isMuted"
      />
      <clarins-visit
        :visit="visit"
        :muted="isMuted || !isTabActive"
        :lang="lang"
        :render="isTabActive"
        @play-sound="playSound"
        @resume-sound="resumeSound"
        @pause-sound="pauseSound"
        @mute="mute"
        @unmute="unmute"
        @toggle-fullscreen="toggleFullscreen"
      />
    </template>
    <pointer-cross v-if="hasPointerCross" />
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import mergeConfigFiles from '@/lib/mergeConfigFiles'

import loadTexture from '@/three/loadTexture';

import PointerCross from '@/components/PointerCross';
import SplashScreen from '@/components/SplashScreen';
import LoadingScreen from '@/components/LoadingScreen';
import IntroVideo from '@/components/IntroVideo';
import ClarinsVisit from '@/components/ClarinsVisit';

import fonts from '@/visit/fonts.js';
import langs from '@/visit/langs.js';

let DEFAULT_LANG = langs.find(lang => lang.isDefault === true);
if (!DEFAULT_LANG) { DEFAULT_LANG = langs[0]; }

const preloadedScenes = [];
const visitFiles = {}

require.context(`@/visit`, true, /^.*\.js$/)
  .keys()
  .map(fileName => fileName.replace(/^\W*/g, ``))
  .forEach(fileName => visitFiles[fileName] = require(`@/visit/${fileName}`))

const loadVisitConfigItem = (item, lang, isMobile) => {
  const platformFileKey = isMobile ? `.mobile` : ``;
  const langFileKey = `.${lang.key}`;
  const defaultLangFileKey = `.${DEFAULT_LANG.key}`;

  const itemSpecificConfig = visitFiles[`${item}/${item}${platformFileKey}${langFileKey}.js`];
  const itemLangConfig = visitFiles[`${item}/${item}${langFileKey}.js`];
  const itemDefaultLangConfig = visitFiles[`${item}/${item}${defaultLangFileKey}.js`];
  const itemPlatformConfig = platformFileKey ? visitFiles[`${item}/${item}${platformFileKey}.js`] : null;
  const itemGlobalConfig = visitFiles[`${item}/${item}.js`];
  const itemRes = mergeConfigFiles(
    itemGlobalConfig?.default,
    itemPlatformConfig?.default,
    itemDefaultLangConfig?.default,
    itemLangConfig?.default,
    itemSpecificConfig?.default
  );
  return itemRes;
};

function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

window.onpopstate = () => {
  window.location.reload();
};

export default {
  components: {
    PointerCross,
    SplashScreen,
    LoadingScreen,
    IntroVideo,
    ClarinsVisit,
  },

  data: () => ({
    hasPointerCross: process.env.VUE_APP_SHOW_CAMERA_TARGET === `true`,
    hasPreloading: true,
    isSplashScreenOpened: false,
    isLoadingScreenOpened: false,
    isPreloading: true,
    defaultIsVisitStarted: false,
    fullscreen: false,
    isVisitStarted: false,
    isIntroVideoOpened: false,
    isMuted: false,
    defaultSoundName: `default`,
    lang: DEFAULT_LANG,
    visit: {},
    currentSoundName: null,
    isTabActive: true,
    isSoundPlayingBeforeInactiveTab: true,
  }),

  computed: {
    urlLang() {
      const url = window.location.href
        .replace(/\?.*$/g, ``)
        .replace(/\/$/g, ``);
      const langCandidateKey = url.replace(/.*\/(.*)$/g, `$1`);
      const urlLang = langs.find(lang => lang.key === langCandidateKey);

      return urlLang || null;
    },
    langUrl() {
      return `//${window.location.host}/${this.lang.key}`
    },
    introVideo() {
      let video = this.visit.videos.find(video => video.name === `intro-video`);

      return video || null;
    },
    currentSound() {
      if (!this.currentSoundName) {
        return null;
      }

      const sound = this.visit.sounds.find(sound => sound.name === this.currentSoundName);

      return sound || null;
    },
  },

  methods: {
    langChosen(lang) {
      this.setLang(lang);
      this.closeSplashScreen();
    },
    loadVisitConfig() {
      const splashScreen = loadVisitConfigItem(`splashscreen`, this.lang, this.$breakpoints.isMobile);
      const loading = loadVisitConfigItem(`loading`, this.lang, this.$breakpoints.isMobile);
      const scenes = loadVisitConfigItem(`scenes`, this.lang, this.$breakpoints.isMobile);
      const dialogs = loadVisitConfigItem(`dialogs`, this.lang, this.$breakpoints.isMobile);
      const map = loadVisitConfigItem(`map`, this.lang, this.$breakpoints.isMobile);
      const menu = loadVisitConfigItem(`menu`, this.lang, this.$breakpoints.isMobile);
      const sounds = loadVisitConfigItem(`sounds`, this.lang, this.$breakpoints.isMobile);
      const videos = loadVisitConfigItem(`videos`, this.lang, this.$breakpoints.isMobile);
      const icons = loadVisitConfigItem(`icons`, this.lang, this.$breakpoints.isMobile);

      this.visit = {
        splashScreen,
        loading,
        dialogs,
        sounds,
        map,
        menu,
        icons,
        scenes: scenes.map(scene => {
          const dialogHotspots = [];
          (scene.dialogHotspots || []).forEach(hotspot => {
            const dialog = dialogs.find(dialog => dialog.name === hotspot.to);

            if (dialog) {
              dialogHotspots.push({
                ...hotspot,
                uuid: uuidv4(),
                legend: dialog?.title || null,
              });
            }
          });
          return {
            ...scene,
            uuid: uuidv4(),
            isVisible: false,
            isMounted: false,
            dialogHotspots,
            sceneHotspots: (scene.sceneHotspots || []).map(hotspot => {
              const hotspotScene = scenes.find(scene => scene.name === hotspot.to);

              return {
                ...hotspot,
                uuid: uuidv4(),
                legend: hotspotScene?.title || null,
                xRotation: hotspot.xRotation || 0,
                yRotation: hotspot.yRotation || 0,
                zRotation: hotspot.zRotation || 0,
              };
            }),
            hubHotspots: (scene.hubHotspots || []).map(hotspot => {
              const hotspotScene = scenes.find(scene => scene.name === hotspot.to);

              return {
                ...hotspot,
                uuid: uuidv4(),
                legend: hotspotScene?.title || null,
              };
            }),
          };
        }),
        videos: videos.map(video => ({
          ...video,
          uuid: uuidv4(),
        })),
      };
    },
    async preloadFonts() {
      if (!fonts.length) { return Promise.resolve(); }

      await Promise.all(fonts.map(async font => {
        const { data } = await axios.get(font.url, { responseType: `arraybuffer` });
        const base64Font = arrayBufferToBase64(data);
        const style = document.createElement(`style`);
        style.innerText = `
          @font-face {
            font-family: '${font.name}';
            font-weight: ${font.weight};
            src: url('data:font/truetype;charset=utf-8;base64,${base64Font}');
          }
        `.replace(/[\r\n]/g, ``).replace(/ +/g, ` `);
        document.head.appendChild(style);
      }));
      const style = document.createElement(`style`);
      style.innerText = `
        html, body, #app {
          font-family: ${fonts[0].name}, Avenir, Helvetica, Arial, sans-serif;
        }
      `.replace(/[\r\n]/g, ``).replace(/ +/g, ` `);
      document.head.appendChild(style);
    },
    async preloadImage(image) {
      const { data } = await axios.get(image, { responseType: `blob` });
      const base64Image = URL.createObjectURL(data);

      return base64Image;
    },
    preloadDialogImages(dialog) {
      return Promise.all((dialog.images || []).map(async image => {
        const base64Image = await this.preloadImage(image.src);
        image.$src = image.src;
        image.src = base64Image;
      }));
    },
    async preloadDialogVideo(dialog) {
      if (dialog.video) {
        const base64Video = await this.preloadVideo(dialog.video);
        dialog.$video = dialog.video;
        dialog.video = base64Video;
      }

      return Promise.resolve();
    },
    preloadDialogs() {
      return Promise.all(this.visit.dialogs.map(dialog => {
        const promises = [this.preloadDialogImages(dialog)];
        promises.push(this.preloadDialogVideo(dialog));

        return Promise.all(promises);
      }))
    },
    async preloadMap() {
      const { data } = await axios.get(this.visit.map.backgroundImage, { responseType: `blob` });
      const base64Image = URL.createObjectURL(data);
      this.visit.map.$backgroundImage = this.visit.map.backgroundImage;
      this.visit.map.backgroundImage = base64Image;
    },
    async preloadScene(scene) {
      let sceneToLoad = scene;
      if (typeof sceneToLoad === `string`) {
        sceneToLoad = this.visit.scenes.find(scene => scene.name === sceneToLoad);
      }
      if (!sceneToLoad || preloadedScenes.indexOf(sceneToLoad.name) !== -1) {
        return Promise.resolve();
      }
      if (sceneToLoad?.image) {
        await loadTexture(sceneToLoad.image)
      } else if (sceneToLoad?.video) {
        const base64Video = await this.preloadVideo(sceneToLoad.video);
        sceneToLoad.$video = sceneToLoad.video;
        sceneToLoad.video = base64Video;
        await this.create360VideoElement(sceneToLoad);
      }

      preloadedScenes.push(sceneToLoad.name);

      return Promise.resolve();
    },
    preloadScenes() {
      return Promise.all(this.visit.scenes.map(async scene => {
        return this.preloadScene(scene);
      }))
    },
    preloadSounds() {
      return Promise.all(this.visit.sounds.map(async sound => {
        const { data } = await axios.get(sound.src, { responseType: `blob` });
        const base64Sound = URL.createObjectURL(data);
        sound.$src = sound.src;
        sound.src = base64Sound;
      }));
    },
    async preloadVideo(src) {
      const { data } = await axios.get(src, { responseType: `blob` });
      const base64Video = URL.createObjectURL(data);

      return base64Video;
    },
    async preloadIntroVideo() {
      let video = this.visit.videos.find(video => video.name === `intro-video`);
      if (video) {
        const base64Video = await this.preloadVideo(video.src);
        video.$src = video.src;
        video.src = base64Video;
      }
    },
    preloadVideos() {
      return Promise.all(this.visit.videos.map(async video => {
        if (video.name !== `intro-video`) {
          const base64Video = await this.preloadVideo(video.src);
          video.$src = video.src;
          video.src = base64Video;
        }
      }));
    },
    preloadIcons() {
      return Promise.all(Object.keys(this.visit.icons).map(async key => {
        this.visit.icons[key] = await this.preloadImage(this.visit.icons[key]);
      }));
    },
    create360VideoElement(scene) {
      return new Promise(resolve => {
        const id = `video-scene-${scene.uuid}`;
        let videoElement = document.getElementById(id);
        let currentTime = 0;
        let isPaused = true;
        if (!videoElement) {
          videoElement = document.createElement(`video`);
          videoElement.id = id;
          videoElement.style.display = `none`;
          videoElement.setAttribute(`playsinline`, true);
          videoElement.setAttribute(`webkit-playsinline`, true);
          videoElement.setAttribute(`muted`, true);
          videoElement.setAttribute(`default-muted`, !(scene.hasSound === true));
          if (!scene.videoEndsToScene) {
            videoElement.setAttribute(`loop`, true);
          }
          document.body.appendChild(videoElement);
        } else {
          currentTime = videoElement.currentTime;
          isPaused = videoElement.paused;
        }
        videoElement.setAttribute(`src`, scene.video);
        videoElement.setAttribute(`currentTime`, currentTime);
        if (!isPaused) {
          videoElement.play();
        }
        setTimeout(() => {
          resolve(videoElement);
        }, 1000);
      });
    },
    create360VideoElements() {
      return Promise.all(this.visit.scenes.map(scene => {
        if (scene.image) { return Promise.resolve(); }
        return this.create360VideoElement(scene);
      }));
    },
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    async preload() {
      if (this.hasPreloading) {
        this.isPreloading = true;
        this.preloadIcons(),
        await this.wait(100);
        await Promise.all([
          this.preloadIntroVideo(),
          this.preloadScene(`hub`),
          this.preloadScene(`parcelle-cheval`),
          this.preloadScene(`entree-domaine`),
          this.preloadScene(`devant-chalet`),
          this.preloadScene(`chalet`),
          this.preloadSounds(),
          this.preloadDialogs(),
        ]),
        this.preloadScenes();
        this.preloadMap();
        this.preloadVideos();
      }
      await this.create360VideoElements();
      await this.wait(10);
      this.isPreloading = false;
    },
    closeSplashScreen() {
      this.isSplashScreenOpened = false;
      this.isLoadingScreenOpened = true;
      history.pushState({}, ``, this.langUrl);
      this.preload();
    },
    closeLoadingScreen() {
      this.isLoadingScreenOpened = false;
      if (!this.$breakpoints.isMobile && this.fullscreen) {
        this.enterFullscreen();
      }
      this.playIntroVideo();
      this.$gtagEvent(`Start`);
    },
    enterFullscreen() {
      const fullscreenElement = document.documentElement;
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        fullscreenElement.msRequestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        fullscreenElement.webkitRequestFullscreen();
      }
    },
    exitFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozExitFullScreen) {
          document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } catch (e) {} // eslint-disable-line
    },
    toggleFullscreen() {
      if (document.fullscreenElement !== null) {
        this.exitFullscreen();
      } else {
        this.enterFullscreen();
      }
    },
    playIntroVideo() {
      this.isIntroVideoOpened = true;
    },
    introVideoClosed() {
      this.isIntroVideoOpened = false;
      this.isVisitStarted = true;
    },
    pauseSound() {
      setTimeout(() => {
        try {
          this.$refs[`backgroundSound`].pause();
        } catch (e) {} // eslint-disable-line
      });
    },
    resumeSound() {
      setTimeout(() => {
        try {
          this.$refs[`backgroundSound`].play();
        } catch (e) {} // eslint-disable-line
      });
    },
    playSound(soundName) {
      this.currentSoundName = soundName;
      this.resumeSound();
    },
    mute() {
      this.isMuted = true;
    },
    unmute() {
      this.isMuted = false;
      this.resumeSound();
    },

    setLang(lang) {
      this.lang = lang;
      this.$gtagLang(lang.key);
      this.loadVisitConfig();
    },
    handleVisibilityChange() {
      if (document.visibilityState === `hidden`) {
        this.isTabActive = false;
      } else  {
        this.isTabActive = true;
      }
    },
  },

  created() {
    this.setLang(this.urlLang || DEFAULT_LANG);
  },

  beforeMount() {
    this.preloadFonts();
  },

  async mounted() {
    document.addEventListener(`visibilitychange`, this.handleVisibilityChange, false);
    if (!this.urlLang) {
      setTimeout(() => this.isSplashScreenOpened = true, 500);
    } else {
      setTimeout(() => this.isLoadingScreenOpened = true, 500);
      await this.preload();
      this.isVisitStarted = this.defaultIsVisitStarted;
    }
  },

  watch: {
    isVisitStarted(isVisitStarted) {
      if (isVisitStarted) {
        this.playSound(this.defaultSoundName);
      }
    },
    isTabActive(isTabActive) {
      if (!isTabActive) {
        this.isSoundPlayingBeforeInactiveTab = false;
        try {
          this.isSoundPlayingBeforeInactiveTab = !this.$refs[`backgroundSound`].paused;
        } catch (e) {
          console.log(e);
        } // eslint-disable-line
        this.pauseSound();
      } else if (this.isSoundPlayingBeforeInactiveTab) {
        this.resumeSound();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.powered-by-reality {
  position: fixed;
  bottom: 7px;
  right: 9px;
  height: 16px;
  text-decoration: none;
  img {
    height: 100%;
  }
}
</style>

<style lang="scss">
.clarins-button {
  padding: 0px 16px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  background-color: #B40024;
  height: 44px;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #8C0B26;
  }
}

.secondary-button {
  background-color: #F3F1F0;
  color: #333;
  border: 1px solid #CFCAC9;
  &:hover {
    background-color: #E9E3E1;
    color: #8C0B26;
    border: 1px solid #787474;
  }
}

.border-button {
  background-color: transparent;
  border: 1px solid #FFF;
  color: inherit;
}

.button-l {
  height: 64px;
  padding: 0px 26px;
}

img.lang-flag {
  max-height: 15px;
}

.clickable,
.close-button {
  cursor: pointer !important;
}
</style>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html, body, #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #FFF;
  overflow: hidden;
  touch-action: pan-x pan-y;
}

* {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row,
.flex-col {
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }
}

.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-stretch {
  align-self: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.d-block {
  display: block;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.pa-0 {
  padding: 0px !important;
}

.pa-3 {
  padding: 3px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-15 {
  padding: 15px !important;
}

.pa-20 {
  padding: 20px !important;
}

.pa-25 {
  padding: 25px !important;
}

.pa-30 {
  padding: 30px !important;
}

.pa-35 {
  padding: 35px !important;
}

.pa-40 {
  padding: 40px !important;
}

.pa-45 {
  padding: 45px !important;
}

.pa-50 {
  padding: 50px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-3 {
  margin: 3px !important;
}

.ma-5 {
  margin: 5px !important;
}

.ma-10 {
  margin: 10px !important;
}

.ma-15 {
  margin: 15px !important;
}

.ma-20 {
  margin: 20px !important;
}

.ma-25 {
  margin: 25px !important;
}

.ma-30 {
  margin: 30px !important;
}

.ma-35 {
  margin: 35px !important;
}

.ma-40 {
  margin: 40px !important;
}

.ma-45 {
  margin: 45px !important;
}

.ma-50 {
  margin: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
</style>
