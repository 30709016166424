
export default {
  backgroundImage: require(`@/assets/map/background.jpg`),
  hotspots: [
    {
      to: `devant-chalet`,
      image: require(`@/assets/map/chalet.jpg`),
      x: 75,
      y: 60,
    },
    {
      to: `entree-domaine`,
      image: require(`@/assets/map/entree-domaine.jpg`),
      x: 67,
      y: 66,
    },
    {
      to: `gentiane-et-saponaire`,
      image: require(`@/assets/map/gentiane-saponaire.jpg`),
      x: 57,
      y: 71,
    },
    {
      to: `haut-domaine`,
      image: require(`@/assets/map/haut-domaine.jpg`),
      x: 66.7,
      y: 35,
    },
    {
      to: `jardin-collection`,
      image: require(`@/assets/map/jardin-collection.jpg`),
      x: 66,
      y: 49,
    },
    {
      to: `parcelle-cheval`,
      image: require(`@/assets/map/cheval.jpg`),
      x: 25,
      y: 60,
    },
    {
      to: `partenaires`,
      image: require(`@/assets/map/partenaires.jpg`),
      x: 49,
      y: 6,
      direction: `bottom`,
    },
    {
      to: `plantations`,
      image: require(`@/assets/map/plantations.jpg`),
      x: 55,
      y: 33,
    },
  ],
};
