
export default [
  {
    name: `default`,
    src: require(`@/assets/sounds/default.mp3`),
  },
  {
    name: `horses`,
    src: require(`@/assets/sounds/horses.mp3`),
  },
];
