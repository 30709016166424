
export default [
  {
    name: `home`,
    title: `ようこそ`,
  },
  {
    name: `map`,
    title: `上空から見る`,
  },
  {
    name: `sound-on`,
    title: `アンミュート`,
  },
  {
    name: `sound-off`,
    title: `ミュート`,
  },
  {
    name: `full-screen`,
    title: `全画面表示`,
  },
  {
    name: `gyroscope-on`,
    title: `ジャイロオフ`,
  },
  {
    name: `gyroscope-off`,
    title: `ジャイロスコープ`,
  },
];
