
export default [
  {
    name: `home`,
    title: `Accueil`,
  },
  {
    name: `map`,
    title: `Vue de haut`,
  },
  {
    name: `sound-on`,
    title: `Couper son`,
  },
  {
    name: `sound-off`,
    title: `Activer son`,
  },
  {
    name: `full-screen`,
    title: `Plein écran`,
  },
  {
    name: `gyroscope-on`,
    title: `Gyro off`,
  },
  {
    name: `gyroscope-off`,
    title: `Gyro on`,
  },
];
