export default [
  {
    name: `title`,
    title: `欢迎来到娇韵诗庄园`,
  },
  {
    name: `instruction_1`,
    title: `拖动鼠标进行360°全方位的探索。`,
  },
  {
    name: `instruction_2`,
    title: ` 点击箭头以切换区域。`,
  },
  {
    name: `instruction_3`,
    title: `点击感兴趣的区域，可了解更多信息。`,
  },
  {
    name: `instruction_4`,
    title: ` 点击标志返回主页。`,
  },
  {
    name: `loading_label`,
    title: `加载`,
  },
  {
    name: `start_btn`,
    title: `开启360°虚拟之旅`,
  },
  {
    name: `loading_texts`,
    texts: [
      `法国娇韵诗，于阿尔卑斯山的中心地带建立了娇韵诗庄园。`,
      ` 这里经过悉心保护，没有空气和土壤污染。`,
      ` 我们秉承敬畏大自然的心种植与采摘植物。`,
      ` 以优质的植萃成分造就我们卓越的产品。`,
    ],
  },
];
