
export default [
  {
    url: require(`@/assets/fonts/GothamSSm-Book.otf`),
    name: `gotham`,
    weight: 400,
  },
  {
    url: require(`@/assets/fonts/GothamSSm-Medium.otf`),
    name: `gotham`,
    weight: 700,
  },
  {
    url: require(`@/assets/fonts/GothamSSm-ExtraLight.otf`),
    name: `gotham`,
    weight: 300,
  },
];
