
export default [
  {
    name: `title`,
    title: `BIENVENUE AU CŒUR DU DOMAINE CLARINS`,
  },
  {
    name: `instruction_1`,
    title: `Faites glisser votre souris pour l'explorer à 360°.`,
  },
  {
    name: `instruction_2`,
    title: `Cliquez sur les flèches pour changer de zone.`,
  },
  {
    name: `instruction_3`,
    title: `Cliquez sur les points d'intérêts pour en savoir plus.`,
  },
  {
    name: `instruction_4`,
    title: `Cliquez sur le logo pour revenir à la page d'accueil.`,
  },
  {
    name: `loading_label`,
    title: `Chargement`,
  },
  {
    name: `start_btn`,
    title: `JE COMMENCE MA VISITE VIRTUELLE`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Clarins a créé son propre Domaine au cœur des Alpes.`,
      `Un site préservé, sans pollution de l'air ni des sols.`,
      `Nous cultivons et récoltons nos propres plantes dans le respect de la nature.`,
      `La qualité de nos ingrédients fait la qualité de produits nos soins.`,
    ],
  },
];
