
export default [
  {
    name: `title`,
    title: `WELCOME TO HEART OF DOMAINE CLARINS `,
  },
  {
    name: `instruction_1`,
    title: `Drag your mouse to explore it in 360°`,
  },
  {
    name: `instruction_2`,
    title: `Click on the arrows to change areas.`,
  },
  {
    name: `instruction_3`,
    title: `Click on the points of interest to learn more.`,
  },
  {
    name: `instruction_4`,
    title: `Click on the logo to return to the home page.`,
  },
  {
    name: `loading_label`,
    title: `Loading`,
  },
  {
    name: `start_btn`,
    title: `START MY VIRTUAL TOUR`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Clarins has created its own Domaine in the heart of the Alps.`,
      `A preserved site, with no air or soil pollution.`,
      `We grow and harvest our own plants with the utmost respect for nature.`,
      `The quality of our ingredients determines the quality of our products.`,
    ],
  },
];
