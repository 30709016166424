
export default [
  {
    name: `home`,
    title: `Welcome`,
  },
  {
    name: `map`,
    title: `View from above`,
  },
  {
    name: `sound-on`,
    title: `Mute`,
  },
  {
    name: `sound-off`,
    title: `Unmute`,
  },
  {
    name: `full-screen`,
    title: `Full screen`,
  },
  {
    name: `gyroscope-on`,
    title: `Gyro off`,
  },
  {
    name: `gyroscope-off`,
    title: `Gyro on`,
  },
];
