
export default [
  {
    name: `entree-domaine`,
    title: `Вход на участок`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `Горечавка и сапонария`,
  },
  {
    name: `devant-chalet`,
    title: `Шале`,
  },
  {
    name: `chalet`,
    title: `Шале`,
  },
  {
    name: `haut-domaine`,
    title: `Верхняя часть участка`,
  },
  {
    name: `jardin-collection`,
    title: `Коллекционный сад`,
  },
  {
    name: `parcelle-cheval`,
    title: `Пастбище для лошадей`,
  },
  {
    name: `plantations`,
    title: `Плантации`,
  },
  {
    name: `partenaires`,
    title: `Наши партнеры`,
  },
  {
    name: `drone-domaine`,
    title: `Полет над участком`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `Полет над долиной`,
    isInMap: false
  },
];
