<template>
  <div id="clarins-visit" :class="{ visible }">
    <clarins-scene
      :scene="currentScene"
      :visible="isSceneVisible"
      :hotspots-visible="areHotspotsVisible"
      :muted="muted || isSceneMuted"
      :gyro-enabled="isGyroEnabled"
      :render="render"
      @scene-mounted="sceneMounted(currentScene)"
      @change-scene="changeScene"
      @open-dialog="openDialog"
      v-if="currentScene"
    />
    <clarins-map
      :visible="isMapOpened"
      :scenes="scenes"
      :map="map"
      @change-scene="changeScene"
      @close="closeMap"
      v-if="!$breakpoints.isMobile"
    />
    <clarins-menu
      :menu-config="visit.menu"
      :muted="muted"
      :gyro-enabled="isGyroEnabled"
      @open-map="openMap"
      @go-home="goHome"
      @toggle-fullscreen="$emit(`toggle-fullscreen`)"
      @change-scene="changeScene"
      @mute="$emit(`mute`)"
      @unmute="$emit(`unmute`)"
      @toggleGyroscope="toggleGyroscope"
    />
    <clarins-map-mobile
      :visible="isMapOpened"
      :scenes="scenes"
      :map="map"
      @change-scene="changeScene"
      @close="closeMap"
      v-if="$breakpoints.isMobile"
    />
    <clarins-dialog-mobile
      :dialog="currentDialog"
      :visible="isDialogVisible"
      :muted="muted"
      @video-playing="pauseSound"
      @video-stopped="resumeSoundAfterDialogClose"
      @go-to-dialog="saveDialogAndGoToDialog"
      @close="closeDialog"
      v-if="$breakpoints.isMobile"
    />
    <clarins-dialog
      :dialog="currentDialog"
      :visible="isDialogVisible"
      :muted="muted"
      :lang="lang"
      :icons="visit.icons"
      @video-playing="pauseSound"
      @video-stopped="resumeSoundAfterDialogClose"
      @go-to-dialog="saveDialogAndGoToDialog"
      @close="closeDialog"
      v-else
    />
    <a v-if="isMapOpened || this.currentSceneName == this.firstSceneName" class="powered-by-reality" href="https://reality.fr" target="_blank">
      <span>*Shot of the domain 2021</span> <img src="@/assets/poweredbyreality.png" />
    </a>
  </div>
</template>

<script>
import ClarinsDialogMobile from '@/components/ClarinsDialogMobile';
import ClarinsDialog from '@/components/ClarinsDialog';
import ClarinsScene from '@/components/ClarinsScene';
import ClarinsMenu from '@/components/ClarinsMenu';
import ClarinsMapMobile from '@/components/ClarinsMapMobile';
import ClarinsMap from '@/components/ClarinsMap';

export default {
  components: {
    ClarinsDialogMobile,
    ClarinsDialog,
    ClarinsScene,
    ClarinsMenu,
    ClarinsMapMobile,
    ClarinsMap,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    visit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data: () => ({
    previousDialogName: null,
    currentDialog: null,
    isDialogVisible: false,
    firstSceneName: `hub`,
    isChangingScene: false,
    previousSceneName: null,
    currentSceneName: null,
    isMapOpened: false,
    isSceneVisible: false,
    areHotspotsVisible: false,
    isSceneMuted: false,
    isGyroEnabled: false,
  }),

  computed: {
    scenes() {
      return this.visit?.scenes || [];
    },
    dialogs() {
      return this.visit?.dialogs || [];
    },
    map() {
      return this.visit?.map || [];
    },
    menu() {
      return this.visit?.menu || [];
    },
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }

      return currentScene || null;
    },
    isCurrentSceneHub() {
      return this.currentScene && this.currentScene.name === `hub`;
    },
    previousScene() {
      let previousScene = null;
      if (this.previousSceneName) {
        previousScene = this.getSceneByName(this.previousSceneName);
      }

      return previousScene || null;
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    pauseSound() {
      this.$emit(`pause-sound`);
    },
    resumeSound() {
      this.$emit(`resume-sound`);
    },
    async toggleGyroscope() {
      if (this.isGyroEnabled) {
        this.isGyroEnabled = false;
      } else if (window.DeviceOrientationEvent && window.DeviceOrientationEvent.requestPermission) {
        try {
          await window.DeviceOrientationEvent.requestPermission();
          this.isGyroEnabled = true;
        } catch (e) {} // eslint-disable-line
      } else {
        this.isGyroEnabled = true;
      }
    },
    resumeSoundAfterDialogClose() {
      if (!this.currentScene.hasSound) {
        this.resumeSound();
      }
    },
    async changeScene(sceneName) {
      this.isMapOpened = false;
      this.isDialogVisible = false;

      if (this.isChangingScene || this.currentSceneName === sceneName) { return; }

      this.isChangingScene = true;
      this.areHotspotsVisible = false;

      await this.wait(100);
      this.currentSceneName = sceneName;

      if (this.currentScene.hasSound) {
        this.pauseSound();
      } else {
        this.resumeSound();
      }
    },
    async sceneMounted(scene) {
      if (this.previousScene) {
        this.previousScene.isVisible = false;
        setTimeout(() => {
          this.previousScene.isMounted = false;
        }, 500)
      }
      scene.isVisible = true;
      this.isChangingScene = false;
      await this.wait();
      this.isSceneVisible = true;
      this.areHotspotsVisible = true;
    },
    openHub() {
      this.changeScene(`hub`);
    },
    openMap() {
      this.isDialogVisible = false;
      this.isMapOpened = true;
    },
    closeMap() {
      this.isMapOpened = false;
    },
    goHome() {
      this.closeMap();
      if (this.currentSceneName !== this.firstSceneName) {
        this.changeScene(this.firstSceneName);
      }
    },
    async openDialog(dialogName) {
      const dialog = this.getDialogByName(dialogName);
      this.currentDialog = dialog;
      await this.wait();
      if (this.currentDialog.video) {
        this.isSceneMuted = true;
      }
      this.isDialogVisible = true;
    },
    async closeDialog() {
      this.isDialogVisible = false;
      this.isSceneMuted = false;
      if (this.previousDialogName) {
        await this.wait();
        this.openDialog(this.previousDialogName);
      }
      this.previousDialogName = null;
    },
    async saveDialogAndGoToDialog(dialogName) {
      if (this.isDialogVisible) {
        this.previousDialogName = this.currentDialog.name;
        this.isDialogVisible = false;
        await this.wait();
      }
      this.openDialog(dialogName);
    },
  },

  mounted() {
    setTimeout(() => {
      this.changeScene(this.firstSceneName);
      if (!this.currentDialog) {
        this.currentDialog = this.dialogs[0];
      }
    });
  },
};
</script>

<style lang="scss" scoped>


.powered-by-reality {
  position: fixed;
  bottom: 7px;
  right: 9px;
  height: 16px;
  text-decoration: none;
  display:flex;
  img {
    height: 100%;
  }
  span{
    font-size: 10px;
    color: #fff;
    padding: 3px 10px;
  
  }
}

.clarins-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
