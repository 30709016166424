export default [
  {
    name: `actifs-excellence-absolue`,
    images: [
      {
        src: require(`@/assets/dialogs/actifs-excellence-absolue.jpg`),
      },
    ],
  },
  {
    name: `annee-domaine-clarins`,
    images: [
      {
        src: require(`@/assets/dialogs/annee-domaine-clarins.jpg`),
      },
    ],
  },
  {
    name: `beaute-au-sommet`,
    images: [
      {
        src: require(`@/assets/dialogs/beaute-au-sommet.jpg`),
      },
    ],
  },
  {
    name: `ceuillette-au-sechage`,
    images: [
      {
        src: require(`@/assets/dialogs/ceuillette-au-sechage.jpg`),
      },
    ],
  },
  {
    name: `cultures-100-bio`,
    images: [
      {
        src: require(`@/assets/dialogs/cultures-100-bio.jpg`),
      },
    ],
  },
  {
    name: `debut-longue-aventure`,
    images: [
      {
        src: require(`@/assets/dialogs/debut-longue-aventure.jpg`),
      },
    ],
  },
  {
    name: `dedie-innovation`,
    images: [
      {
        src: require(`@/assets/dialogs/dedie-innovation.jpg`),
      },
    ],
  },
  {
    name: `engagement-preservation-alpes`,
    images: [
      {
        src: require(`@/assets/dialogs/engagement-preservation-alpes.jpg`),
      },
    ],
  },
  {
    name: `engages-sauvegarde-alpes`,
    images: [
      {
        src: require(`@/assets/dialogs/engages-sauvegarde-alpes.jpg`),
      },
    ],
  },
  {
    name: `equipe-petits-soins`,
    images: [
      {
        src: require(`@/assets/dialogs/equipe-petits-soins.jpg`),
      },
    ],
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    images: [
      {
        src: require(`@/assets/dialogs/gentiane-jaune-gentiana-lutea.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/eau-micellaire-demaquillante/C080033240.html`,
      event: `CTA_Gentiane`,
    },
  },
  {
    name: `gypaete-barbu`,
    images: [
      {
        src: require(`@/assets/dialogs/gypaete-barbu.jpg`),
      },
    ],
  },
  {
    name: `joubarbe-des-toits`,
    images: [
      {
        src: require(`@/assets/dialogs/joubarbe-des-toits.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/gel-energisant-yeux-clarinsmen/80071985.html`,
      event: `CTA_Joubarbe`,
    },
  },
  {
    name: `laboratoire-ciel-ouvert`,
    images: [
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-1.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-2.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-3.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-4.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-5.jpg`),
      },
    ],
  },
  {
    name: `lieu-culture-experimentation`,
    images: [
      {
        src: require(`@/assets/dialogs/lieu-culture-experimentation.jpg`),
      },
    ],
  },
  {
    name: `melisse`,
    images: [
      {
        src: require(`@/assets/dialogs/melisse.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/doux-nettoyant-moussant-hydratant/80071907.html`,
      event: `CTA_Melisse`,
    },
  },
  {
    name: `partenaires-locaux`,
    images: [
      {
        src: require(`@/assets/dialogs/partenaires-locaux-1.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-2.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-3.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-4.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-5.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-6.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-7.jpg`),
      },
    ],
  },
  {
    name: `plantes-sauvages`,
    images: [
      {
        src: require(`@/assets/dialogs/plantes-sauvages.jpg`),
      },
    ],
  },
  {
    name: `production-circuit-court`,
    images: [
      {
        src: require(`@/assets/dialogs/production-circuit-court.jpg`),
      },
    ],
  },
  {
    name: `respect-sols`,
    images: [
      {
        src: require(`@/assets/dialogs/respect-sols.jpg`),
      },
    ],
    cta: {
      videoDialog: `video-cheval`,
    },
  },
  {
    name: `richesse-purete-alpes`,
    images: [
      {
        src: require(`@/assets/dialogs/richesse-purete-alpes.jpg`),
      },
    ],
  },
  {
    name: `saponaire-saponaria-officinalis`,
    images: [
      {
        src: require(`@/assets/dialogs/saponaire-saponaria-officinalis.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/doux-nettoyant-moussant-hydratant/80071907.html`,
      event: `CTA_Saponaire`,
    },
  },
  {
    name: `tracabilite-exemplaire`,
    images: [
      {
        src: require(`@/assets/dialogs/tracabilite-exemplaire.jpg`),
      },
    ],
  },
  {
    name: `sources-energie-propres`,
    images: [
      {
        src: require(`@/assets/dialogs/sources-energie-propres.jpg`),
      },
    ],
  },
  {
    name: `sourcing-responsable`,
    images: [
      {
        src: require(`@/assets/dialogs/sourcing-responsable.jpg`),
      },
    ],
  },
  {
    name: `video-cheval`,
    video: require(`@/assets/dialogs/video-cheval.mp4`),
    hasControls: true,
    isMobileFullscreen: true,
  },
  {
    name: `voilages-protecteurs`,
    images: [
      {
        src: require(`@/assets/dialogs/voilages-protecteurs.jpg`),
      },
    ],
  },
];
