
export default [
  {
    name: `entree-domaine`,
    title: `Entrada a la hacienda`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `La genciana y la jabonera`,
  },
  {
    name: `devant-chalet`,
    title: `La casa`,
  },
  {
    name: `chalet`,
    title: `La casa`,
  },
  {
    name: `haut-domaine`,
    title: `La parte alta de la Hacienda`,
  },
  {
    name: `jardin-collection`,
    title: `La colección del jardín`,
  },
  {
    name: `parcelle-cheval`,
    title: `La parte baja de la Hacienda`,
  },
  {
    name: `plantations`,
    title: `Las plantaciones`,
  },
  {
    name: `partenaires`,
    title: `Nuestros colaboradores`,
  },
  {
    name: `drone-domaine`,
    title: ` Sobrevuela la Hacienda`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `Descubre el valle`,
    isInMap: false
  },
];
