export default [
    {
      name: `actifs-excellence-absolue`,
      title: `Исключительные активные компоненты`,
      texts: [
        `Органическое выращивание растений позволяет нам получать сырье исключительного качества, насыщенное активными компонентами. Затем каждый полученный из него экстракт проходит многочисленные тесты на эффективность и безопасность.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `Год на участке`,
      texts: [
        `Растения на участке Domaine Clarins получают наше внимание в течение всего года.`,
        ` Весна - время активной работы: прополки, подготовки почвы, высаживания новых растений. Лето и осень посвящены сбору и сушке растений, а также научным исследованиям. `,
        ` Зимой команда готовится к новому сезону и анализирует наблюдения, сделанные за прошедший год.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `Сбор и сушка растений`,
      texts: [
        `Растения на участке Domaine Clarins собирают вручную в тот момент, когда они максимально насыщенны активными компонентами. Затем их разрезают на мелкие кусочки и сушат традиционным способом - в сушильне под защитой от солнечных лучей. Раз в день их аккуратно переворачивают до полного высыхания. 
 Каждый год на участке Domaine Clarins собирается более 2,5 тонн свежих растений.`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100% органическое производство`,
      texts: [
        `На участке Domaine Clarins мы выращиваем растения в соответствии с естественными природными циклами и принципами органического земледелия. Чистая богатая от природы почва удобряется исключительно веществами растительного и животного происхождения. Она подвергается минимальной обработке, не нарушающей ее естественный баланс. Здесь нет места никаким фитосанитарным процедурам: растения обрабатываются только растительными экстрактами. Участок Domaine Clarins воплощает в себе все преимущества честного и качественного производства.`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `Начало долгого пути`,
      texts: [
        `С 2016 года уже 6 собственных 100% органических экстрактов Clarins пополнили нашу базу ингредиентов. Это органические экстракты рододендрона, каменной розы, сапонарии, мелиссы и горечавки. Они входят в состав наших очищающих средств и средств ухода для мужчин. Наша цель - удвоить количество собственных ингредиентов к 2025 году.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `Выращивание и исследование растений`,
      texts: [
        `На участке Domaine Clarins мы выращиваем органические растения для уже существующих и будущих средств Clarins. 
  Наши ученые исследуют новые виды растений, выявляют их полезные свойства и изучают их воздействие на кожу.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `Новые экологические обязательства`,
      texts: [
        `В 2021 году связь Clarins и альпийского горного массива стала еще крепче благодаря новому экологическому обязательству, которое мы взяли на себя для защиты биоразнообразия этого региона. Компания Clarins подписала договор Obligation Réelle Environnementale (ORE) с Консерваторией природных территорий Верхней Савойи Asters. В соответствии с этим договором, мы обязуемся поддерживать биоразнообразие на территории участка Domaine Clarins в течение 99 лет (максимально возможный срок действия договора). Это первый случай в истории, когда частное предприятие берет на себя такого рода обязательства в отношении природной территории.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `Программа защиты Альп`,
      texts: [
        `Тесная связь между Clarins и Альпами установилась в 1993 году, когда Кристиан Куртен познакомился с принцем Садруддином Ага-ханом и его фондом Alp Action. С тех пор компания Clarins непрерывно поддерживает проекты, направленные на сохранение биоразнообразия альпийского региона.`,
        `В сотрудничестве с фондом Alp Action, а затем - с ассоциацией Asters за эти годы мы реализовали 5 программ по спасению видов, находящихся под угрозой исчезновения. Мы очень дорожим этим направлением нашей деятельности и относимся к нему с прежним энтузиазмом.`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `Увлеченная команда`,
      texts: [
        `Команда участка Domaine Clarins - это люди, влюбленные в природу и свое дело. Она включает до 15 человек, работающих под руководством Орор Метраль и Вероник Моте, отвечающих за эксплуатацию земель, а также при консультационной поддержке этноботаника Жана-Пьера Николя.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `Горечавкa`,
      texts: [
        `Горечавка желтая с античных времен ценится в традиционной медицине за свои многочисленные лечебные свойства. Это стойкое растение любит большие пространства и пышно цветет на пастбищах, поражая своей исключительной продолжительностью жизни - до полувека. За это время горечавка развивает мощную корневую систему и накапливает в ней активные компоненты, которые помогают, в частности, при нарушениях пищеварения. 
 Органический экстракт листьев горечавки, выращенной на участке Domaine Clarins, оказывает эффективное успокаивающее действие в составе наших очищающих средств.`,
      ],
      cta: {
        title: `Подробнее о средствах`,
          href: `https://www.clarins.ru/uhod-za-kozhei/uhod-za-litsom/ochishchenie-i-tonizirovanie/210/`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `Спасение популяции бородача`,
      texts: [
        `В небе над французскими Альпами сегодня вновь можно увидеть бородача, представителя семейства Ястребиные, популяция которого была восстановлена в этом регионе в 1986 году по инициативе Asters и Clarins. Бородач - это самая крупная хищная птица Европы с размахом крыльев до 3 метров. Этот вид находился под угрозой исчезновения, но сегодня в Альпах проживает уже около 70 особей.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `Каменная роза`,
      texts: [
        `Каменная роза или молодило - это суккулент родом из Западной Европы, способный выживать даже в условиях экстремальных температур, длительной засухи и бедных почв. Несмотря на это его листья всегда остаются зелеными и наполненными влагой. 
 Органический экстракт каменной розы, выращенной на участке Domaine Clarins, защищает кожу от обезвоживания в составе средств ухода для мужчин из линии ClarinsMen.
`,
      ],
      cta: {
        title: `Подробнее о средствах`,
          href: `https://www.clarins.ru/uhod-za-kozhej/dlya-muzhchin-500/`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `Лаборатория под открытым небом`,
      texts: [
        `Участок Domaine Clarins служит также экспериментальной площадкой. На отдельной территории мы выращиваем около 20 новых видов горных и равнинных растений и наблюдаем за ними, стараясь определить, какие части содержат наибольшую концентрацию активных компонентов. Полученные пробники отправляются в Лаборатории Clarins в Понтуазе на анализ. С 2017 года такую оценку на возможность дальнейшего использования в составе средств Clarins прошли около 900 пробников.`,
      ],
    },
    {
      name: `melisse`,
      title: `Мелисса`,
      texts: [
        `Мелисса с античных времен выращивается в качестве лекарственного растения: она помогает при множестве заболеваний, включая пищеварительные и нервные расстройства. Научные исследования подтверждают, что мелисса также способствует расслаблению, улучшению сна, уменьшению стресса и тревоги. 
 Органический экстракт листьев мелиссы, выращенной на участке Domaine Clarins, смягчает и успокаивает кожу в составе наших очищающих средств.
`,
      ],
      cta: {
        title: `Подробнее о средствах`,
           href: `https://www.clarins.ru/uhod-za-kozhei/uhod-za-litsom/ochishchenie-i-tonizirovanie/210/`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `Надежные партнеры`,
      texts: [
        `Верность - одна из наших ценностей. Clarins работает с одними и теми же партнерами на протяжении многих лет. Фермеры и сборщики растений, с которыми мы сотрудничаем, разделяют нашу философию и соблюдают те же принципы устойчивого развития, которые действуют на участке Domaine Clarins: исключительное качество растений, органическое сельское хозяйство, традиционные методы земледелия. Мы полностью доверяем своим партнерам и выстраиваем с ними долгосрочные отношения.`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `Дикорастущие растения`,
      texts: [
        `Команда Domaine Clarins сотрудничает с владельцами 14 соседних участков, также обладающих органическими сертификатами. Эти земли называются "дикими", поскольку растения произрастают здесь в абсолютно естественных условиях. На этих участках мы, в частности, собираем листья белокопытника, горечавки и рододендрона с уважением к природе и в полном соответствии с принципами устойчивого развития.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `Производство короткого цикла`,
      texts: [
        `Выращивание собственных растений позволяет нам контролировать наш экологический след, не жертвуя качеством продукции. После сбора и сушки растения поступают в собственные Лаборатории Clarins, где наши эксперты извлекают из них экстракты и составляют на их основе формулы средств. Таким образом, весь процесс производства является на 100% прозрачным и соответствующим нашим принципам устойчивого развития.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `Забота о почвах`,
      texts: [
        `Участок Domaine Clarins отличается исключительным богатством почвы. Мы относимся к ней крайне бережно, отдавая предпочтение традиционным методам ведения сельского хозяйства и не используя тяжелую технику. Бережное вспахивание при помощи плугов и лошадей сохраняет здоровье почвы и, в частности, жизнь земляных червей и микроорганизмов, отвечающих за ее плодородие. `,
      ],
      cta: {
        title: `Смотреть видео`,
        enabled: true,
        fakeDisable: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `Богатство и чистота Альп`,
      texts: [
        `Расположенный в самом сердце альпийского массива участок Domaine Clarins обладает всеми преимуществами гор: чистый воздух, богатая почва, вода из натуральных источников. Идеальные условия, чтобы выращивать растения для создания по-настоящему эффективных косметических средств.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `Сапонария`,
      texts: [
        `Сапонария - это многолетнее травянистое растение, часто встречающееся в лесах, вдоль канав и речных берегов. Летом оно покрывается множеством мелких белых или розовых цветков с приятным ароматом. В древние времена листья и корни сапонарии использовали для чистки изделий из шерсти и отбеливания кружев. Сапонария действительно обладает прекрасными очищающими и пенящимися свойствами благодаря высокой концентрации сапонинов, натуральных поверхностно-активных веществ. 
 Органические экстракты листьев и корня сапонарии, выращенной на участке Domaine Clarins, бережно очищают кожу в составе наших пенящихся кремов Doux Nettoyant Moussant. 
`,
      ],
      cta: {
        title: `Подробнее о средствах`,
          href: `https://www.clarins.ru/uhod-za-kozhei/uhod-za-litsom/ochishchenie-i-tonizirovanie/210/`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `Прозрачность происхождения`,
      texts: [
        `Логотип Domaine Clarins на наших средствах гарантирует, что мы полностью контролируем весь их жизненный путь, от выращивания растений до поступления готовой продукции в магазины.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `Чистые источники энергии`,
      texts: [
        `Участком Domaine Clarins правит Природа. Мы выращиваем растения в соответствии с естественными циклами, не загрязняя окружающую среду и не причиняя ей вреда. Для полива мы используем дождевую воду, которую собираем в специальные резервуары. Электричество для сушильни и шале получаем посредством солнечных панелей.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `Ответственное происхождение`,
      texts: [
        `Мы верим в принципы ответственной и устойчивой красоты. Мы отдаем предпочтение правильным сельскохозяйственным практикам и местному производству (Франция, Европа). При закупке растений в более далеких странах мы уделяем большое внимание поддержке программ справедливой торговли. Мы тщательно следим за прозрачностью происхождения наших ингредиентов и заключаем долгосрочные партнерские отношения с поставщиками.`,
      ],
    },
    {
      name: `video-cheval`,
      video: require(`@/assets/dialogs/video-cheval.ru_RU.mp4`),
    },
    {
      name: `voilages-protecteurs`,
      title: `Натуральная защита`,
      texts: [
        `На участке Domaine Clarins прополка полностью выполняется вручную. Для того чтобы сдерживать рост сорняков, мы выбрали натуральную защиту. Специальное покрытие из льна или конопли защищает растения от солнечных лучей, стимулирует их рост и поддерживает плодородие почвы.`,
      ],
    },
  ];
