export default [
  {
    name: `home`,
    title: `欢迎`,
  },
  {
    name: `map`,
    title: `以俯视角度观看`,
  },
  {
    name: `sound-on`,
    title: `静音`,
  },
  {
    name: `sound-off`,
    title: `取消静音`,
  },
  {
    name: `full-screen`,
    title: `全屏模式`,
  },
  {
    name: `gyroscope-on`,
    title: `陀螺关闭`,
  },
  {
    name: `gyroscope-off`,
    title: `陀螺仪开启`,
  },
];
