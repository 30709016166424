export default [
  {
    name: `actifs-excellence-absolue`,
    images: [
      {
        src: require(`@/assets/dialogs/actifs-excellence-absolue-mobile.jpg`),
      },
    ],
  },
  {
    name: `ceuillette-au-sechage`,
    images: [
      {
        src: require(`@/assets/dialogs/ceuillette-au-sechage-mobile.jpg`),
      },
    ],
  },
  {
    name: `cultures-100-bio`,
    images: [
      {
        src: require(`@/assets/dialogs/cultures-100-bio-mobile.jpg`),
      },
    ],
  },
  {
    name: `dedie-innovation`,
    images: [
      {
        src: require(`@/assets/dialogs/dedie-innovation-mobile.jpg`),
      },
    ],
  },
  {
    name: `engagement-preservation-alpes`,
    images: [
      {
        src: require(`@/assets/dialogs/engagement-preservation-alpes-mobile.jpg`),
      },
    ],
  },
  {
    name: `engages-sauvegarde-alpes`,
    images: [
      {
        src: require(`@/assets/dialogs/engages-sauvegarde-alpes-mobile.jpg`),
      },
    ],
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    images: [
      {
        src: require(`@/assets/dialogs/gentiane-jaune-gentiana-lutea.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/eau-micellaire-demaquillante/C080033240.html`,
    },
  },
  {
    name: `joubarbe-des-toits`,
    images: [
      {
        src: require(`@/assets/dialogs/joubarbe-des-toits.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/soins-homme/500/`,
    },
  },
  {
    name: `laboratoire-ciel-ouvert`,
    images: [
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-1-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-2-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-3-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-4-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/laboratoire-ciel-ouvert-5-mobile.jpg`),
      },
    ],
  },
  {
    name: `melisse`,
    images: [
      {
        src: require(`@/assets/dialogs/melisse.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/soins-visage-nettoyants-lotions-nettoyants-a-l-eau/214/`,
    },
  },
  {
    name: `partenaires-locaux`,
    images: [
      {
        src: require(`@/assets/dialogs/partenaires-locaux-1-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-2-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-3-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-4-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-5-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-6-mobile.jpg`),
      },
      {
        src: require(`@/assets/dialogs/partenaires-locaux-7-mobile.jpg`),
      },
    ],
  },
  {
    name: `saponaire-saponaria-officinalis`,
    images: [
      {
        src: require(`@/assets/dialogs/saponaire-saponaria-officinalis.jpg`),
      },
    ],
    cta: {
      href: `https://www.clarins.fr/doux-nettoyant-moussant-hydratant/CS00242987.html`,
    },
  },
];
