
export default [
  {
    name: `title`,
    title: `WELCOME TO HEART OF DOMAINE CLARINS `,
  },
  {
    name: `instruction_1`,
    title: `マウスをドラッグして360°探索できます。`,
  },
  {
    name: `instruction_2`,
    title: `矢印をクリックするとエリアが切り替わります。`,
  },
  {
    name: `instruction_3`,
    title: `興味のあるポイントをクリックすると、さらに詳しく知ることができます。`,
  },
  {
    name: `instruction_4`,
    title: `ロゴをクリックすると、トップページに戻ります。`,
  },
  {
    name: `start_btn`,
    title: `バーチャルツアーを開始する`,
  },
  {
    name: `loading_label`,
    title: `を読み込み中`,
  },
  {
    name: `loading_texts`,
    texts: [
      `クラランスは、アルプスの中心部に独自の自社農園ドメーヌ クラランスを作りました。`,
      `大気や土壌の汚染と無縁のクラランスの理念を体現する自然環境です。`,
      `自然に最大限の敬意を払いながら、自分たちの手で植物を育て、収穫しています。`,
      `原料の品質は製品の品質を決定します。`,
    ],
  },
];
