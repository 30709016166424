export default [
    {
      name: `actifs-excellence-absolue`,
      title: `Activos de excelencia absoluta`,
      texts: [
        `Este modo de cultivo garantiza la calidad única de nuestras plantas y su elevada concentración en principios activos. Cada extracto que se prepara se prueba para comprobar su rendimiento y tolerancia.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `Un año en la Hacienda Clarins`,
      texts: [
        `En la Hacienda Clarins siempre hay una planta que necesita nuestra atención.`,
        `En primavera se realiza la labranza: se eliminan las malas hierbas, se prepara la tierra y se planta de nuevo. Durante el verano y el otoño, las plantas se recolectan y se secan. También es la temporada de observación científica.`,
        `Cuando llega el invierno, los equipos se preparan para la próxima temporada y analizan las observaciones realizadas durante todo el año.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `De la recolección al secado`,
      texts: [
        `Las plantas se recolectan en el momento en que son más ricas en activos. A continuación, se cortan en pequeños trocitos. Extendidas en el secadero, se secan siguiendo métodos tradicionales al abrigo de los rayos del sol: una vez al día, se les da la vuelta con delicadeza hasta que se secan por completo. Cada año se recolectan más de 2,5 toneladas de plantas frescas en la Hacienda.`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `Cultivos 100% bio`,
      texts: [
        `En un suelo preservado de toda contaminación, las plantas crecen al ritmo de las estaciones y respetando la naturaleza, siguiendo los criterios de la agricultura bio. Naturalmente rica, la tierra es abonada únicamente por aportes de materias de origen vegetal y animal. Se trabaja al mínimo para respetar el equilibrio natural del suelo. No hay cabida para tratamientos fitosanitarios: las plantas se tratan con extractos vegetales. La Hacienda reúne todos los atributos de una producción responsable de calidad.`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `El comienzo de una larga aventura`,
      texts: [
        `Desde 2016, seis extractos bio 100% Clarins han enriquecido nuestro magnífico herbario: rosa de los Alpes, siempreviva, jabonera, melisa, Petasites y genciana amarilla. Puedes encontrarlas en nuestros productos desmaquillantes y en los productos ClarinsMen. Nuestro objetivo es doblar la cifra de aquí a 2025.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `Lugar de cultura y experimentación`,
      texts: [
        `En la Hacienda Clarins, cultivamos las plantas bio del presente y el futuro... Nuestros equipos de investigación analizan nuevas especies para descubrir sus propiedades cosméticas y estudiar sus interacciones con la piel.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `Un nuevo compromiso medioambiental`,
      texts: [
        `En 2021, los vínculos entre Clarins y los Alpes se reforzaron aún más con un nuevo y marcado compromiso con la biodiversidad: la firma de una obligación medioambiental real (ORE) con el Conservatoire des espaces naturels de Haute-Savoie Asters. Mediante este contrato, nos comprometemos a preservar la biodiversidad de la Hacienda durante 99 años, la duración máxima prevista por el mecanismo. Es la primera vez que una empresa privada se compromete de este modo con un espacio natural.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `Comprometidos con la protección de los Alpes`,
      texts: [
        `El amor de Clarins por los Alpes surgió hace tiempo Todo comenzó en 1993, cuando Christian Courtin empezó a colaborar con el príncipe Sadruddin Aga Khan y su fundación Alp Action.`,
        `Desde entonces, la marca contribuye constantemente a la biodiversidad del macizo: ha participado en 5 programas de preservación de especies amenazadas en colaboración con Alp Action, que posteriormente fue absorbida por Asters. Se trata de un compromiso muy importante para nosotros que mantenemos con el mismo fervor que en los inicios.`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `Un equipo entregado`,
      texts: [
        `LLa Hacienda Clarins es también un equipo apasionado con un profundo apego a la tierra y a su oficio. 5 expertos trabajan bajo la dirección de Aurore Metral y Véronique Mothès, ambas responsables de la explotación, y con el asesoramiento de Jean-Pierre Nicolas, etnobotánico.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `La genciana amarilla - Gentiana lutea`,
      texts: [
        `La genciana amarilla, reina de la fitoterapia, es conocida desde la antigüedad por sus numerosos efectos curativos. Esta planta robusta de espacios amplios florece en los pastos y es asombrosamente longeva: puede vivir medio siglo. En el transcurso de su larga vida, desarrolla sólidas raíces, depósitos de principios activos que ayudan a combatir los problemas digestivos. Puedes encontrar los efectos calmantes del extracto de hojas de genciana bio cultivada en la Hacienda Clarins en nuestras aguas desmaquillantes. `,
      ],
      cta: {
        title: `Descubre uno de nuestros productos`,
          href: `https://www.clarins.es/eau-micellaire-demaquillante/80062047.html`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `El Gypaetus barbatus`,
      texts: [
        `Si miras al cielo, quizás tengas la suerte de ver a este buitre alpino, reintroducido en Francia en 1986 a instancias de Asters y Clarins. Con una envergadura de unos 3 metros, es la mayor rapaz europea. Aunque la especie estaba en peligro de extinción, actualmente hay 70 ejemplares en los Alpes.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `La siempreviva de los tejados - Sempervivum tectorum`,
      texts: [
        `La siempreviva, originaria de Europa occidental, se enfrenta a temperaturas extremas, una sequía excesiva y terrenos áridos. A pesar de ello, sus hojas verdes y carnosas están llenas de agua. La planta se cultiva en la Hacienda, y su extracto bio ha sido seleccionado por nuestros Laboratorios para la gama ClarinsMen, que ayuda a la piel a combatir la deshidratación.`,
      ],
      cta: {
        title: `Descubre uno de nuestros productos`,
          href: `https://www.clarins.es/gel-energizante-para-ojos/80071985.html`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `Un laboratorio al aire libre`,
      texts: [
        `La Hacienda cuenta con una zona dedicada a la experimentación. Cada año se estudian unas 15 especies vegetales de montaña y de llanura para determinar dónde se concentra el mayor volumen de principios activos. Las muestras recogidas se envían a los Laboratorios de Pontoise para su análisis. Desde 2017, se han evaluado más de 1500 muestras para enriquecer las fórmulas de Clarins.`,
      ],
    },
    {
      name: `melisse`,
      title: `La melisa`,
      texts: [
        `Cultivada desde la antigüedad como planta medicinal, la melisa alivia una gran cantidad de males, sobre todo problemas digestivos y nerviosos. Favorece el sueño y combate el estrés. Algunos estudios confirman que la planta favorece la relajación y la disminución del estrés y la ansiedad. En cosmética, el extracto de melisa bio contribuye a calmar y suavizar la piel. Puedes encontrarla en todos nuestros limpiadores.`,
      ],
      cta: {
        title: `Descubre uno de nuestros productos`,
          href: `https://www.clarins.es/espuma-limpiadora-piel-nueva/80071909.html`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `Colaboradores locales de confianza`,
      texts: [
        `La lealtad es un valor muy apreciado por Clarins. Trabajamos con los mismos colaboradores locales desde hace años. Tanto los productores como los recolectores comparten nuestra filosofía y respetan las buenas prácticas aplicadas en la Hacienda Clarins: plantas de calidad, agricultura bio, métodos tradicionales... Cuentan con toda nuestra confianza, y estamos comprometidos con ellos a largo plazo.`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `Las plantas silvestres`,
      texts: [
        `El equipo de la Hacienda Clarins colabora con los propietarios de 14 terrenos vecinos certificados en agricultura bio. Se trata de terrenos silvestres en los que las plantas crecen de forma natural. En ellos recolectamos de forma responsable y sostenible las hojas de Petasite, genciana amarilla y rosa de los Alpes.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `Nuestro circuito de producción`,
      texts: [
        `Cultivar nuestras propias plantas nos permite controlar el impacto medioambiental sin comprometer la calidad. Tras la recolección, preparamos extractos que luego se formulan en nuestros Laboratorios. Realizamos un seguimiento completo de cada etapa, lo que garantiza la transparencia y la durabilidad.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `El respeto del suelo`,
      texts: [
        `La Hacienda Clarins nos ofrece una tierra de una riqueza excepcional: suelos que nunca han sido contaminados y están enriquecidos con insumos naturales. Seguimos los principios de la agricultura regenerativa para cuidar la tierra lo mejor posible. Este método de cultivo ético va más allá de las normas de la agricultura ecológica. Hacemos todo lo posible para garantizar la protección del suelo y de la biodiversidad.`,
      ],
      cta: {
        title: `Ver el vídeo`,
        enabled: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `La riqueza y la pureza de los Alpes`,
      texts: [
        `Situada en pleno corazón del macizo alpino, la Hacienda Clarins cuenta con todas las ventajas de las montañas: un aire puro, una tierra rica y preservada, un agua que llega directamente de su fuente. Condiciones óptimas para cultivar nuestras plantas y ofrecerte tratamientos de calidad excepcional.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `La jabonera - Saponaria officinalis`,
      texts: [
        `La jabonera es una planta perenne que crece en las riberas de los ríos, en las acequias y en los bosques, y que en verano produce fragantes flores blancas o rosadas. Antiguamente, se utilizaban sus hojas y rizomas para limpiar tejidos de lana y blanquear hilos y encajes. La jabonera posee propiedades limpiadoras y emulsionantes gracias a su elevado contenido en saponinas, tensioactivos naturales. La jabonera bio cultivada en la Hacienda permite a la Espuma Limpiadora Suave limpiar la piel con total delicadeza.`,
      ],
      cta: {
        title: `Descubre uno de nuestros productos`,
          href: `https://www.clarins.es/espuma-limpiadora-suave-hidratante/80071907.html`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `Una trazabilidad ejemplar`,
      texts: [
        `El logo de la Hacienda Clarins de nuestros productos garantiza un total control de la cadena de suministros, de la parcela de cultivo a nuestros puntos de venta.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `Fuentes de energía limpias`,
      texts: [
        `La naturaleza reina en la Hacienda. Las plantas crecen gracias a la energía complementaria de la naturaleza y el hombre, que no la contamina ni la daña. Las aguas pluviales recogidas en los tanques se utilizan para regar los cultivos. Para producir la electricidad del secadero y la casa, hemos instalado paneles solares.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `Un abastecimiento responsable`,
      texts: [
        `Creemos en una belleza responsable y sostenible. Velamos por la aplicación de buenas prácticas agrícolas y un abastecimiento local (Francia o Europa). Si los ingredientes llegan de más lejos, aplicamos primas de comercio justo. Garantizamos la total trazabilidad de las zonas de recolección y establecemos contratos a largo plazo con nuestros colaboradores.`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `Un velo protector`,
      texts: [
        `En la Hacienda, las malas hierbas se eliminan totalmente a mano. Para limitar su desarrollo, hemos elegido una protección natural. Los velos de lana o cáñamo protegen los cultivos de los rayos del sol, preservan la fertilidad del suelo y favorecen el crecimiento de las cosechas.`,
      ],
    },
  ];
