<template>
  <div
    class="splash-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    :style="`background-image: url('${backgroundImage}');`"
  >
    <div class="h-100 flex-col justify-center align-center">
      <!-- <div class="w-100">
        <div class="logo-wrapper">
          <img src="@/assets/logo.png">
        </div>
      </div> -->
      <div class="flex-col justify-center align-center w-100" v-show="!isLangSelectorMobileOpened">
        <div class="welcome">
          Welcome to the
        </div>
        <div class="welcome-subtitle">
          Domaine Clarins
        </div>
        <img class="logo" src="@/assets/logo.png">
      </div>
      <div
        class="flex-col w-100 pt-10 pb-50 px-30"
        :class="{
          'flex-grow': !$breakpoints.isMobile,
          'h-100': $breakpoints.isMobile,
        }"
      >
        <lang-selector-mobile
          @close="isLangSelectorMobileOpened = false"
          @choose-lang="chooseLang"
          v-show="isLangSelectorMobileOpened"
          v-if="$breakpoints.isMobile"
        />
        <lang-selector-desktop
          @close="isLangSelectorDesktopOpened = false"
          @choose-lang="chooseLang"
          v-show="isLangSelectorDesktopOpened"
          v-else
        />
        <div class="flex-grow flex-col justify-center align-center" v-show="!isLangSelectorMobileOpened && !isLangSelectorDesktopOpened">
          <div class="flex-grow"></div>
          <div class="actions-container">
            <a class="clarins-button secondary-button mb-20" @click="openLangSelector">
              <div class="flex-row justify-center align-center">
                <img
                  :src="flagImg"
                  class="lang-flag mr-10"
                  v-if="flagImg"
                >
                Choose language
              </div>
            </a>
            <a class="clarins-button" @click="start">
              Start the virtual tour
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LangSelectorMobile from '@/components/LangSelectorMobile';
import LangSelectorDesktop from '@/components/LangSelectorDesktop';

export default {
  components: {
    LangSelectorMobile,
    LangSelectorDesktop,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    splashscreenConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data: () => ({
    isLangSelectorMobileOpened: false,
    isLangSelectorDesktopOpened: false,
  }),

  computed: {
    backgroundImage() {
      return this.getConfigItem(`background_image`)?.image || ``;
    },
    flagImg() {
      if (this.lang) {
        return require(`@/assets/flags/${this.lang.key}.png`);
      }

      return null;
    },
  },

  methods: {
    getConfigItem(name) {
      return this.splashscreenConfig.find(item => item.name === name) || null;
    },
    openLangSelector() {
      this.isLangSelectorMobileOpened = this.$breakpoints.isMobile;
      this.isLangSelectorDesktopOpened = !this.isLangSelectorMobileOpened;
    },
    chooseLang(lang) {
      this.$emit(`choose-lang`, lang);
    },
    start() {
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.splash-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  & {
    color: #FFF;
  }

  &:not(.mobile) {
    .logo {
      width: 30%;
      max-width: 160px;
      margin-top: 20px;
    }

    .welcome {
      margin-top: 60px;
    }

    .welcome,
    .welcome-subtitle {
      padding: 40px 15px 10px;
      font-size: 28px;
      text-transform: uppercase;
    }

    .welcome-subtitle {
      padding-top: 0px;
      font-weight: bold;
    }

    .actions-container {
      width: 100%;
      max-width: 300px;
      margin-bottom: 50px;
    }
  }

  &.mobile {
    .logo {
      width: 30%;
      max-width: 120px;
      margin-top: 20px;
    }

    .welcome {
      margin-top: 60px;
    }

    .welcome,
    .welcome-subtitle {
      padding: 40px 15px 10px;
      font-size: 22px;
      text-transform: uppercase;
    }

    .welcome-subtitle {
      padding-top: 0px;
      font-weight: bold;
    }

    .actions-container {
      width: 100%;
    }
  }
}
</style>
