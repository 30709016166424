
export default [
  {
    name: `title`,
    title: `BIENVENIDO AL CORAZÓN DE LA HACIENDA CLARINS`,
  },
  {
    name: `instruction_1`,
    title: `Desliza el ratón para realizar un recorrido panorámico.`,
  },
  {
    name: `instruction_2`,
    title: `Haz clic en las flechas para cambiar de zona.`,
  },
  {
    name: `instruction_3`,
    title: `Haz clic en los puntos de interés para saber más.`,
  },
  {
    name: `instruction_4`,
    title: `Haz clic en el logotipo para volver a la página de inicio.`,
  },
  {
    name: `loading_label`,
    title: `Cargando`,
  },
  {
    name: `start_btn`,
    title: `COMENZAR EL TOUR`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Clarins ha creado su propia hacienda en el corazón de los Alpes.`,
      `Un lugar protegido y libre de la contaminación del aire y el suelo.`,
      `Cultivamos y recolectamos nuestras propias plantas respetando la naturaleza.`,
      `La calidad de nuestros ingredientes nos permite ofrecer excelentes productos en nuestros tratamientos.`,
    ],
  },
];
