export default [
    {
      name: `actifs-excellence-absolue`,
      title: `卓越的活性成分`,
      texts: [
        `这种栽培方式保证了植物的特有品质，保证植物富含高浓度的活性成分。植物在萃取后也将通过功效和肌肤耐受性的测试。`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `娇韵诗庄园的四季`,
      texts: [
        `在娇韵诗庄园，时刻都有植物需要我们的关爱！`,
        `在春季，我们需要除草、翻地和种植新植物。在夏季和秋季，`,
        `就是收成和烘干植物的时候，也是科学观察的季节。在冬季，团队则为即将到来的季节做好充分的准备，并分析全年的观察结果。`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `从采摘到烘干`,
      texts: [
        `这些植物在活性成分最丰富时被手工采摘，然后切成小片，铺在烘干机中以传统的方法烘干，同时避免阳光照射。它们每天都被小心地翻面一次，直至完全干燥。我们在娇韵诗庄园每年采收超过2.5吨的新鲜植物。`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `有机种植`,
      texts: [
        `在没有污染的土壤中，植物以顺应季节的节奏和尊重大自然的方式生长，并遵循严格的有机农业标准。这里的土壤天然肥沃，所以只需通过添加植物与动物的物质来改良，以维持土壤的天然平衡。娇韵诗庄园并不实施植物的检疫处理，只利用植物本身的萃取进行调整。因此，庄园的种植条件不仅遵循自然规律，更符合高品质生产的所有标准。`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `漫长历程的序幕`,
      texts: [
        `自2016年以来，娇韵诗的美丽植物之家加入了6种萃取的植物，包括阿尔卑斯玫瑰、石莲花、肥皂草、柠檬香脂和黄龙胆。您可以在我们的植萃清洁系列和男士产品中找到这些成分。我们的目标是在2025年将这一数字增加一倍。`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `文化与科研融汇之地`,
      texts: [
        `我们在娇韵诗庄园种植的植物，不仅为了今天，也惠及未来…… 我们的研发团队探索新品种的美妍成分，并研究其对肌肤的功效。`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `对环境保护的新承诺`,
      texts: [
        `2021年，娇韵诗与阿尔卑斯山之间进一步加强联系，肩负一项生物多样性的重大新承诺：与上萨瓦省自然空间学院的上萨瓦省自然保护区（Asters）签署了Real Environmental Obligation协定（REO）。在此协定中，娇韵诗承诺保护庄园的生物多样性99年，达至该协定订立的最长期限。这是首次有私人公司对自然区域保育作出此项承诺。`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `致力于守护阿尔卑斯山`,
      texts: [
        `娇韵诗一直以来都热爱阿尔卑斯山！一切始于1993年，Christian Courtin先生在当时偶然地认识了阿迦汗王子和他的阿尔卑斯行动基金会。`,
        `自此，娇韵诗一直提供支援，致力于保护阿尔卑斯山的生物多样性：与阿尔卑斯行动基金会携手合作，为保育5种濒危品种的计划作出贡献，阿尔卑斯行动基金会后来由上萨瓦省自然保护区（Asters）接手管理。而我们守护阿尔卑斯山的承诺至今不变，今天也一如既往，以同样的热忱继续给予支持。`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `充满热忱的团队`,
      texts: [
        `娇韵诗庄园拥有充满热忱的团队，他们热爱自然，热爱土地，热爱他们的专业。运营负责人Aurore Metral小姐和Véronique Mothès小姐带领多达15名专家在此工作，并同时也获得来自民族植物学家Jean-Pierre Nicolas先生的专业建议。`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `黄龙胆 - Gentiana lutea`,
      texts: [
        `黄龙胆是大自然的馈赠，自古以来就以其众多的功效而闻名。黄龙胆是一种天生健壮的户外植物，能在牧场上茁壮成长，并且拥有长达半个世纪的惊人寿命`,
        `在这漫长的生命中，黄龙胆会形成强壮的根茎和活性成分储藏库。您可以在娇韵诗的植萃清洁系列中，体验由娇韵诗庄园种植的黄龙胆叶萃取所带来的的柔和肌肤功效。`,
      ],
      cta: {
        title: `探索我们的美妍产品`,
        href: `https://www.clarins.com.cn/face/leisurely-remover`,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `胡兀鹫`,
      texts: [
        `仰望天空，您可能还有机会看到在阿尔卑斯山的胡兀鹫，该物种在上萨瓦省自然保护区和娇韵诗的推动下于1986年重新引入法国。胡兀鹫，翼展约3米，是欧洲身型最大的鸟类捕食者。尽管该品种濒临灭绝，但现在仍有70只生活在阿尔卑斯山区域。`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `石莲花 - Sempervivum tectorum`,
      texts: [
        `石莲花原产于西欧，能应对极端的温度，极度耐旱。尽管如此，石莲花仍能长出水润的绿色肉质叶子。庄园中也种植了石莲花，其萃取物通过我们实验室的挑选，用于男士护肤系列，有助肌肤保持润泽。`,
      ],
      cta: {
        title: `探索我们的美妍产品`,
         href: ` https://www.clarins.com.cn/men-products/`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `户外实验室`,
      texts: [
        `庄园是一片专门进行科研实验之地。目前，我们在这里研究了大约20多个来自山地和平原的新品种，以找出活性成分最高浓度的部分。收集到的植萃样品会送至我们位于法国蓬图瓦兹的实验室进行研究分析。自2017年以来，实验室已研究超过900个样品，不断地丰富娇韵诗的产品配方。`,
      ],
    },
    {
      name: `melisse`,
      title: `香蜂花`,
      texts: [
        `在护肤品中，香蜂花萃取有助于柔和肌肤。我们在植萃清洁系列中，皆添加香蜂花。`,
      ],
      cta: {
        title: `探索我们的美妍产品`,
          href: `https://www.clarins.com.cn/hot/cleaning/`,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `值得信赖的当地合作伙伴`,
      texts: [
        `忠诚，是我们最珍视的价值。多年来，我们一直和同一群当地的合作伙伴携手。他们均认同我们的理念，并尊重娇韵诗庄园所遵循的良好作业模式，包括优质植物、有机农业、传统方法等。我们之间互相信赖，我们亦承诺与他们长期合作。`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `野生植物`,
      texts: [
        `娇韵诗庄园与邻近的14个获得有机认证的土地方合作。在这些“原野”上，植物能自然生长。我们以可持续和负责任的方式采摘蜂斗菜、黄龙胆和阿尔卑斯玫瑰叶。`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `我们的本地生产网络`,
      texts: [
        `在庄园种植我们自己的植物，使我们能够在不影响品质的前提下把控对环境的影响。植物一旦收成，即被萃取为有效成分，准备为实验室配制所用。每一步均可追溯，保证流程的透明度和可持续发展。`,
      ],
    },
    {
      name: `respect-sols`,
      title: `尊重土壤`,
      texts: [
        `娇韵诗庄园为我们提供格外肥沃的土壤。为了更好地保护土壤，我们偏向采用传统种植方法而非重型农业机器。使用马匹温和地开垦土地以保护生活于土壤中的各种生物——特别是蚯蚓和微生物，来维持土壤的肥沃。`,
      ],
      cta: {
        title: `观看影片`,
        enabled: true,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `富饶纯净的阿尔卑斯山`,
      texts: [
        `娇韵诗庄园位于阿尔卑斯山的中心地带，被山脉所环绕。这里有能畅怀呼吸的纯净空气、悉心保护的肥沃土壤，以及来自源头的纯净水源。这一切均为娇韵诗庄园创造了理想的种植条件，并以此为您打造优质的护肤产品。`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `肥皂草 - Saponaria officinalis`,
      texts: [
        `肥皂草是一种多年生植物，在河岸、水渠和树林都有它的踪影，在夏季还会盛开芳香的白色或粉红色花朵。我们的祖先利用肥皂草的叶子和根茎洗涤羊毛织物，漂白缝隙和花边。由于肥皂草富含皂素与天然表面活性剂，因而具有清洁和乳化的效果。肥皂草生长在娇韵诗庄园，为我们的植萃清洁系列带来柔和的清洁功效。 `,
      ],
      cta: {
        title: `探索我们的美妍产品`,
        href: `https://www.clarins.com.cn/face/gentle-foaming-cleanser-normal-combination-125ml`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `优秀的可追溯性`,
      texts: [
        `娇韵诗产品包装上的「Domaine Clarins」标志，保证了我们供应链的全程可追溯，从种植地点到呈现在您眼前。`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `洁净能源`,
      texts: [
        `在庄园里，一切以大自然为首位。这里的植物能够茁壮成长，全有赖于人类和大自然的共同努力，让植物免受污染或侵害。雨水用储水桶收集起来，用于浇灌；太阳能电池板则为烘干机和小木屋提供能源。`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `负责任的采购过程`,
      texts: [
        `我们相信负责任和可持续的美丽之道。我们会尽量选择良好的农业实践模式，并在当地进行采购（法国及欧洲）。当我们的原材料来自更远的地方时，我们会缴付公平交易补助款。我们确保采收区域拥有完全可溯源的系统，并与我们的合作伙伴签订长期合约。`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `天然保护`,
      texts: [
        `在庄园中，除草工作完全由人工完成。为了控制杂草的生长，我们选择采用天然的保护方式，利用羊毛或保护作物免受阳光直射，从而保持土壤肥沃，促进作物生长。`,
      ],
    },
  ];
