
export default [
  {
    name: `home`,
    title: `Inicio`,
  },
  {
    name: `map`,
    title: `Vista de arriba`,
  },
  {
    name: `sound-on`,
    title: `Silenciar`,
  },
  {
    name: `sound-off`,
    title: `Activar sonido`,
  },
  {
    name: `full-screen`,
    title: `Pantalla completa`,
  },
  {
    name: `gyroscope-on`,
    title: `Gyro apagado`,
  },
  {
    name: `gyroscope-off`,
    title: `Gyro encendido`,
  },
];
