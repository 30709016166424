
export default [
  {
    name: `home`,
    title: `Home`,
  },
  {
    name: `map`,
    title: `Ansicht von oben`,
  },
  {
    name: `sound-on`,
    title: `Ton aus`,
  },
  {
    name: `sound-off`,
    title: `Ton an`,
  },
  {
    name: `full-screen`,
    title: `Ganzer Bildschirm `,
  },
  {
    name: `gyroscope-on`,
    title: `Kreisel aus`,
  },
  {
    name: `gyroscope-off`,
    title: `Kreisel an`,
  },
];
