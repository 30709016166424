
export default [
  {
    name: `home`,
    title: `主頁`,
  },
  {
    name: `map`,
    title: `從上方觀看`,
  },
  {
    name: `sound-on`,
    title: `靜音`,
  },
  {
    name: `sound-off`,
    title: `取消靜音`,
  },
  {
    name: `full-screen`,
    title: `全螢幕`,
  },
  {
    name: `gyroscope-on`,
    title: `陀螺关闭`,
  },
  {
    name: `gyroscope-off`,
    title: `陀螺仪开启`,
  },
];
