
export default [
  {
    name: `home`,
    image: require(`@/assets/menu/home.png`),
    imageHover: require(`@/assets/menu/home-hover.png`),
  },
  {
    name: `map`,
    image: require(`@/assets/menu/map.png`),
    imageHover: require(`@/assets/menu/map-hover.png`),
  },
  {
    name: `sound-on`,
    image: require(`@/assets/menu/sound-on.png`),
    imageHover: require(`@/assets/menu/sound-on-hover.png`),
  },
  {
    name: `sound-off`,
    image: require(`@/assets/menu/sound-off.png`),
    imageHover: require(`@/assets/menu/sound-off-hover.png`),
  },
  {
    name: `full-screen`,
    image: require(`@/assets/menu/full_screen.png`),
    imageHover: require(`@/assets/menu/full_screen-hover.png`),
  },
  {
    name: `gyroscope-on`,
    enabled: false,
  },
  {
    name: `gyroscope-off`,
    enabled: false,
  },
  {
    name: `close`,
    image: require(`@/assets/menu/close.png`),
    // imageHover: require(`@/assets/menu/close-hover.png`),
  },
];
