export default [
    {
      name: `actifs-excellence-absolue`,
      title: `優れた有効成分を配合`,
      texts: [
        `このような栽培方法で得られた植物は、有用成分を高濃度に含有することが確認されています。調製された各エキスは、効果や安全性についてテストされます。`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `ドメーヌ クラランスの1年`,
      texts: [
        `ドメーヌ クラランスには、いつも私たちの注意を必要とする植物があります。`,
        `春には、草取り、土壌の準備、新芽の育成などの作業が行われます。夏から秋にかけては、植物を収穫し、乾燥させる時期です。また、科学的な観察を行う季節でもあります。冬になると、各チームは次のシーズンに向けて1年間の観察結果を分析します。`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `収穫から乾燥まで`,
      texts: [
        `有用成分が最も豊富な時期に植物を手摘みしています。その後、細かくカットされます。1日に1回、完全に乾くまで丁寧に裏返し、太陽の光から保護しながら伝統的な方法で乾燥させます。ドメーヌ クラランスでは、毎年2.5トン以上の新鮮な植物が収穫されます。`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `100％オーガニック栽培の農作物`,
      texts: [
        `汚染のない土壌で、季節に合わせ、自然を尊重し、オーガニック農業の厳しい基準に従って植物を育てています。自然豊かな土壌は、植物や動物が加わるだけで変化します。土壌の自然なバランスを尊重するため、最小限の作業しか行いません。また、植物検疫は行わず、植物から抽出したエキスで処理されています。このようにして、高品質で安定した生産から得られるすべての恩恵が、ドメーヌ クラランスに集約されるのです。`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `長い冒険のはじまり`,
      texts: [
        `2016年より、6種類のクラランス100％オーガニックエキスがハーバリウムを豊かにしています。アルペンローズ、ハウスリーク、サボンソウ、レモンバーム、ゲンチアナです。これらは、クレンジングやクラランスメン製品に配合されています。私たちの目標は、2025年までにこの数を倍増させることです。`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `文化・実験の場`,
      texts: [
        `"ドメーヌ クラランスでは、オーガニック植物を育てています。私たちの研究チームは、常に新しいスキンケア製品の特性を分析し、肌への影響を研究しています"`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `新たな環境への取り組み `,
      texts: [
        `2021年、クラランスとアルプスのつながりは、生物多様性への新たな取り組みとして、アスターズ（オート・サヴォワ自然環境保護区）とのREO（Real Environmental Obligation）の締結によってさらに強固になりました。この契約により、私たちはドメーヌ クラランスの生物多様性をREOで定められている最長期間である99年間保全することを約束します。民間企業がこのような自然保護に取り組むのは初めてのことです。`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `アルプスを守るための取り組み`,
      texts: [
        `クラランスのアルプスへの思いは、今に始まったことではありません。1993年、サドルディン・アガ・カーン皇太子と彼の率いるアルプ・アクション財団との出会いが、クラランスのすべての始まりでした。`,
        `それ以来、クラランスはアルプスの生物多様性を継続的に支援しています。アルプ・アクション（後にアスターズに引き継がれる）と連携して、絶滅危惧種を保護する5つのプログラムに貢献しています。この取り組みは、私たちの心に深く刻まれ、今も変わらぬ情熱をもって支援し続けています。`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `クラランスで働くエキスパートたち`,
      texts: [
        `ドメーヌ クラランスには、土地と自分の職業に深いつながりを持つ情熱的な人々が集まっています。運営を担当するオーロール・メトラールとヴェロニク・モテスの指導のもと、民族植物学者ジャン＝ピエール・ニコラのアドバイスを受けて、最大15人のエキスパートが働いています。`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      enabled:false,
    },
    {
      name: `gypaete-barbu`,
      title: `ヒゲハゲタカ`,
      texts: [
        `1986年、アスターズとクラランスの呼びかけでフランスに再導入されたアルプスハゲタカに出会えるかもしれません。ヨーロッパ最大の猛禽類で、翼を広げると約3メートル。絶滅の危機に瀕していたが、現在ではアルプス山脈に70羽が生息しています。`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      enabled:false,
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `オープンエアーの研究室`,
      texts: [
        `ドメーヌ クラランスには、実験に特化したエリアがあります。そこでは、山や平野で採れる約20種類の新種が研究され、有用成分が最も濃縮された部分を特定しています。採取したサンプルはポントワーズにあるラボラトリーに送られ、分析されます。2017年以降、900以上のサンプルが分析され、クラランスの処方をより豊かなものにしています。`,
      ],
    },
    {
      name: `melisse`,
      enabled:false,
    },
    {
      name: `partenaires-locaux`,
      title: `信頼できる現地のパートナー`,
      texts: [
        `私たちが大切にしている価値観、「忠誠心」。私たちは長年にわたり、同じ現地のパートナーと仕事をしてきました。彼らは皆、私たちの哲学を共有し、ドメーヌ クラランスで守られている良い習慣、すなわち品質の良い植物、有機農業、伝統的な手法などを尊重しています。彼らは私たちの全幅の信頼を寄せており、私たちは長期的に彼らと付き合っていくことを約束しています。`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `野生の植物`,
      texts: [
        `ドメーヌ クラランスのチームは、オーガニック認証を受けた近隣の14の敷地のオーナーと協力しています。これらの土地は、植物が自然に生育する「未開拓地」と呼ばれる場所です。セイヨウフキ、ゲンチアナ、アルペンローズの葉を、持続可能で責任ある方法で収穫しています。`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `現地での生産ネットワーク`,
      texts: [
        `自社で植物を栽培することで、品質に妥協することなく、環境への影響をコントロールすることができます。収穫後、私たちの研究所で配合するためのエキスを準備します。各工程は100％追跡可能で、透明性と持続可能性を保証しています。`,
      ],
    },
    {
      name: `respect-sols`,
      title: `土壌を大切にすること`,
      texts: [
        `ドメーヌ クラランスは、非常に豊かな土壌を提供してくれます。その土壌を大切にするために、私たちは 機械の代わりに馬が土地を耕す伝統的な方法を選んでいます。`,
      ],
      cta: {
        title: `ビデオを見る`,
        enabled: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `アルプスの恵みと清らかさ`,
      texts: [
        `アルプスの中心に位置するドメーヌ クラランスは、山の恩恵をすべて享受しています。澄んだ空気、豊かな土壌、源泉から直接採水した水。私たち自身自然を大切にしながら植物を育て、高品質の製品を提供するための最適な条件なのです。`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      enabled: false,
    },
    {
      name: `tracabilite-exemplaire`,
      title: `卓越したトレーサビリティ`,
      texts: [
        `ドメーヌ クラランスのロゴは、畑から販売店までのサプライチェーンの完全管理を保証しています。`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `クリーンなエネルギー源`,
      texts: [
        `ドメーヌ クラランスでは、自然がなによりも大切です。人と自然のエネルギーを結集し、自然を汚さず、傷つけず、植物を育てます。雨水は雨水桶に集められ、作物の水やりに利用されています。ソーラーパネルを設置し、乾燥機とシャレーの電気をまかなっています`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `責任ある原料調達の実現`,
      texts: [
        `私たちは、責任ある、持続可能な美を追求します。そして、よい農法とと地元（フランスまたはヨーロッパ）での調達を優先しています。遠方から原料を調達する場合は、組合や地域の経済的・社会的・環境的開発のために使われる資金である、フェアトレード・プレミアムという制度を導入しています。収穫地の完全なトレーサビリティを確保し、パートナーとの長期契約を締結しています。`,
          `*トレーサビリティとは、｢その製品がいつ、どこで、だれによって作られたのか｣を明らかにすべく、原材料の調達から生産、そして消費または廃棄まで追跡可能な状態にすることです。`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `保護ベール`,
      texts: [
        `ドメーヌ クラランスでは、除草はすべて手作業で行っています。雑草の発生を抑えるために、私たちは自然の保護手段を選びました。ウールや麻のキャノピーは、太陽の光から作物を守り、土壌の肥沃さを保ち、作物の成長を促します。`,
      ],
    },
  ];
