
export default [
  {
    key: `en_US`,
    label: `English (US)`,
  },
  {
    key: `en_UK`,
    label: `English (UK)`,
    isDefault: true,
  },
  {
    key: `en_AU`,
    label: `English (AU/NZ)`,
    isInSelector: false,
  },
  {
    key: `fr_FR`,
    label: `Français`,
  },
  {
    key: `es_ES`,
    label: `Español`,
  },
  {
    key: `fr_CA`,
    label: `Français (CA)`,
  },
  {
    key: `en_CA`,
    label: `English (CA)`,
  },
  {
    key: `jp_JP`,
    label: `日本`,
    isInSelector: false,
  },
  {
    key: `ch_CH`,
    label: `中文(繁體)`,
    isInSelector: false,
  },
  {
    key: `ch_TW`,
    label: `台灣`,
    isInSelector: false,
  },
  {
    key: `de_DE`,
    label: `German`,
  },
   {
    key: `en_MY`,
    label: `Malaysia`,
    isInSelector: false,
  },
    {
    key: `en_SG`,
    label: `Singapore`,
    isInSelector: false,
  },
  {
    key: `ch_CHS`,
    label: `中国人`,
    isInSelector: false,
  },
  {
    key: `kr_KR`,
    label: `대한민국`,
  },
  {
    key: `ru_RU`,
    label: `Русский`,
  },
]
