export default [
  {
    name: `actifs-excellence-absolue`,
    title: `卓越的活性成分`,
    texts: [
      `這種栽培方法保證植物的獨有品質，確保植物蘊含高濃度的活性成分。萃取後將受測試功效和肌膚耐受性。`,
    ],
  },
  {
    name: `annee-domaine-clarins`,
    title: `克蘭詩純淨之巔的四季`,
    texts: [
      `在克蘭詩純淨之巔，時時刻刻都有植物需要我們關注！`,
      `在春天即是工作的季節，我們需要除草、整地和種植新植物。在夏季和秋季，就是收成和烘乾植物的時候，`,
      `亦是科學觀察的季節。當冬天來臨時，團隊為即將到來的季節做準備並分析全年的觀察結果。`,
    ],
  },
  {
    name: `beaute-au-sommet`,
    title: `La beauté au sommet`,
    texts: [
      `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
      `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
    ],
  },
  {
    name: `ceuillette-au-sechage`,
    title: `從採收到烘乾`,
    texts: [
      `這些植物在活性成分最豐富時以手工採摘，然後切成小片，鋪在烘乾機中，依照傳統方法烘乾，避免陽光照射，並將植物每天小心翻面一次，直至完全乾燥。克蘭詩純淨之巔每年採收超過2.5噸新鮮植物。`,
    ],
  },
  {
    name: `cultures-100-bio`,
    title: `100%有機種植`,
    texts: [
      `在沒有汙染的土壤中，植物順應季節的節奏和尊重大自然的方式生長，並遵循嚴格的有機農業標準。由於土壤天然肥沃，所以只需透過添加植物與動物的物質作微調。我們把土壤處理工作減至最少，以維持土壤的天然平衡。純淨之巔並不實施植物檢疫處理，植物均以萃取作處理。因此，純淨之巔匯聚合乎倫理道德以及高品質生產的所有好處。 `,
    ],
  },
  {
    name: `debut-longue-aventure`,
    title: `漫長歷程的序幕`,
    texts: [
      `自2016年以來，6種克蘭詩100%有機萃取加入了我們的草本植物園，包括阿爾卑斯玫瑰、石蓮花、肥皂草、檸檬香脂和黃龍膽。您可以在我們的潔顏乳和男性保養品中找到有關成分。我們的目標是到2025年時，把這個數字雙倍提升。`,
    ],
  },
  {
    name: `dedie-innovation`,
    title: `融匯文化與科研之地`,
    texts: [
      `在克蘭詩純淨之巔，我們為今天和未來種植有機植物…… 我們的研究團隊分析新品種的美容特性，並研究其對肌膚的效用。`,
    ],
  },
  {
    name: `engagement-preservation-alpes`,
    title: `對環境的新承諾 `,
    texts: [
      `2021年，克蘭詩與阿爾卑斯山之間進一步加強連繫，肩負一項生物多樣性的重大新承諾：與上薩瓦省自然空間學院的上薩瓦省自然保護區（Asters）簽署了Real Environmental Obligation協定（REO）。透過這份合約，我們承諾保護純淨之巔的生物多樣性99年，達至該協定訂立的最長期限。這是首次有私人公司對自然區域做出此項承諾。`,
    ],
  },
  {
    name: `engages-sauvegarde-alpes`,
    title: `致力拯救阿爾卑斯山`,
    texts: [
      `克蘭詩一直以來都熱愛阿爾卑斯山！一切始於1993年，當時Christian Courtin先生偶然認識了阿迦汗王子和他的阿爾卑斯行動基金會。`,
      `從那時起，克蘭詩一直提供支援，保護為阿爾卑斯山的生物多樣性：與阿爾卑斯行動攜手合作，為保育5個瀕危品種的計畫作出貢獻，阿爾卑斯行動後來由上薩瓦省自然保護區（Asters）接手管理。此承諾秉承我們的核心價值，而我們今天一如既往，以同樣的熱忱繼續予以支持。`,
    ],
  },
  {
    name: `equipe-petits-soins`,
    title: `熱誠關注土地的團隊`,
    texts: [
      `克蘭詩純淨之巔亦擁有充滿熱誠的團隊，他們熱愛地球的土地、熱愛他們的專業。多達15名專家在營運負責人Aurore Metral小姐和Véronique Mothès小姐的帶領下工作，她們亦獲得民族植物學家Jean-Pierre Nicolas先生的專業建議。`,
    ],
  },
  {
    name: `gentiane-jaune-gentiana-lutea`,
    title: `黃金龍膽草 - Gentiana lutea`,
    texts: [
      ` 身為植物療法的療效之后，黃金龍膽草自古以來就以其眾多的療效而聞名，是一種健壯的戶外植物，能在牧場上茁壯成長，並且壽命驚人，可長達半個世紀。在這漫長的生命中，黃金龍膽草會形成強壯的根莖和活性成分貯藏庫，有助對抗消化系統疾病。在克蘭詩的潔顏保養品中，您可體驗到由克蘭詩純淨之巔種植的有機龍膽葉萃取所帶來的舒緩功效。`,
    ],
    cta: {
      title: `探索我們的保養品`,
      href: `https://www.clarins.com.tw/newcleanser/%E9%98%BF%E7%88%BE%E5%8D%91%E6%96%AF%E7%B4%94%E6%B7%A8%E5%8D%B8%E5%A6%9D%E6%B0%B4-80062047.html`,
    },
  },
  {
    name: `gypaete-barbu`,
    title: `有鬚禿鷹`,
    texts: [
      `仰望天空，您可能有機會看到高山老鷹，在上薩瓦省自然保護區和克蘭詩的推動下於1986年重新引入法國。翼展約3米，是歐洲身型最大的鳥類捕獵者。儘管該品種面臨滅絕的威脅，但現在於阿爾卑斯山仍有70隻。`,
    ],
  },
  {
    name: `joubarbe-des-toits`,
    title: `石蓮花 - Sempervivum tectorum`,
    texts: [
      `石蓮花原產於西歐，生長時面對極端溫度、嚴重乾旱和乾旱土壤。儘管如此，石蓮花仍能長出充滿水分的綠色肉質葉子。純淨之巔中種植了石蓮花，其有機萃取獲我們的實驗室挑選並用於男士保養系列，可幫助肌膚保持潤澤。`,
    ],
    cta: {
      title: `探索我們的保養品`,
      href: `https://www.clarins.com.tw/%E8%87%89%E9%83%A8%E8%88%87%E8%BA%AB%E9%AB%94%E4%BF%9D%E9%A4%8A/%E7%94%B7%E5%A3%AB%E4%BF%9D%E9%A4%8A-500/`,
    },
  },
  {
    name: `laboratoire-ciel-ouvert`,
    title: `戶外實驗室`,
    texts: [
      `純淨之巔是一片專門進行科研實驗的地方。當地研究大約20個來自山區和平原的新品種，以找出活性成分最高濃度的部分。收集到的樣品會送至我們位於法國蓬圖瓦茲的實驗室進行分析。自2017年以來，實驗室已分析900多個樣品，令克蘭詩的配方更豐富。`,
    ],
  },
  {
    name: `melisse`,
    title: `檸檬香蜂草 - Lemon balm`,
    texts: [
      `檸檬香蜂草自古以來就作為藥用植物栽培，可以緩解許多疾病，尤其是消化系統和神經系統疾病。檸檬香蜂草亦可減輕壓力以促進睡眠。研究證實，這種植物有助放鬆，以及減輕壓力和焦慮。在化妝品中，有機檸檬香蜂草萃取有助鎮靜、舒緩和軟化肌膚。我們所有潔顏產品皆含有檸檬香蜂草。`,
    ],
    cta: {
      title: `探索我們的保養品`,
      href: `https://www.clarins.com.tw/%E8%87%89%E9%83%A8%E8%88%87%E8%BA%AB%E9%AB%94%E4%BF%9D%E9%A4%8A/%E8%87%89%E9%83%A8%E4%BF%9D%E9%A4%8A/%E6%B8%85%E6%BD%94%E7%B3%BB%E5%88%97%E5%8F%8A%E5%8C%96%E5%A6%9D%E6%B0%B4/%E6%BD%94%E9%A1%8F-214/new-exfoliating-cream-series/`,
    },
  },
  {
    name: `partenaires-locaux`,
    title: `值得信賴的當地合作夥伴`,
    texts: [
      `忠誠，是我們珍視的價值。多年來，我們一直與相同的當地合作夥伴合作。他們都認同我們的理念，並尊重克蘭詩純淨之巔遵循的良好實踐模式，包括優質植物、有機農業、傳統方法等。他們對我們充滿信心，我們亦承諾與他們長期合作。`,
    ],
  },
  {
    name: `plantes-sauvages`,
    title: `野生植物`,
    texts: [
      `克蘭詩純淨之巔團隊與14個獲有機認證的鄰近場地之擁有者合作。這些地點稱為「荒野」場地，讓植物自然生長。我們以永續和負責任的方式採收蜂斗菜、黃金龍膽草和阿爾卑斯玫瑰葉。`,
    ],
  },
  {
    name: `production-circuit-court`,
    title: `我們的本地生產網絡`,
    texts: [
      `種植自家的植物使我們能夠在不影響品質的情況下控制對環境的影響。收成後，我們的實驗室就會準備為萃取進行配製。每一步都是100%可追溯，保證透明度和永續發展。`,
    ],
  },
  {
    name: `respect-sols`,
    title: `尊重土壤`,
    texts: [
      `克蘭詩純淨之巔為我們提供格外肥沃的土壤。為了好好保護土壤，我們偏向採用傳統種植方法而非重型農業機械。使用馬匹作溫和開墾可以保護土壤的生命，特別是蚯蚓和微生物，以保證土壤肥沃。`,
    ],
    cta: {
      title: `觀看影片`,
    },
  },
  {
    name: `richesse-purete-alpes`,
    title: `富饒純淨的阿爾卑斯山`,
    texts: [
      `克蘭詩純淨之巔位於阿爾卑斯山的中心地帶，享有山脈的所有優點，包括純淨的空氣（盡情深呼吸感受！）、悉心保育的肥沃土壤以及直接取自源頭的清水。純淨之巔擁有最佳的條件種植我們的植物，並為您提供優質保養品。`,
    ],
  },
  {
    name: `saponaire-saponaria-officinalis`,
    title: `肥皂草 - Saponaria officinalis`,
    texts: [
      `肥皂草是一種多年生植物，可在河岸、水道和樹林中找到，夏季會開出芳香的白色或粉紅色花朵。我們的祖先會用肥皂草的葉子和根莖來洗滌羊毛織物，漂白縫線和花邊。由於肥皂草含豐富皂素與天然界面活性劑，因而具有清潔和乳化的特性。有機肥皂草生長在純淨之巔，為我們的阿爾卑斯純淨潔顏系列帶來溫和的潔膚功效。
      `,
    ],
    cta: {
      title: `探索我們的保養品`,
      href: `https://www.clarins.com.tw/newcleanser/%E9%98%BF%E7%88%BE%E5%8D%91%E6%96%AF%E7%B4%94%E6%B7%A8%E6%BD%94%E9%A1%8F%E4%B9%B3_%E4%BF%9D%E6%BF%95-80071907.html`,
    },
  },
  {
    name: `tracabilite-exemplaire`,
    title: `卓越的可追溯系統`,
    texts: [
      `我們保養品上的「Domaine Clarins」標誌保證我們全面掌握從種植地點到銷售點的所有供應鏈。`,
    ],
  },
  {
    name: `sources-energie-propres`,
    title: `潔淨能源`,
    texts: [
      `在純淨之巔，一切以大自然為優先。植物能夠茁壯生長，有賴人類和大自然的共同努力，讓植物免受汙染或侵害。雨水收集在雨水桶中，用來澆灌莊稼。純淨之巔亦設置太陽能電池板，為烘乾機和小木屋發電。`,
    ],
  },
  {
    name: `sourcing-responsable`,
    title: `負責任採購過程`,
    texts: [
      `我們相信負責任和永續的美麗之道。我們會盡量選擇良好的農業實踐模式和於當地進行採購（法國或歐洲）。當我們的原材料來自更遠的地方時，我們會繳付公平交易補助款。我們確保採收區域擁有完全可追溯系列，並與我們的合作夥伴簽訂長期合約。`,
    ],
  },
  {
    name: `video-cheval`,
  },
  {
    name: `voilages-protecteurs`,
    title: `天然保護`,
    texts: [
      `在純淨之巔中，除草工作完全由人手完成。為了控制雜草的生長，我們選擇採用天然的保護方式。羊毛或大麻樹冠可保護作物免受陽光照射，保持土壤肥沃，促進作物生長。`,
    ],
  },
];
