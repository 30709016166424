export default [
  {
    name: `entree-domaine`,
    title: `Eingang Domaine`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `Enzian & Saponaria`,
  },
  {
    name: `devant-chalet`,
    title: `Die Hütte`,
  },
  {
    name: `chalet`,
    title: `Die Hütte`,
  },
  {
    name: `haut-domaine`,
    title: `Spitze der Domaine`,
  },
  {
    name: `jardin-collection`,
    title: `Der Garten`,
  },
  {
    name: `parcelle-cheval`,
    title: `Der untere Teil der Domaine`,
  },
  {
    name: `plantations`,
    title: `Das Pflanzenfeld`,
  },
  {
    name: `partenaires`,
    title: `Unsere Partner`,
  },
  {
    name: `drone-domaine`,
    title: `Flug über die Domaine`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `Entdecken Sie das Tal`,
    isInMap: false
  },
];
