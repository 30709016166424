<template>
  <div
    class="clarins-menu"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
    v-if="menuConfig.length"
  >
    <div class="items" v-if="$breakpoints.isMobile">
      <div class="menu-img">
        <img
          src="@/assets/menu/burger.png"
          @click="openMenu"
          v-show="!isMenuOpened"
        />
      </div>
    </div>
    <div class="items" v-show="isMenuOpened || !$breakpoints.isMobile">
      <div class="menu-img" :class="{ 'has-image-hover': homeImgHover !== null }">
        <img
          :src="homeImg"
          class="image-base"
          @click="goHome"
        />
        <img
          :src="homeImgHover"
          class="image-hover"
          @click="goHome"
          v-if="!$breakpoints.isMobile && homeImgHover"
        />
        <div class="legend" v-if="homeLegend" v-html="homeLegend"/>
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': mapImgHover !== null }">
        <img
          :src="mapImg"
          class="image-base"
          @click="openMap"
        />
        <img
          :src="mapImgHover"
          class="image-hover"
          @click="openMap"
          v-if="!$breakpoints.isMobile && mapImgHover"
        />
        <div class="legend" v-if="mapLegend" v-html="mapLegend" />
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': soundOnImgHover !== null }" v-show="!muted">
        <img
          :src="soundOnImg"
          class="image-base"
          @click="$emit(`mute`)"
        />
        <img
          :src="soundOnImgHover"
          class="image-hover"
          @click="$emit(`mute`)"
          v-if="!$breakpoints.isMobile && soundOnImgHover"
        />
        <div class="legend" v-if="soundOnLegend" v-html="soundOnLegend" />
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': soundOffImgHover !== null }" v-show="muted">
        <img
          :src="soundOffImg"
          class="image-base"
          @click="$emit(`unmute`)"
        />
        <img
          :src="soundOffImgHover"
          class="image-hover"
          @click="$emit(`unmute`)"
          v-if="!$breakpoints.isMobile && soundOffImgHover"
        />
        <div class="legend" v-if="soundOffLegend" v-html="soundOffLegend" />
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': fullScreenImgHover !== null }"  v-if="!$breakpoints.isMobile">
        <img
          :src="fullScreenImg"
          class="image-base"
          @click="$emit(`toggle-fullscreen`)"
        />
        <img
          :src="fullScreenImgHover"
          class="image-hover"
          @click="$emit(`toggle-fullscreen`)"
          v-if="!$breakpoints.isMobile && fullScreenImgHover"
        />
        <div class="legend" v-if="fullScreenLegend" v-html="fullScreenLegend" />
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': gyroscopeOnImgHover !== null }" v-show="gyroEnabled" v-if="$breakpoints.isMobile">
        <img
          :src="gyroscopeOnImg"
          class="image-base"
          @click="$emit(`toggleGyroscope`)"
        />
        <img
          :src="gyroscopeOnImgHover"
          class="image-hover"
          @click="$emit(`toggleGyroscope`)"
          v-if="!$breakpoints.isMobile && gyroscopeOnImgHover"
        />
        <div class="legend" v-if="gyroscopeOnLegend" v-html="gyroscopeOnLegend" />
      </div>
      <div class="menu-img" :class="{ 'has-image-hover': gyroscopeOffImgHover !== null }" v-show="!gyroEnabled" v-if="$breakpoints.isMobile">
        <img
          :src="gyroscopeOffImg"
          class="image-base"
          @click="$emit(`toggleGyroscope`)"
        />
        <img
          :src="gyroscopeOffImgHover"
          class="image-hover"
          @click="$emit(`toggleGyroscope`)"
          v-if="!$breakpoints.isMobile && gyroscopeOffImgHover"
        />
        <div class="legend" v-if="gyroscopeOffLegend" v-html="gyroscopeOffLegend" />
      </div>
      <div class="menu-img" v-if="$breakpoints.isMobile">
        <img
          :src="closeImg"
          @click="closeMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    isMenuOpened: false,
  }),

  computed: {
    homeImg() {
      return this.getConfigItem(`home`)?.image || null;
    },
    homeImgHover() {
      return this.getConfigItem(`home`)?.imageHover || null;
    },
    homeLegend() {
      return this.getConfigItem(`home`)?.title || null;
    },
    mapImg() {
      return this.getConfigItem(`map`)?.image || null;
    },
    mapImgHover() {
      return this.getConfigItem(`map`)?.imageHover || null;
    },
    mapLegend() {
      return this.getConfigItem(`map`)?.title || null;
    },
    soundOnImg() {
      return this.getConfigItem(`sound-on`)?.image || null;
    },
    soundOnImgHover() {
      return this.getConfigItem(`sound-on`)?.imageHover || null;
    },
    soundOnLegend() {
      return this.getConfigItem(`sound-on`)?.title || null;
    },
    soundOffImg() {
      return this.getConfigItem(`sound-off`)?.image || null;
    },
    soundOffImgHover() {
      return this.getConfigItem(`sound-off`)?.imageHover || null;
    },
    soundOffLegend() {
      return this.getConfigItem(`sound-off`)?.title || null;
    },
    fullScreenImg() {
      return this.getConfigItem(`full-screen`)?.image || null;
    },
    fullScreenImgHover() {
      return this.getConfigItem(`full-screen`)?.imageHover || null;
    },
    fullScreenLegend() {
      return this.getConfigItem(`full-screen`)?.title || null;
    },
    gyroscopeOnImg() {
      return this.getConfigItem(`gyroscope-on`)?.image || null;
    },
    gyroscopeOnImgHover() {
      return this.getConfigItem(`gyroscope-on`)?.imageHover || null;
    },
    gyroscopeOnLegend() {
      return this.getConfigItem(`gyroscope-on`)?.title || null;
    },
    gyroscopeOffImg() {
      return this.getConfigItem(`gyroscope-off`)?.image || null;
    },
    gyroscopeOffImgHover() {
      return this.getConfigItem(`gyroscope-off`)?.imageHover || null;
    },
    gyroscopeOffLegend() {
      return this.getConfigItem(`gyroscope-off`)?.title || null;
    },
    closeImg() {
      return this.getConfigItem(`close`)?.image || null;
    },
    closeImgHover() {
      return this.getConfigItem(`close`)?.imageHover || null;
    },
    closeLegend() {
      return this.getConfigItem(`close`)?.title || null;
    },
  },

  methods: {
    getConfigItem(name) {
      return this.menuConfig.find(item => item.name === name) || null;
    },
    openMenu() {
      this.isMenuOpened = true;
    },
    closeMenu() {
      this.isMenuOpened = false;
    },
    openMap() {
      this.closeMenu();
      this.$emit(`open-map`);
    },
    goHome() {
      this.closeMenu();
      this.$emit(`go-home`);
    },
  },
}
</script>

<style lang="scss" scoped>
$mobileMenuButtonSize: 50px;
.clarins-menu {
  position: fixed;
  bottom: 40px;
  right: 30px;

  .items {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .menu-img {
      position: relative;
      display: inline-block;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:not(:first-child) {
        margin-left: 15px;
      }

      img {
        height: 50px;
        width: 50px;
      }

      .legend {
        margin-top: 0.5em;
        white-space: nowrap;
        font-size: 12px;
        color: #FFF;
      }
    }
  }

  &.mobile {
    .items {
      .menu-img {
        .legend {
          bottom: -18px;
          font-size: 9px;
        }
      }
    }
  }

  &:not(.mobile) {
    bottom: 40px;
    .menu-img {
      .image-base {
        display: block;
      }
      .image-hover {
        display: none;
      }
      &:not(:first-child) {
        margin-left: 35px;
      }
      &.has-image-hover:hover {
        .image-base {
          display: none;
        }
        .image-hover {
          display: block;
        }
      }
    }
  }
}
</style>
