
export default [
  {
    name: `home`,
    title: `Home`,
  },
  {
    name: `map`,
    title: `Sky view`,
  },
  {
    name: `sound-on`,
    title: `Mute`,
  },
  {
    name: `sound-off`,
    title: `Unmute`,
  },
  {
    name: `full-screen`,
    title: `Full screen`,
  },
  {
    name: `gyroscope-on`,
    title: `Gyro off`,
  },
  {
    name: `gyroscope-off`,
    title: `Gyro on`,
  },
];
