
export default [
  {
    name: `home`,
    imageHover: null,
  },
  {
    name: `map`,
    imageHover: null,
  },
  {
    name: `sound-on`,
    imageHover: null,
  },
  {
    name: `sound-off`,
    imageHover: null,
  },
  {
    name: `full-screen`,
    enabled: false,
  },
  {
    name: `close`,
    imageHover: null,
  },
  {
    name: `gyroscope-on`,
    image: require(`@/assets/menu/gyro-on.png`),
    imageHover: null,
    enabled: true,
  },
  {
    name: `gyroscope-off`,
    image: require(`@/assets/menu/gyro-off.png`),
    imageHover: null,
    enabled: true,
  },
];


