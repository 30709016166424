<template>
  <div class="lang-selector-mobile h-100 flex-col justify-center align-center">
    <div class="lang-selector-mobile-list my-auto pt-40 flex-col justify-center align-center w-100">
      <div
        v-for="(lang, i) in displayedLangs"
        :key="lang.key"
        class="clarins-button border-button lang-btn"
        :class="{
          'mt-15': i !== 0
        }"
        @click="chooseLang(lang)"
      >
        <div class="w-100">
          <div class="flex-row justify-left align-center">
            <img
              :src="require(`@/assets/flags/${lang.key}.png`)"
              class="lang-flag mr-15"
            >
            {{ lang.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="align-self-stretch mt-40">
      <a class="clarins-button secondary-button" @click="close">
        Back
      </a>
    </div>
  </div>
</template>

<script>
import langs from '@/visit/langs.js';

export default {
  data: () => ({
    langs,
  }),

  computed: {
    displayedLangs() {
      return (this.langs || []).filter(lang => lang.isInSelector !== false);
    },
  },

  methods: {
    chooseLang(lang) {
      this.$emit(`choose-lang`, lang);
    },
    close() {
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-btn {
  width: 80%;
  max-width: 300px;
  min-height: 44px;
}

.lang-selector-mobile {
  .lang-selector-mobile-list {
    overflow: auto;
    justify-content: flex-start;
    margin: 3em 0;
  }
}
</style>
