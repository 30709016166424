
export default [
  {
    name: `entree-domaine`,
    title: `我們的合作夥伴`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `花壇`,
  },
  {
    name: `devant-chalet`,
    title: `珍藏花園`,
  },
  {
    name: `chalet`,
    title: `珍藏花園`,
  },
  {
    name: `haut-domaine`,
    title: `小木屋`,
  },
  {
    name: `jardin-collection`,
    title: `莊園入口`,
  },
  {
    name: `parcelle-cheval`,
    title: `耕地`,
  },
  {
    name: `plantations`,
    title: `莊園最高處`,
  },
  {
    name: `partenaires`,
    title: `龍膽和肥皂草`,
  },
  {
    name: `drone-domaine`,
    title: `探索山谷`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `飛越莊園`,
    isInMap: false
  },
];
