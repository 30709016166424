<template>
  <div
    class="loading-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    :style="`background-image: url('${backgroundImage}');`"
  >
    <div class="logo-wrapper">
      <img src="@/assets/logo.png">
    </div>
    <div class="welcome">
      {{ title }}
    </div>
    <div class="instructions">
      <div class="instructions-container">
        <div class="instruction">
          <div class="img-wrapper">
            <img :src="instruction1Img" v-if="instruction1Img">
          </div>
          <div class="legend" v-html="instruction1" />
        </div>
        <div class="instruction">
          <div class="img-wrapper">
            <img :src="instruction2Img" v-if="instruction2Img">
          </div>
          <div class="legend" v-html="instruction2" />
        </div>
        <div class="instruction">
          <div class="img-wrapper">
            <img :src="instruction3Img" v-if="instruction3Img">
          </div>
          <div class="legend" v-html="instruction3" />
        </div>
        <div class="instruction">
          <div class="img-wrapper">
            <img :src="instruction4Img" v-if="instruction4Img">
          </div>
          <div class="legend" v-html="instruction4" />
        </div>
      </div>
    </div>
    <div class="flex-row justify-center align-center preloading-area">
      <div v-if="isPreloading">
        <div class="preloading-message pt-0 pb-10" v-html="currentLoadingText">
        </div>
        <loading-progress
          indeterminate
          shape="line"
          :size="$breakpoints.isMobile ? 300 : 600"
          :width="$breakpoints.isMobile ? 300 : 600"
          :height="$breakpoints.isMobile ? 10 : 20"
          fill-duration="20"
        />
        <div class="preloading-message">
          {{ currentLoadingTextSecondary }}
        </div>
      </div>
      <div class="start-button-wrapper" v-else>
        <a class="clarins-button button-l" @click="close">
          {{ startBtnLabel }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const LOADING_TEXT_DURATION = 350;
const LOADING_TEXT_SECONDARY_DURATION = 5000;

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loadingConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    isPreloading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    currentLoadingTextNbDots: 1,
    currentLoadingTextSecondaryIndex: -1,
    loadingTextTimeout: null,
    loadingTextSecondaryTimeout: null,
  }),

  computed: {
    backgroundImage() {
      return this.getConfigItem(`background_image`)?.image || ``;
    },
    loadingLabel() {
      return this.getConfigItem(`loading_label`)?.title || [];
    },
    loadingTexts() {
      return this.getConfigItem(`loading_texts`)?.texts || [];
    },
    title() {
      return this.getConfigItem(`title`)?.title || ``;
    },
    instruction1() {
      return this.getConfigItem(`instruction_1`)?.title || ``;
    },
    instruction2() {
      return this.getConfigItem(`instruction_2`)?.title || ``;
    },
    instruction3() {
      return this.getConfigItem(`instruction_3`)?.title || ``;
    },
    instruction4() {
      return this.getConfigItem(`instruction_4`)?.title || ``;
    },
    instruction1Img() {
      return this.getConfigItem(`instruction_1`)?.image || null;
    },
    instruction2Img() {
      return this.getConfigItem(`instruction_2`)?.image || null;
    },
    instruction3Img() {
      return this.getConfigItem(`instruction_3`)?.image || null;
    },
    instruction4Img() {
      return this.getConfigItem(`instruction_4`)?.image || null;
    },
    startBtnLabel() {
      return this.getConfigItem(`start_btn`)?.title || ``;
    },
    currentLoadingText() {
      let loadingText = this.loadingLabel;
      const nbDots = this.currentLoadingTextNbDots % 4;
      for (let i = 0; i < 3; i++) {
        const content = i < nbDots ? `.` : ``;
        loadingText += `<div class="dot">${content}</div>`;
      }
      return loadingText;
    },
    currentLoadingTextSecondary() {
      return this.loadingTexts[this.currentLoadingTextSecondaryIndex % this.loadingTexts.length] || ``;
    },
  },

  methods: {
    getConfigItem(name) {
      return this.loadingConfig.find(item => item.name === name) || null;
    },
    close() {
      this.$emit(`close`);
    },
    initLoadingText() {
      this.currentLoadingTextNbDots = -1;
      this.nextLoadingText();
    },
    initLoadingTextSecondary() {
      this.currentLoadingTextSecondaryIndex = -1;
      this.nextLoadingTextSecondary();
    },
    nextLoadingText() {
      this.currentLoadingTextNbDots += 1;
      this.loadingTextTimeout = setTimeout(() => this.nextLoadingText(), LOADING_TEXT_DURATION);
    },
    nextLoadingTextSecondary() {
      this.currentLoadingTextSecondaryIndex += 1;
      this.loadingTextSecondaryTimeout = setTimeout(() => this.nextLoadingTextSecondary(), LOADING_TEXT_SECONDARY_DURATION);
    },
  },

  mounted() {
    if (this.visible) {
      this.initLoadingText();
      this.initLoadingTextSecondary();
    }
  },

  beforeDestroy() {
    clearTimeout(this.loadingTextTimeout);
    clearTimeout(this.loadingTextSecondaryTimeout);
  },

  watch: {
    visible(visible) {
      if (visible) {
        this.initLoadingText();
        this.initLoadingTextSecondary();
      } else {
        clearTimeout(this.loadingTextTimeout);
        clearTimeout(this.loadingTextSecondaryTimeout);
        this.currentLoadingTextNbDots = -1;
        this.currentLoadingTextSecondaryIndex = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &, * {
    color: #FFF;
  }

  &:not(.mobile) {
    display: flex;
    flex-direction: column;
    .logo-wrapper {
      text-align: left;
      padding: 40px 40px 20px;
      img {
        width: 140px;
      }
    }

    .welcome {
      padding: 60px 15px 10px;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .instructions {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      width: 100%;

      .instructions-container {
        padding: 30px 20px;
        height: 100%;
        max-height: 360px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        max-width: 1500px;

        .instruction {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          font-size: 18px;
          font-weight: 300;
          text-align: center;
          flex: 1;
          flex-basis: 0;
          padding: 0px 15px;

          .img-wrapper {
            height: 80px;
            width: 80px;
            margin-bottom: 40px;
          }

          img {
            height: 100%;
          }
        }
      }
    }

    .preloading-area {
      margin-bottom: 40px;
      height: 130px;
    }

    .preloading-message {
      font-weight: 300;
      padding-top: 15px;
      font-size: 20px;
    }

    .start-button-wrapper {
      display: inline-block;
    }

    .start-button {
      background-color: #AB1822;
      padding: 40px 80px;
      font-weight: 700;
      color: #FFF;
      border-radius: 20px;
      font-size: 40px;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &.mobile {
    display: flex;
    flex-direction: column;
    .logo-wrapper {
      text-align: left;
      padding: 20px 20px 0px;
      img {
        width: 30%;
        max-width: 80px;
      }
    }

    .welcome {
      padding: 40px 15px 10px;
      font-size: 22px;
      text-transform: uppercase;
    }

    .instructions {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;

      .instructions-container {
        padding: 30px 20px;
        height: 100%;
        max-height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .instruction {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          font-weight: 300;
          text-align: left;

          .img-wrapper {
            height: 50px;
            width: 50px;
            margin-right: 20px;
          }

          img {
            height: 100%;
          }
        }
      }
    }

    .preloading-area {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 10px 20px 30px;

      .preloading-message {
        font-weight: 300;
        padding-top: 10px;
        font-size: 11px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .start-button {
        background-color: #AB1822;
        padding: 10px 30px;
        font-weight: 500;
        color: #FFF;
        border-radius: 10px;
        font-size: 14px;
        text-decoration: none;
        display: inline-block
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>

<style lang="scss">
.loading-screen {
  .preloading-area {
    .preloading-message {
      .dot {
        display: inline-block;
        width: 5px;
      }
    }
  }
}

.vue-progress-path .progress {
  stroke: #FFF !important;
  stroke-width: 11px !important;
}

.vue-progress-path .background {
  stroke: #AB1822 !important;
  stroke-width: 11px !important;
}
</style>
