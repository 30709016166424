
export default [
  {
    name: `title`,
    title: `Welcome to the heart of Domaine Clarins`,
  },
  {
    name: `instruction_1`,
    title: `Drag your mouse to explore in 360°.`,
  },
  {
    name: `instruction_2`,
    title: `Click on the arrows to change areas. `,
  },
  {
    name: `instruction_3`,
    title: `Click on the points of interest to learn more.`,
  },
  {
    name: `instruction_4`,
    title: `Click on the logo to return to the home page.`,
  },
  {
    name: `loading_label`,
    title: `Loading`,
  },
  {
    name: `start_btn`,
    title: `Start my virtual tour`,
  },
  {
    name: `loading_texts`,
    texts: [
      `Domaine Clarins epitomizes our company’s approach to responsible beauty.`,
      `Located high within the French Alps where the soil and air are free of pollution`,
      `this living laboratory and farm is the perfect environment for growing`,
      `some of the precious organic ingredients used in our products.`,
    ],
  },
];
