
export default [
  {
    name: `entree-domaine`,
    title: `Entrée du Domaine`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `La gentiane et la saponaire`,
  },
  {
    name: `devant-chalet`,
    title: `Le châlet`,
  },
  {
    name: `chalet`,
    title: `Le châlet`,
  },
  {
    name: `haut-domaine`,
    title: `Le haut du Domaine`,
  },
  {
    name: `jardin-collection`,
    title: `Le jardin collection`,
  },
  {
    name: `parcelle-cheval`,
    title: `Le bas du Domaine`,
  },
  {
    name: `plantations`,
    title: `Les plantations`,
  },
  {
    name: `partenaires`,
    title: `Nos partenaires`,
  },
  {
    name: `drone-domaine`,
    title: `Survolez le Domaine`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `Découvrez la vallée`,
    isInMap: false
  },
];
