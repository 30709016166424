
export default [
  {
    name: `entree-domaine`,
    title: ` 도멘느 클라랑스 입구`,
  },
  {
    name: `gentiane-et-saponaire`,
    title: `겐티안과 비누풀`,
  },
  {
    name: `devant-chalet`,
    title: `목장`,
  },
  {
    name: `chalet`,
    title: `목장`,
  },
  {
    name: `haut-domaine`,
    title: ` 도멘느 클라랑스의 정상`,
  },
  {
    name: `jardin-collection`,
    title: `컬렉션 가든`,
  },
  {
    name: `parcelle-cheval`,
    title: `도멘느 클라랑스 하단`,
  },
  {
    name: `plantations`,
    title: `화단`,
  },
  {
    name: `partenaires`,
    title: ` 클라랑스 파트너스`,
  },
  {
    name: `drone-domaine`,
    title: ` 도멘느 클라랑스의 상공을 비행`,
    isInMap: false
  },
  {
    name: `drone-montee`,
    title: `계곡 둘러보기`,
    isInMap: false
  },
];
