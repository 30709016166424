export default [
  {
    name: `home`,
    title: `환영합니다`,
  },
  {
    name: `map`,
    title: ` 상공에서 보기`,
  },
  {
    name: `sound-on`,
    title: `음소거 해제`,
  },
  {
    name: `sound-off`,
    title: `음소거`,
  },
  {
    name: `full-screen`,
    title: ` 전체 화면 보기`,
  },
  {
    name: `gyroscope-on`,
    title: `자이로스코프 켜기`,
  },
  {
    name: `gyroscope-off`,
    title: `자이로스코프 끄기`,
  },
];
