export default [
    {
      name: `actifs-excellence-absolue`,
      title: `Des actifs d'une excellence absolue`,
      texts: [
        `Ce mode de culture nous garantit des plantes d'une qualité unique hautement concentrées en principes actifs. Chaque extrait préparé est ensuite testé pour en vérifier la performance et la tolérance.`,
      ],
    },
    {
      name: `annee-domaine-clarins`,
      title: `Une année au Domaine Clarins`,
      texts: [
        `Au Domaine Clarins, il y a toujours une plante qui a besoin de notre attention !`,
        `Au printemps, l'heure est au labeur : désherbage, préparation des sols, nouvelles plantations. Durant l’été et l’automne, place aux récoltes et au séchage des plantes. C’est aussi la saison des observations scientifiques.`,
        `Quand vient l’hiver, les équipes préparent la saison à venir et analysent les observations menées tout au long de l’année.`,
      ],
    },
    {
      name: `beaute-au-sommet`,
      title: `La beauté au sommet`,
      texts: [
        `C’était un rêve « Clarins » depuis toujours. Déjà dans les années 60, le fondateur de la marque, Jacques Courtin-Clarins, avait eu l’intuition que la nature des Alpes offrait des actifs naturellement efficaces et bons pour la peau. Dans les années 90, son fils aîné Christian s’engage auprès d’Alp Action pour préserver la faune et la flore des massifs alpins.`,
        `Depuis 2016, c’est une réalité. Ici, chaque jour, les plantes alpines Clarins sont cultivées dans le respect des hommes, de la nature et des sols.`,
      ],
    },
    {
      name: `ceuillette-au-sechage`,
      title: `De la cueillette au séchage`,
      texts: [
        `Les plantes sont cueillies à la main au moment où elles sont les plus riches en actifs. Elles sont ensuite découpées en petits morceaux. Etalées dans le séchoir, elles sèchent selon des méthodes traditionnelles, à l’abri des rayons du soleil : une fois par jour, elles sont délicatement retournées jusqu’à leur séchage complet. Plus de 2,5 tonnes de plantes fraîches sont ainsi récoltées chaque année au Domaine Clarins.`,
      ],
    },
    {
      name: `cultures-100-bio`,
      title: `Des cultures 100 % biologiques`,
      texts: [
        `Ici, nous avons la chance d'avoir une terre naturellement et merveilleusement riche. Nous avons fait le choix d'une culture 100% biologique : aucun pesticide, uniquement des apports organiques (feuilles mortes, fumier, vieux foin...). La terre est travaillée dans le respect de l'équilibre naturel du sol. Les plantes s'épanouissent au rythme des saisons, sans pollution de l'air ni des sols.`,
      ],
    },
    {
      name: `debut-longue-aventure`,
      title: `Le début d'une longue aventure`,
      texts: [
        `Depuis 2016, 6 extraits bio 100 % Clarins ont déjà enrichi notre bel herbier : rose des Alpes, joubarbe, saponaire, mélisse, pétasite et gentiane jaune. Vous les retrouvez dans nos soins démaquillants et ClarinsMen. Notre ambition est de doubler leur nombre d'ici 2025.`,
      ],
    },
    {
      name: `dedie-innovation`,
      title: `Lieu de culture et d'expérimentation`,
      texts: [
        `Au Domaine Clarins, nous cultivons les plantes bio d'aujourd'hui et de demain... Nos équipes de Recherche analysent de nouvelles espèces pour déceler leurs propriétés cosmétiques et étudier leurs interactions avec la peau.`,
      ],
    },
    {
      name: `engagement-preservation-alpes`,
      title: `Un nouvel engagement pour la préservation des Alpes`,
      texts: [
        `Clarins soutient le Conservatoire des espaces naturels de Haute-Savoie Asters, gestionnaire des neuf réserves naturelles du département. Pour poursuivre et renforcer ces liens de coeur, nous avons signé en 2021 avec eux une Obligation Réelle Environnementale (ORE). Par ce contrat, nous nous engageons à préserver la biodiversité du Domaine pendant 99 ans, la durée maximale prévue par le mécanisme ORE. C’est la toute première fois qu’une entreprise privée prend ce type d’engagement envers un espace naturel.`,
      ],
    },
    {
      name: `engages-sauvegarde-alpes`,
      title: `Engagés pour la sauvegarde des Alpes`,
      texts: [
        `L'amour de Clarins pour les Alpes ne date pas d'hier ! Tout a commencé en 1993, lorsque Christian Courtin croise la route du Prince Saddrudin Aga Khan et de sa fondation Alp Action.`,
        `Depuis, la marque apporte un soutien pérenne à la biodiversité du massif : elle a contribué à 5 programmes de préservation d’espèces menacées en partenariat avec Alp Action repris ensuite par Asters. Un engagement qui nous tient vraiment à coeur et que nous portons aujourd’hui comme hier toujours avec autant de ferveur.`,
      ],
    },
    {
      name: `equipe-petits-soins`,
      title: `Une équipe aux petits soins`,
      texts: [
        `Le Domaine Clarins, c'est aussi une équipe de passionnés qui partagent un profond attachement à la terre et à leur métier. 5 experts y travaillent sous la houlette d'Aurore Metral et Véronique Mothès, toutes deux responsables de l’exploitation, et sous les conseils de Jean-Pierre Nicolas, ethnobotaniste.`,
      ],
    },
    {
      name: `gentiane-jaune-gentiana-lutea`,
      title: `La gentiane jaune - Gentiana lutea`,
      texts: [
        `Grande dame de la phytothérapie, la gentiane jaune est connue depuis l’Antiquité pour ses nombreux effets curatifs. Plante robuste des grands espaces, elle s’épanouit dans les pâturages et sa longévité est étonnante : un demi-siècle. Au cours de cette longue vie, elle développe de solides racines, réservoirs de principes actifs qui aident à lutter contre les troubles digestifs. Vous pouvez retrouver les effets apaisants de l’extrait de feuilles de gentiane bio cultivé au Domaine Clarins dans nos Eaux démaquillantes.`,
      ],
      cta: {
        title: `Découvrez un de nos produit`,
          href: `https://www.clarins.ca/fr/lait-velours-demaquillant/80062049.html `,
      },
    },
    {
      name: `gypaete-barbu`,
      title: `Le gypaète barbu`,
      texts: [
        `Levez les yeux au ciel, vous aurez peut-être la chance d'apercevoir ce vautour des Alpes réintroduit en France en 1986 sous l'impulsion d'Asters et de Clarins. Avec une envergure d'environ 3 mètres, c'est le plus grand rapace européen. On en compte aujourd'hui 70 dans les Alpes, alors que l'espèce était menacée d'extinction.`,
      ],
    },
    {
      name: `joubarbe-des-toits`,
      title: `La joubarbe des toits - Sempervivum tectorum`,
      texts: [
        `Originaire d’Europe occidentale, la joubarbe est soumise à des températures extrêmes, une sécheresse excessive, un terrain aride. Malgré cela, elle affiche des feuilles vertes et charnues, gorgées d’eau. La plante est cultivée sur le Domaine et son extrait bio est ensuite sélectionné par nos Laboratoires pour la gamme ClarinsMen qui aide la peau à résister à la déshydratation.`,
      ],
      cta: {
        title: `Découvrez un de nos produit`,
          href: `https://www.clarins.ca/fr/gel-energisant-clarinsmen/80071984.html`,
      },
    },
    {
      name: `laboratoire-ciel-ouvert`,
      title: `Un laboratoire à ciel ouvert`,
      texts: [
        `Le Domaine abrite un laboratoire à ciel ouvert dédié à l'expérimentation. Chaque année, une quinzaine d’espèces de montagne et de plaine y sont observées pour déterminer les parties les plus concentrées en principes actifs. Les échantillons recueillis sont acheminés vers nos Laboratoires de Pontoise pour être analysés. Depuis 2017, plus de 1 500 prélèvements ont ainsi été évalués pour une utilisation potentielle dans les formules Clarins.`,
      ],
    },
    {
      name: `melisse`,
      title: `La mélisse`,
      texts: [
        `Cultivée depuis l’Antiquité comme plante médicinale, la mélisse soulage quantité de maux et notamment les troubles digestifs et les troubles nerveux. Elle favorise le sommeil en luttant contre le stress. Des études confirment que la plante aide à la relaxation, à la diminution du stress et de l’anxiété. En cosmétique, l’extrait de mélisse bio contribue à calmer, apaiser et adoucir la peau. Vous la retrouverez dans tous nos soins démaquillants.`,
      ],
      cta: {
        title: `Découvrez un de nos produit`,
          href: `https://www.clarins.ca/fr/lait-velours-demaquillant/80062049.html `,
      },
    },
    {
      name: `partenaires-locaux`,
      title: `Des partenaires locaux de confiance`,
      texts: [
        `Fidélité, une valeur qui nous est chère. Nous travaillons avec les mêmes partenaires locaux depuis de nombreuses années. Producteurs et cueilleurs, tous partagent notre philopsophie et respectent les bonnes pratiques appliquées au Domaine Clarins : qualité des plantes, agriculture biologique, méthodes traditionnelles… Ils ont notre pleine confiance et nous nous engageons auprès d'eux sur le long terme.`,
      ],
    },
    {
      name: `plantes-sauvages`,
      title: `Les plantes sauvages`,
      texts: [
        `Les équipes du Domaine Clarins collaborent avec les propriétaires de 14 sites voisins certifiés en agriculture biologique. Ce sont des terrains dits "sauvages" sur lesquels les plantes poussent naturellement. Nous y récoltons de manière durable et responsable des feuilles de pétasite, de gentiane jaune et de rose des Alpes.`,
      ],
    },
    {
      name: `production-circuit-court`,
      title: `Notre production en circuit court`,
      texts: [
        `Cultiver nos propres plantes nous permet de maîtriser notre impact environnemental sans faire de compromis sur la qualité. Après la récolte, un extrait est préparé pour être ensuite formulé par nos Laboratoires. Chaque étape est 100% traçable, gage de transparence et de durabilité.`,
      ],
    },
    {
      name: `respect-sols`,
      title: `Le respect des sols`,
      texts: [
        `Le Domaine Clarins nous offre une terre d'une richesse exceptionnelle : un sol jamais pollué, enrichi d’intrants naturels. Pour en prendre le plus grand soin, nous suivons les principes de l’agriculture régénératrice. Ce mode de culture vertueux va au-delà des standards de l’agriculture biologique. Tout est fait pour une exploitation qui renforce les sols et la biodiversité.`,
      ],
      cta: {
        title: `Visionnez la vidéo`,
        enabled: true,
        fakeDisable: false,
      },
    },
    {
      name: `richesse-purete-alpes`,
      title: `La richesse et la pureté des Alpes`,
      texts: [
        `Situé en plein cœur du massif alpin, le Domaine Clarins bénéficie de tous les bienfaits des montagnes : un air pur (respirez!), une terre riche et préservée, une eau puisée directement à la source. Des conditions optimales pour cultiver nos propres plantes et vous offrir des soins d'une qualité exceptionnelle.`,
      ],
    },
    {
      name: `saponaire-saponaria-officinalis`,
      title: `La saponaire - Saponaria officinalis`,
      texts: [
        `Vivace des bords des rivières, des fossés et des bois, la saponaire porte en été d’odorantes fleurs blanches ou rosées. Nos ancêtres utilisaient ses feuilles et ses rhizomes aux propriétés nettoyantes et émulsionnantes pour lessiver les étoffes de laine et blanchir fils et dentelles. Elle est cultivée au coeur du Domaine Clarins : son extrait bio riche en tensioactifs naturels est idéal pour nettoyer la peau. Vous le retrouverez dans nos soins nettoyants.`,
      ],
      cta: {
        title: `Découvrez un de nos produit`,
          href: `https://www.clarins.ca/fr/doux-nettoyant-moussant-purifiant/80071906.html`,
      },
    },
    {
      name: `tracabilite-exemplaire`,
      title: `Une traçabilité exemplaire`,
      texts: [
        `Le logo "Domaine Clarins" sur nos produits garantit une maîtrise complète de la chaîne d'approvisionnement, de la parcelle de culture à nos points de vente.`,
      ],
    },
    {
      name: `sources-energie-propres`,
      title: `Des sources d'énergie propres`,
      texts: [
        `Sur le Domaine, la nature est reine. Les plantes poussent grâce à l'énergie complémentaire des hommes et de la nature, sans jamais la polluer ni lui nuire. Les eaux pluviales collectées grâce à des récupérateurs, servent à l’arrosage des cultures. Pour produire l'électricité du séchoir et du chalet, des panneaux solaires ont été installés.`,
      ],
    },
    {
      name: `sourcing-responsable`,
      title: `Un approvisionnement responsable`,
      texts: [
        `Nous croyons en une beauté, responsable et durable. Nous privilégions les bonnes pratiques agricoles et un approvisionnement de préférence local (France ou Europe). Lorsque nos ingrédients viennent de plus loin, nous mettons en place des primes de commerce équitable. Nous veillons à une traçabilité totale des zones de récolte et nouons des contrats à long terme avec nos partenaires.`,
      ],
    },
    {
      name: `video-cheval`,
    },
    {
      name: `voilages-protecteurs`,
      title: `Des voilages protecteurs`,
      texts: [
        `Sur le Domaine, le désherbage est entièrement réalisé à la main. Pour limiter le développement des mauvaises herbes, nous avons fait le choix d'une protection naturelle. Des voilages en laine ou en chanvre protègent les cultures des rayons du soleil, présevent la fertilité de la terre et favorisent l’épanouissement des cultures.`,
      ],
    },
  ];
